import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'ionicons/dist/css/ionicons.css';
import './index.css';
import {LoginScreen} from './ui/login_screen/login_screen';
import {DefaultTheme, ThemeProvider, styled} from './ui/theme';
import {Router} from '@reach/router';
import {SelectDistanceScreen} from './ui/select_distance_screen/select_distance_screen';
import {Slide, toast, ToastContainer} from 'react-toastify';
import {BoatsOverviewScreen} from './ui/boats_overview_screen/boats_overview_screen';
import {RaceOverviewScreen} from './ui/race_overview_screen/race_overview_screen';
import {BigScreen} from './ui/big_screen/big_screen';
import {RaceLoadingScreen} from './ui/race_loading_screen/race_loading_screen';

const StyledRouter = styled(Router)`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

ReactDOM.render(
    <ThemeProvider theme={DefaultTheme}>
        <>
            <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_RIGHT} transition={Slide} />

            <StyledRouter>
                <LoginScreen path="/" />
                <LoginScreen path="/create" />

                <RaceLoadingScreen
                    path="/:raceId/lobby"
                    render={(race, navigate) => <SelectDistanceScreen race={race} navigate={navigate} />}
                />
                <RaceLoadingScreen path="/:raceId/boats" render={(race) => <BoatsOverviewScreen race={race} />} />
                <RaceLoadingScreen
                    path="/:raceId/race-overview"
                    render={(race) => <RaceOverviewScreen race={race} />}
                />

                <RaceLoadingScreen path="/big/:raceId" render={(race) => <BigScreen race={race} />} />
            </StyledRouter>
        </>
    </ThemeProvider>,
    document.getElementById('root') as HTMLElement,
);
