import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {RaceProvider} from '../../business/race/race_provider';
import {CompositeSubscription} from '../../support/composite_subscription';
import {runInAction} from 'mobx';

export class RaceOverviewScreenPresenter implements Presenter {
    public race: Race;

    private subscriptions = new CompositeSubscription();

    constructor(initialRace: Race, private raceProvider: RaceProvider) {
        this.race = initialRace;
    }

    public mount(): void {
        this.subscriptions.add(
            this.raceProvider.get().subscribe((raceTry) => {
                raceTry.map((race) => {
                    runInAction(() => {
                        this.race = race;
                    });
                });
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
