import * as React from 'react';
import {FunctionComponent} from 'react';
import {Rect, Text} from 'react-konva';
import {yPositionIsInViewport} from '../calculators/position_in_viewport_calculator';
import {formatDistanceMeters} from '../../../support/format_distance';

interface OwnProps {
    meterInPx: number;
    viewportOffsetPx: number;
    canvasHeight: number;
    canvasWidth: number;
    distanceMeters: number;
}

interface SplitLine {
    distanceMeters: number;
    y: number;
}

export const BigBuoys: FunctionComponent<OwnProps> = ({
    meterInPx,
    viewportOffsetPx,
    canvasHeight,
    canvasWidth,
    distanceMeters,
}) => {
    const splitLines: SplitLine[] = [];
    let locationMeters = 0;
    const intervalMeters = 100;
    do {
        const splitLineY = canvasHeight - locationMeters * meterInPx;
        if (locationMeters !== 0 && yPositionIsInViewport(splitLineY, canvasHeight, viewportOffsetPx, 200)) {
            splitLines.push({
                distanceMeters: locationMeters,
                y: splitLineY,
            });
        }
        locationMeters += intervalMeters;
    } while (locationMeters < distanceMeters);

    return (
        <>
            {splitLines.map((splitLine) => {
                return (
                    <React.Fragment key={splitLine.distanceMeters}>
                        <Text
                            text={formatDistanceMeters(splitLine.distanceMeters)}
                            verticalAlign="middle"
                            wrap="none"
                            align="left"
                            fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                            fontSize={14}
                            ellipsis={true}
                            fontStyle="bold"
                            fill="white"
                            x={15}
                            y={splitLine.y + viewportOffsetPx - 20}
                        />
                        <Rect
                            y={splitLine.y + viewportOffsetPx}
                            x={0}
                            width={canvasWidth}
                            height={1}
                            fill="#ffffff"
                            opacity={0.4}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};
