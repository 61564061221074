import {fromEvent, Observable} from 'rxjs';
import {Race} from '../models/race';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {RaceForRaceSocket} from '../business/race/race_provider';

export interface RaceSocket extends RaceForRaceSocket {}

export class DefaultRaceSocket implements RaceSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<Race> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}`);
    }
}
