import * as React from 'react';
import {FunctionComponent} from 'react';
import {formatDistance} from '../../../support/format_distance';
import {Block, BlockCircle} from '../../components/block';

interface OwnProps {
    disabled: boolean;
    distanceMeters: number;
    active: boolean;
    onClick: () => void;
}
export const DistanceBlock: FunctionComponent<OwnProps> = ({distanceMeters, active, disabled, onClick}) => {
    return (
        <Block disabled={disabled} active={active} onClick={onClick}>
            <BlockCircle disabled={disabled} active={active}>
                {formatDistance(distanceMeters)}
            </BlockCircle>
        </Block>
    );
};
