import {Race} from '../models/race';
import {DefaultStartResultRepository, StartResultRepository} from './start_result_repository';

export class StartResultRepositoryFactory {
    private repositories = new Map<string, StartResultRepository>();

    public create(race: Race): StartResultRepository {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(race.id, new DefaultStartResultRepository());
        }
        return this.repositories.get(race.id)!;
    }
}
