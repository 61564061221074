import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface OwnProps {
    participantId: string;
    participantName: string;
    raceStartTimeMillis: number;
}

const StyledHref = styled.a`
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: solid 2px;
    border: solid 2px;
    border-color: #d8d8d8;
    background-color: #d8d8d8;
    margin: 0 0 0 4px;
    position: relative;
    padding: 0 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    svg {
        position: absolute;
        width: 26px;
        height: 26px;
        top: 5px;
        left: 5px;
    }
`;

export const StrokesDownloadButton: FunctionComponent<OwnProps> = ({
    participantId,
    participantName,
    raceStartTimeMillis,
}) => {
    return (
        <StyledHref
            href={`/graphql?query={strokes(participantId:"${participantId}",raceStartTimeMillis:${raceStartTimeMillis}){distanceMeters,timeMillis}}`}
            title="Download participant strokes"
            download={`participant_${encodeURIComponent(participantName)}.json`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        </StyledHref>
    );
};
