import {fromEvent, Observable} from 'rxjs';
import {Boat} from '../models/boat';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {BoatsForRaceSocket} from '../business/boats/socket_boats_provider';

export interface BoatSocket extends BoatsForRaceSocket {}

export class DefaultBoatSocket implements BoatSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<Boat[]> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/boats`);
    }
}
