import {HttpLinkProvider} from './driver/http_link_provider';
import {Try} from '../support/monads/try';
import {Device} from '../models/device';
import {execute, ExecutionResult, GraphQLRequest, makePromise} from 'apollo-link';
import gql from 'graphql-tag';
import {UpdateDeviceApi} from '../ui/boats_overview_screen/boats_grid/internal/device_to_participant_assigner';
import {DevicesForRaceApi} from '../business/devices/api_device_provider';

export interface DeviceApi extends DevicesForRaceApi, UpdateDeviceApi {}

export class DefaultDeviceApi implements DeviceApi {
    constructor(private httpLinkProvider: HttpLinkProvider) {}

    public async getForRaceId(raceId: string): Promise<Try<Device[]>> {
        const operation: GraphQLRequest = {
            query: gql`
                query($raceId: String!) {
                    devicesByRaceId(raceId: $raceId) {
                        id
                        raceId
                        participantId
                        isWheelSpinning
                        ping
                        lastPingReceivedMillis
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.devicesByRaceId);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async update(id: string, raceId: string, participantId: string | null): Promise<Try<Device>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($id: String!, $raceId: String!, $participantId: String) {
                    updateDevice(id: $id, raceId: $raceId, participantId: $participantId) {
                        id
                        raceId
                        participantId
                        isWheelSpinning
                        ping
                        lastPingReceivedMillis
                    }
                }
            `,
            variables: {
                id,
                raceId,
                participantId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.updateDevice);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async create(raceId: string): Promise<Try<Device>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!) {
                    createDevice(raceId: $raceId) {
                        id
                        raceId
                        participantId
                        isWheelSpinning
                        ping
                        lastPingReceivedMillis
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.updateDevice);
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
