import {combineLatest, Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {BoatWithProgress} from '../../models/boat';
import {Participant} from '../../models/participant';
import {Device} from '../../models/device';
import {ParticipantsProvider} from '../participants/participant_provider';
import {DevicesProvider} from '../devices/device_provider';
import {BoatProgress, SocketBoatsProgressProvider} from './boats_progress_provider';
import {shareReplay} from 'rxjs/operators';

export interface BoatProgressWithCombinationsProvider {
    get(): Observable<Try<BoatProgressWithCombinations[]>>;
}

export interface BoatProgressWithCombinations {
    boat: BoatWithProgress;
    combinations: ValidCombination[];
}

export interface ValidCombination {
    participant: Participant;
    device: Device;
}

export class DefaultBoatProgressWithCombinationsProvider implements BoatProgressWithCombinationsProvider {
    constructor(
        private boatsProgressProvider: SocketBoatsProgressProvider,
        private participantsProvider: ParticipantsProvider,
        private deviceProvider: DevicesProvider,
    ) {}

    public get(): Observable<Try<BoatProgressWithCombinations[]>> {
        return combineLatest(
            this.boatsProgressProvider.get(),
            this.participantsProvider.get(),
            this.deviceProvider.get(),
            (boatProgresses, participantsTry, devicesTry) =>
                participantsTry.flatMap((participants) =>
                    devicesTry.map((devices) => this.combine(boatProgresses, participants, devices)),
                ),
        ).pipe(shareReplay(1));
    }

    private combine(
        boatProgresses: BoatProgress[],
        participants: Participant[],
        devices: Device[],
    ): BoatProgressWithCombinations[] {
        return boatProgresses
            .map((boatProgress) => {
                return {
                    boat: boatProgress,
                    combinations: participants
                        .filter((participant) => participant.boatId === boatProgress.id)
                        .reduce((combinations: ValidCombination[], participant: Participant) => {
                            const device = devices.find((d) => d.participantId === participant.id);
                            if (device !== undefined) {
                                combinations.push({
                                    participant,
                                    device,
                                });
                            }

                            return combinations;
                        }, []),
                };
            })
            .filter((boatWithCombinations) => boatWithCombinations.combinations.length > 0);
    }
}
