import {Presenter} from '../../../support/with_presenter';
import {Observable, Subscription} from 'rxjs';
import {runInAction} from 'mobx';

export enum Overlay {
    StartList = 'start-list',
    FinishList = 'finish-list',
    Clock = 'clock',
    SplitTimes = 'split-times',
    StrokeRates = 'stroke-rates',
    Speeds = 'speeds',
}

export interface ActiveOverlaysProvider {
    overlays: Observable<Overlay[]>;
}

export class OverlaysPresenter implements Presenter {
    public activeOverlays: Overlay[] = [];

    private subscription: Subscription | undefined;

    constructor(private activeOverlaysProvider: ActiveOverlaysProvider) {}

    public mount(): void {
        this.subscription = this.activeOverlaysProvider.overlays.subscribe((activeOverlays) => {
            runInAction(() => {
                this.activeOverlays = activeOverlays;
            });
        });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
