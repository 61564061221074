import {Race} from '../models/race';
import {DefaultDevicesRepository, DevicesRepository} from './devices_repository';

export class DevicesRepositoryFactory {
    private repositories = new Map<string, DevicesRepository>();

    public create(race: Race): DevicesRepository {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(race.id, new DefaultDevicesRepository());
        }
        return this.repositories.get(race.id)!;
    }
}
