import {Observable, from} from 'rxjs';

import {ServerTimeInteractor} from '../server_time_interactor';
import {Try} from '../../../support/monads/try';
import {filter, map} from 'rxjs/operators';

export interface ServerTime {
    serverTimeMillis: number;
    durationMillis: number;
}

interface ServerTimeResult {
    offsetMillis: number;
    queryDurationMillis: number;
    weight: number;
}

export interface ServerTimeApi {
    get(): Promise<Try<ServerTime | null>>;
}

export class SingleServerTimeInteractor implements ServerTimeInteractor {
    constructor(private serverTimeApi: ServerTimeApi) {}

    public serverTimes(): Observable<ServerTimeResult> {
        return from(
            this.serverTimeApi.get().then((response) =>
                response.fold(
                    (time) => time,
                    () => null,
                ),
            ),
        ).pipe(
            filter((serverTime: ServerTime | null): serverTime is ServerTime => {
                return serverTime !== null;
            }),
            map((serverTime: ServerTime) => {
                const now = new Date().getTime();
                return {
                    offsetMillis: now - serverTime.serverTimeMillis + serverTime.durationMillis / 2,
                    queryDurationMillis: serverTime.durationMillis,
                    weight: 1,
                } as ServerTimeResult;
            }),
        );
    }
}
