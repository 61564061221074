import * as React from 'react';
import {Permission} from '../../enumerations/permission';
import {Race} from '../../models/race';
import {isAuthorized} from '../../business/permissions/is_authorized';

interface OwnProps {
    permission: Permission;
    race: Race;
    fallback?: () => any;
}

export class Can extends React.Component<OwnProps> {
    public render() {
        if (!isAuthorized(this.props.permission, this.props.race)) {
            if (this.props.fallback === undefined) {
                return null;
            }
            return this.props.fallback();
        }
        return this.props.children;
    }
}
