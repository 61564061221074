import {HttpLinkProvider} from './driver/http_link_provider';
import {Try} from '../support/monads/try';
import {Participant} from '../models/participant';
import {execute, ExecutionResult, GraphQLRequest, makePromise} from 'apollo-link';
import gql from 'graphql-tag';
import {CreateParticipantApi} from '../ui/boats_overview_screen/boats_grid/participants_block/add_participant_form/internal/create_participant_interactor';
import {UpdateParticipantApi} from '../ui/boats_overview_screen/boats_grid/internal/participant_to_boat_adder';
import {ParticipantsForRaceApi} from '../business/participants/api_participant_provider';

export interface ParticipantApi
    extends ParticipantsForRaceApi,
        CreateParticipantApi,
        UpdateParticipantApi,
        UpdateParticipantApi {}

export class DefaultParticipantApi implements ParticipantApi {
    constructor(private httpLinkProvider: HttpLinkProvider) {}

    public async getForRaceId(raceId: string): Promise<Try<Participant[]>> {
        const operation: GraphQLRequest = {
            query: gql`
                query($raceId: String!) {
                    participantsByRaceId(raceId: $raceId) {
                        id
                        raceId
                        boatId
                        token
                        name
                        weightGrams
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.participantsByRaceId);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async create(raceId: string, name: string): Promise<Try<Participant>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!, $name: String!) {
                    createParticipant(raceId: $raceId, name: $name) {
                        id
                        raceId
                        boatId
                        token
                        name
                        weightGrams
                    }
                }
            `,
            variables: {
                raceId,
                name,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.createParticipant);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async update(
        id: string,
        boatId: string | null,
        name: string,
        weightGrams: number | null,
    ): Promise<Try<Participant>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($id: String!, $boatId: String, $name: String, $weightGrams: Int) {
                    updateParticipant(id: $id, boatId: $boatId, name: $name, weightGrams: $weightGrams) {
                        id
                        raceId
                        boatId
                        token
                        name
                        weightGrams
                    }
                }
            `,
            variables: {
                id,
                boatId,
                name,
                weightGrams,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.updateParticipant);
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
