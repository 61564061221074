import styled from 'styled-components';

export const Background = styled.div`
    background-image: url('/images/bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, rgba(0, 201, 255, 0.9), rgba(6, 156, 193, 0.9));
        background-size: cover;
        background-position: center 80px;
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
    }
`;
