import {CanvasSizes} from '../../boats_screen_presenter';
import {AnimatedCanvasSizesCalculator} from '../boats_screen_state_provider';
import {AnimatedValueCalculator} from './animated_value_calculator';

export class DefaultAnimatedCanvasSizesCalculator implements AnimatedCanvasSizesCalculator {
    constructor(private animatedPositionCalculator: AnimatedValueCalculator) {}

    public calculate(canvasSizes: CanvasSizes, timestamp: number): CanvasSizes {
        this.animatedPositionCalculator.update(canvasSizes.meterInPx, timestamp);

        return {
            ...canvasSizes,
            meterInPx: this.animatedPositionCalculator.get(timestamp),
        };
    }
}
