import styled from 'styled-components';
import {Link} from '@reach/router';
import {baseButtonStyles} from './button';

export const NextScreenButton = styled(Link)`
    ${baseButtonStyles}
`;

export const LeaveButton = styled(Link)`
    ${baseButtonStyles}
    height: 40px;
    margin: 1.765rem 1.765rem 1.765rem 0;
    font-size: 1em;
    width: 200px;
    background-color: #193f49;

    &:focus,
    &:hover {
        background: #215968;
    }

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(25, 63, 73, 0.5);
    }
`;

export const PreviousScreenButton = styled(Link)`
    ${baseButtonStyles}

    color: #00c9ff;
    border: solid 2px #00c9ff;
    background-color: #fff;

    &:focus,
    &:hover {
        color: #00c9ff;
        background: #fff;
    }
`;
