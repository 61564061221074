import {ServerTimeProvider} from './server_time/server_time_provider';
import {Observable, Scheduler, interval, merge} from 'rxjs';
import {flatMap, first, map, delayWhen, switchMap} from 'rxjs/operators';

export class CountdownInteractor {
    constructor(private serverTimeProvider: ServerTimeProvider, private scheduler: Scheduler) {}

    public countdown(startTimeMillis: number): Observable<number> {
        return this.serverTimeProvider.getTime().pipe(
            first(),
            delayWhen((serverTime) => {
                const startInMs = serverTime - startTimeMillis;
                const nearestFlooredThousandMs = Math.ceil(startInMs / 1000) * 1000;
                const delayBeforeCountdown = nearestFlooredThousandMs - startInMs;
                return interval(delayBeforeCountdown, this.scheduler);
            }),
            switchMap(() => {
                return merge(
                    this.tick(startTimeMillis),
                    interval(1000, this.scheduler).pipe(flatMap(() => this.tick(startTimeMillis))),
                );
            }),
        );
    }

    private tick(startTimeMillis: number): Observable<number> {
        return this.serverTimeProvider.getTime().pipe(
            first(),
            map((serverTime) => serverTime - startTimeMillis),
        );
    }
}
