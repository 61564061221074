import {CreateParticipantInteractor} from '../add_participant_form_presenter';
import {Participant} from '../../../../../../models/participant';
import {Try} from '../../../../../../support/monads/try';

export interface CreateParticipantApi {
    create(raceId: string, name: string): Promise<Try<Participant>>;
}

export interface CreateParticipantRepository {
    createOrUpdate(participant: Participant): void;
}

export class ApiCreateParticipantInteractor implements CreateParticipantInteractor {
    constructor(
        private raceId: string,
        private createParticipantApi: CreateParticipantApi,
        private createParticipantRepository: CreateParticipantRepository,
    ) {}

    public async create(name: string): Promise<Try<Participant>> {
        try {
            const participantTry = await this.createParticipantApi.create(this.raceId, name);
            participantTry.map((participant) => {
                this.createParticipantRepository.createOrUpdate(participant);
            });

            return participantTry;
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
