import * as React from 'react';
import {FunctionComponent} from 'react';
import {Race} from '../../models/race';
import styled from 'styled-components';
import {CopyButton} from './copy_button';

interface OwnProps {
    race: Race;
}

const Text = styled.span`
    height: 100px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    padding: 37.5px 0;
    letter-spacing: normal;
    text-align: left;
    color: #7c7c7c;
    margin: 0 1.765rem;
    display: inline-block;
    button {
        background: none;
        border: none;
        cursor: pointer;
        font-weight: bold;
        margin: 0;
        padding: 0;
        display: inline;
        color: #00c9ff !important;
        text-decoration: underline !important;
    }
`;

export const ConnectionHint: FunctionComponent<OwnProps> = ({race}) => {
    return (
        <Text className="d-none d-lg-inline-block">
            For participants to connect, enter <CopyButton string={race.token}>{race.token}</CopyButton> on the RP3's
        </Text>
    );
};
