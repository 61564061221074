import {BehaviorSubject, Observable} from 'rxjs';
import {Device} from '../models/device';
import {UpdateDeviceRepository} from '../ui/boats_overview_screen/boats_grid/internal/device_to_participant_assigner';
import {Option} from '../support/monads/option';
import {DevicesRepository as ProviderDevicesRepository} from '../business/devices/api_device_provider';

export interface DevicesRepository extends ProviderDevicesRepository, UpdateDeviceRepository {}

export class DefaultDevicesRepository implements DevicesRepository {
    private subject = new BehaviorSubject<Device[]>([]);

    public get(): Observable<Device[]> {
        return this.subject;
    }

    public replace(devices: Device[]): void {
        this.subject.next(devices);
    }

    public create(device: Device): void {
        this.subject.next([...this.subject.value, device]);
    }

    public update(device: Device): void {
        this.subject.next([...this.subject.value.filter((p) => p.id !== device.id), device]);
    }

    public async byId(id: string): Promise<Option<Device>> {
        const device = this.subject.value.find((device) => device.id === id);

        return device !== undefined ? Option.some(device) : Option.none();
    }
}
