import {HttpLink} from 'apollo-link-http';

export class HttpLinkProvider {
    private httpLink: HttpLink | undefined;

    constructor(private uri: string) {}

    public get(): HttpLink {
        if (this.httpLink === undefined) {
            this.httpLink = new HttpLink({uri: this.uri});
        }

        return this.httpLink;
    }
}
