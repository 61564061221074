import {Race} from '../models/race';
import {DefaultParticipantsRepository, ParticipantsRepository} from './participants_repository';

export class ParticipantsRepositoryFactory {
    private repositories = new Map<string, ParticipantsRepository>();

    public create(race: Race): ParticipantsRepository {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(race.id, new DefaultParticipantsRepository());
        }
        return this.repositories.get(race.id)!;
    }
}
