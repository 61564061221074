import {Header} from './header';
import {ConnectionHint} from './connection_hint';
import * as React from 'react';
import {Race} from '../../models/race';
import {LeaveButton} from './buttons';
import {ToggleTokenButton} from '../toggle_token/toggle_token_button';

interface OwnProps {
    race: Race;
}

export const LobbyHeader = ({race}: OwnProps) => (
    <Header>
        <ConnectionHint race={race} />
        <LeaveButton to="/" className="float-right">
            Leave room
            <span className="ion-md-exit" />
        </LeaveButton>
        <ToggleTokenButton raceId={race.id} />
    </Header>
);
