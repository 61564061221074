import * as React from 'react';
import {FunctionComponent} from 'react';
import {Text} from 'react-konva';
import {animated as Animated, SpringValue} from 'react-spring/konva';

interface OwnProps {
    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
    opacity?: number | SpringValue<number>;
    align?: string;
    animated?: boolean;
}

export const OverlayText: FunctionComponent<OwnProps> = ({animated, text, x, y, width, height, align, opacity}) => {
    if (animated) {
        return (
            <Animated.Text
                text={text.toUpperCase()}
                verticalAlign="middle"
                wrap="none"
                align={align ? align : 'center'}
                fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                fontSize={16}
                ellipsis={true}
                lineHeight={36}
                fill="white"
                x={x}
                y={y + 2}
                width={width}
                height={height}
                opacity={opacity}
            />
        );
    }
    return (
        <Text
            text={text.toUpperCase()}
            verticalAlign="middle"
            wrap="none"
            align={align ? align : 'center'}
            fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
            fontSize={16}
            ellipsis={true}
            lineHeight={36}
            fill="white"
            x={x}
            y={y + 2}
            width={width}
            height={height}
            opacity={opacity as number}
        />
    );
};
