import {CountdownInteractor} from './countdown_interactor';
import {BestServerTimeInteractor} from './server_time/best_server_time_interactor';
import {BurstServerTimeInteractor} from './server_time/burst_server_time_interactor';
import {ServerTimeProvider, ServerTimeProviderImpl} from './server_time/server_time_provider';
import {SharedServerTimeInteractor} from './server_time/shared_server_time_interactor';
import {SingleServerTimeInteractor} from './server_time/single_server_time_interactor';
import {TimeProvider, TimeProviderImpl} from './server_time/time_provider';
import {WeightedServerTimeInteractor} from './server_time/weighted_server_time_interactor';
import {ServerTimeInteractor} from './server_time_interactor';
import {AsyncScheduler} from 'rxjs/internal/scheduler/AsyncScheduler';
import {AsyncAction} from 'rxjs/internal/scheduler/AsyncAction';
import {TimeApi} from '../../network/time_api';
import {lazy} from '../../support/lazy';

export interface ServerTimeComponent {
    serverTimeProvider: ServerTimeProvider;
    countdownInteractor: CountdownInteractor;
}

export class DefaultServerTimeComponent implements ServerTimeComponent {
    constructor(private timeApi: TimeApi, private scheduler = new AsyncScheduler(AsyncAction)) {}

    @lazy()
    get serverTimeInteractor(): ServerTimeInteractor {
        return new SharedServerTimeInteractor(
            new WeightedServerTimeInteractor(
                new BestServerTimeInteractor(
                    new BurstServerTimeInteractor(new SingleServerTimeInteractor(this.timeApi), 10, 4, 30000),
                ),
            ),
        );
    }

    @lazy()
    get timeProvider(): TimeProvider {
        return new TimeProviderImpl();
    }

    @lazy()
    get serverTimeProvider(): ServerTimeProvider {
        return new ServerTimeProviderImpl(this.serverTimeInteractor, this.timeProvider);
    }

    @lazy()
    get countdownInteractor(): CountdownInteractor {
        return new CountdownInteractor(this.serverTimeProvider, this.scheduler);
    }
}
