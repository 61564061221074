import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Layer} from 'react-konva';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {Overlay, OverlaysPresenter} from './overlays_presenter';
import {Clock} from './overlays/clock/clock';
import {BoatsScreenComponent} from '../boats_screen_component';
import {StrategyBasedActiveOverlaysProvider} from './internal/strategy_based_active_overlays_provider';
import {ClockOverlayStrategy} from './internal/overlays/clock_overlay_strategy';
import {SplitTimesOverlayStrategy} from './internal/overlays/split_times_overlay_strategy';
import {Tables} from './tables';
import {StrokeRatesOverlayStrategy} from './internal/overlays/stroke_rates_overlay_strategy';
import {DefaultStartSignalProvider} from './internal/overlays/internal/start_signal_provider';
import {SpeedsOverlayStrategy} from './internal/overlays/speeds_overlay_strategy';
import {StartListOverlayStrategy} from './internal/overlays/start_list_overlay_strategy';
import {Lane} from '../internal/lanes_provider';
import {StartList} from './overlays/start_list/start_list';
import {Race} from '../../../models/race';
import {FinishList} from './overlays/finish_list/finish_list';
import {FinishListOverlayStrategy} from './internal/overlays/finish_list_overlay_strategy';

interface OwnProps {
    race: Race;
    lanes: readonly Lane[];
    canvasWidth: number;
    canvasHeight: number;
    boatScreenComponent: BoatsScreenComponent;
}

const OverlaysComponent: FunctionComponent<OwnProps & PresenterProps<OverlaysPresenter>> = observer(
    ({presenter, boatScreenComponent, canvasHeight, canvasWidth, race, lanes}) => {
        return (
            <Layer listening={false}>
                {presenter.activeOverlays.includes(Overlay.Clock) && (
                    <Clock key="clock" boatScreenComponent={boatScreenComponent} x={25} y={25} />
                )}
                {presenter.activeOverlays.includes(Overlay.StartList) && (
                    <StartList
                        key="start-list"
                        race={race}
                        lanes={lanes}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                    />
                )}
                {presenter.activeOverlays.includes(Overlay.FinishList) && (
                    <FinishList
                        key="finish-list"
                        race={race}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        boatScreenComponent={boatScreenComponent}
                    />
                )}
                {!presenter.activeOverlays.includes(Overlay.StartList) &&
                    !presenter.activeOverlays.includes(Overlay.FinishList) && (
                        <Tables
                            key="tables"
                            x={25}
                            y={canvasHeight - 270}
                            activeOverlays={presenter.activeOverlays}
                            boatScreenComponent={boatScreenComponent}
                        />
                    )}
            </Layer>
        );
    },
);

export const Overlays = withPresenter<OverlaysPresenter, OwnProps>((props, component) => {
    const startSignalProvider = new DefaultStartSignalProvider(
        props.boatScreenComponent.startTimeProvider,
        component.serverTimeComponent.serverTimeProvider,
    );

    const splitTimesOverlayStrategy = new SplitTimesOverlayStrategy(props.boatScreenComponent.splitTimesProvider);

    const strategyBasedActiveOverlaysProvider = new StrategyBasedActiveOverlaysProvider([
        new StartListOverlayStrategy(props.boatScreenComponent.startTimeProvider),
        new FinishListOverlayStrategy(props.boatScreenComponent.boatsProgressProvider),
        new ClockOverlayStrategy(
            props.boatScreenComponent.startTimeProvider,
            props.boatScreenComponent.boatsProgressProvider,
        ),
        splitTimesOverlayStrategy,
        new StrokeRatesOverlayStrategy(splitTimesOverlayStrategy, startSignalProvider),
        new SpeedsOverlayStrategy(splitTimesOverlayStrategy, startSignalProvider),
    ]);
    return new OverlaysPresenter(strategyBasedActiveOverlaysProvider);
}, OverlaysComponent);
