import * as React from 'react';
import {StyledTooltip} from '../../../../components/tooltip';
import {AlertSquare} from '../../../../components/alert_square';

export const FalseStartIndicator: React.FunctionComponent = () => {
    return (
        <StyledTooltip label={'False start'}>
            <AlertSquare style={{backgroundColor: 'rgb(235,82,85)'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
                    <g
                        id="Icon_material-priority-high"
                        data-name="Icon material-priority-high"
                        transform="translate(-15 -4.5)">
                        <path
                            id="Path_400"
                            data-name="Path 400"
                            d="M19,27.5a2,2,0,1,1-2-2A2,2,0,0,1,19,27.5Z"
                            transform="translate(0 -7)"
                            fill="#fff"
                        />
                        <path
                            id="Path_401"
                            data-name="Path 401"
                            d="M15,4.5h4v12H15Z"
                            transform="translate(0)"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </AlertSquare>
        </StyledTooltip>
    );
};
