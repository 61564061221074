import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {ToggleTokenSocket} from '../../network/toggle_token_socket';
import {CompositeSubscription} from '../../support/composite_subscription';
import {RaceProvider} from '../../business/race/race_provider';
import {runInAction} from 'mobx';

export class BigScreenPresenter implements Presenter {
    public race: Race;

    public isTokenVisible = false;

    private subscriptions = new CompositeSubscription();

    constructor(initialRace: Race, private raceProvider: RaceProvider, private toggleTokenSocket: ToggleTokenSocket) {
        this.race = initialRace;
    }

    public async mount() {
        this.subscriptions.add(
            this.raceProvider.get().subscribe((raceTry) => {
                raceTry.map((race) => {
                    runInAction(() => {
                        this.race = race;
                    });
                });
            }),
        );

        this.subscriptions.add(
            this.toggleTokenSocket.getForRaceId(this.race.id).subscribe((data) => {
                runInAction(() => {
                    this.isTokenVisible = data.isTokenVisible;
                });
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
