import styled from 'styled-components';
import {Col} from '../../components/col';
import * as React from 'react';
import {FunctionComponent} from 'react';

interface OwnProps {
    numCols: number;
    className?: string;
    offsetCols?: number;
}

const Background = styled.div`
    z-index: -1;
    background-image: url('/images/bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    margin: 0 -15px;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, rgba(0, 201, 255, 0.9), rgba(6, 156, 193, 0.9));
        background-size: cover;
        background-position: center 80px;
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
    }
`;

const SidebarColComponent = styled(Col)`
    max-height: 100%;
    display: flex;
    flex-direction: column;

    p {
        font-size: 15px;
        color: #ffffff;
        margin: 0 15px;
        line-height: 18px;
        font-weight: 500;
    }
`;

export const SidebarCol: FunctionComponent<OwnProps> = (props) => {
    return (
        <SidebarColComponent {...props}>
            <Background />
            {props.children}
        </SidebarColComponent>
    );
};
