import {BehaviorSubject, Observable} from 'rxjs';
import {StartResultRepository as StartResultProviderStartResultRepository} from '../business/start_result/start_result_provider';
import {StartResult} from '../models/start_result';

export interface StartResultRepository extends StartResultProviderStartResultRepository {}

export class DefaultStartResultRepository implements StartResultRepository {
    private subject = new BehaviorSubject<StartResult | null>(null);

    public get(): Observable<StartResult | null> {
        return this.subject;
    }

    public update(startResult: StartResult): void {
        this.subject.next(startResult);
    }
}
