import {SplitTimesProvider} from '../split_times_overlay_strategy';
import {Observable} from 'rxjs';
import {SplitTime} from '../../../../../../models/split_time';

export interface SplitTimeSocket {
    forRaceId(raceId: string): Observable<SplitTime>;
}

export class DefaultSplitTimeProvider implements SplitTimesProvider {
    constructor(private raceId: string, private splitTimeSocket: SplitTimeSocket) {}

    public get splitTimes(): Observable<SplitTime> {
        return this.splitTimeSocket.forRaceId(this.raceId);
    }
}
