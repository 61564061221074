import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../models/race';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {StartIntentButtonPresenter} from './start_intent_button_presenter';
import styled from 'styled-components';
import {ApiStartIntentInteractor} from './internal/start_intent_interactor';
import {RaceState} from '../../../enumerations/race_state';

interface OwnProps {
    race: Race;
}

const IconButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    line-height: 24px;
    margin: 0 10px;
    color: #7c7c7c;

    .label {
        display: none;
        position: absolute;
        font-size: 16px;
        margin: 0 10px 0 0;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: bold;
    }
    &:hover {
        .label {
            position: absolute;
            transform: translateX(-230px);
            display: inline-block;
        }
    }
`;

const StartIntentButtonComponent: FunctionComponent<OwnProps & PresenterProps<StartIntentButtonPresenter>> = observer(
    ({race, presenter}) => {
        if (![RaceState.IDLE, RaceState.ABORTED].includes(race.state)) {
            return null;
        }
        if (presenter.intentToStartAtMillis === null) {
            return (
                <IconButton onClick={() => presenter.onIntentToStartClick()}>
                    <span className="label">Announce start signal</span>
                    <span className="ion-ios-notifications" />
                </IconButton>
            );
        }
        return (
            <IconButton onClick={() => presenter.onUndoIntentToStartClick()}>
                <span className="label">Retract start signal</span>
                <span className="ion-ios-notifications-off" />
            </IconButton>
        );
    },
);

export const StartIntentButton = withPresenter<StartIntentButtonPresenter, OwnProps>((props, component) => {
    const apiStartIntentInteractor = new ApiStartIntentInteractor(
        props.race,
        component.networkComponent.raceApi,
        component.raceProviderFactory.create(props.race),
        component.serverTimeComponent.serverTimeProvider,
    );
    return new StartIntentButtonPresenter(apiStartIntentInteractor);
}, StartIntentButtonComponent);
