import io from 'socket.io-client';

export class SocketIoClientProvider {
    private socket: SocketIOClient.Socket | undefined;
    public get(): SocketIOClient.Socket {
        if (this.socket === undefined) {
            this.socket = io('/');
        }

        return this.socket;
    }
}
