import * as React from 'react';
import styled from 'styled-components';
import {FunctionComponent} from 'react';
import {Logo} from '../../components/logo';

const StyledNavigationBar = styled.div`
    height: 30px;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid white;
`;

const StyledNavigationList = styled.ul`
    float: right;
    height: 100%;
    margin: 0;
    line-height: 23px;
    padding: 0 0.294rem 0;
`;

const StyledNavigationListItem = styled.li`
    float: left;
    list-style-type: none;
    padding: 0.17rem 0.588rem;
    position: relative;
    &:last-child {
        &:before {
            content: none;
        }
    }
    &:before {
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: #00c9ff;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -3px;
    }
`;

const StyledNavigationListItemLink = styled.a`
    font-size: 12px;
    color: #aaaaaa;
    padding: 0 0.882rem;
    font-weight: bold;
    &.active {
        color: black;
    }
`;

const NavigationLinkItem: FunctionComponent<{url: string; description: string; active?: boolean; last?: boolean}> = ({
    url,
    description,
    active,
    last,
}) => {
    let calculatedClassName = '';
    calculatedClassName += active ? 'active' : '';
    calculatedClassName += last ? ' last' : '';
    return (
        <StyledNavigationListItem>
            <StyledNavigationListItemLink href={url} className={calculatedClassName}>
                {description}
            </StyledNavigationListItemLink>
        </StyledNavigationListItem>
    );
};

export const NavigationBar: FunctionComponent = () => {
    return (
        <StyledNavigationBar>
            <Logo />
            <StyledNavigationList>
                <NavigationLinkItem url="https://rp3rowing-app.com/" description="Analyze" />
                <NavigationLinkItem url="https://studio.rp3rowing-app.com/" description="Studio" />
                <NavigationLinkItem url="/" description="E-racing" active={true} last={true} />
            </StyledNavigationList>
        </StyledNavigationBar>
    );
};
