import {Device} from '../../../../models/device';
import {Try} from '../../../../support/monads/try';
import {Option} from '../../../../support/monads/option';
import {DeviceToParticipantAssigner} from '../boats_grid_drag_handler_presenter';

export interface UpdateDeviceRepository {
    byId(id: string): Promise<Option<Device>>;
    update(device: Device): void;
}

export interface UpdateDeviceApi {
    update(id: string, raceId: string, participantId: string | null): Promise<Try<Device>>;
}

export class ApiDeviceToDeviceAssigner implements DeviceToParticipantAssigner {
    constructor(
        private raceId: string,
        private updateDeviceRepository: UpdateDeviceRepository,
        private updateDeviceApi: UpdateDeviceApi,
    ) {}

    public async assignToParticipant(deviceId: string, participantId: string | null): Promise<Try<Device>> {
        const deviceOption = await this.updateDeviceRepository.byId(deviceId);

        return deviceOption.fold<Promise<Try<Device>>>(
            async (originalDevice) => {
                this.updateEnthusiastically(originalDevice, participantId);

                const deviceTry = await this.updateDeviceApi.update(originalDevice.id, this.raceId, participantId);
                return this.writeOrRevert(deviceTry, originalDevice);
            },
            async () => Try.raiseError(new Error('Trying to update unknown device')),
        );
    }

    private writeOrRevert(deviceTry: Try<Device>, originalDevice: Device) {
        return deviceTry.fold(
            (device) => {
                this.updateDeviceRepository.update(device);
                return Try.just(device);
            },
            (e) => {
                this.updateDeviceRepository.update(originalDevice);
                return Try.raiseError(e);
            },
        );
    }

    private updateEnthusiastically(originalDevice: Device, participantId: string | null) {
        this.updateDeviceRepository.update({
            ...originalDevice,
            participantId,
        });
    }
}
