import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Block} from '../components/block';
import {Boat} from '../../../../models/boat';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {BoatBlockPresenter} from './boat_block_presenter';
import {Col} from '../../../../components/col';
import {Row} from '../../../../components/row';
import {DefaultParticipantsForBoatProvider} from './internal/participants_for_boat_provider';
import {Race} from '../../../../models/race';
import {PlaceholderRow} from './components/placeholder_row';
import {CombinationRow} from './components/combination_row';
import {ApiCombinationProvider} from './internal/combination_provider';
import {ApiBoatDeleter} from './internal/boat_deleter';
import {BoatEditModal} from '../../boat_edit_modal/boat_edit_modal';
import {BoatTypePill} from './components/boat_type_pill';
import {Can} from '../../../components/can';
import {Permission} from '../../../../enumerations/permission';

interface OwnProps {
    boat: Boat;
    race: Race;
}

const BoatBlockComponent: FunctionComponent<OwnProps & PresenterProps<BoatBlockPresenter>> = observer(
    ({presenter, boat, race}) => {
        const {combinations, isEditModalOpen, onDeleteClick, onBoatEditClick, closeBoatEditModal} = presenter;
        return (
            <>
                {isEditModalOpen && <BoatEditModal isOpen={isEditModalOpen} onClose={closeBoatEditModal} boat={boat} />}
                <Block
                    fixedWidth
                    title={
                        <>
                            {boat.name}
                            <BoatTypePill boatType={boat.type} />
                            <Can permission={Permission.EDIT_BOAT} race={race}>
                                <button className="ion-md-close" onClick={onDeleteClick} />
                                <button className="ion-md-create" onClick={onBoatEditClick} />
                            </Can>
                        </>
                    }>
                    {combinations.map((combination, index) => (
                        <Row key={combination.participant.id}>
                            <Col numCols={12}>
                                <CombinationRow index={index + 1} combination={combination} boat={boat} race={race} />
                            </Col>
                        </Row>
                    ))}
                    <Can permission={Permission.EDIT_BOAT} race={race}>
                        <Row>
                            <Col numCols={12}>
                                <PlaceholderRow key="placeholder" index={combinations.length + 1} boat={boat} />
                            </Col>
                        </Row>
                    </Can>
                </Block>
            </>
        );
    },
);

export const BoatBlock = withPresenter<BoatBlockPresenter, OwnProps>(
    (props, component) =>
        new BoatBlockPresenter(
            new ApiCombinationProvider(
                new DefaultParticipantsForBoatProvider(
                    props.boat.id,
                    component.participantsProviderFactory.create(props.race),
                ),
                component.devicesProviderFactory.create(props.race),
            ),
            new ApiBoatDeleter(props.boat.id, component.networkComponent.boatsApi),
        ),
    BoatBlockComponent,
);
