import {Try} from '../../../support/monads/try';
import {Boat} from '../../../models/boat';
import {Presenter} from '../../../support/with_presenter';
import {toast} from 'react-toastify';
import {BoatType} from '../../../models/boat_type';

export interface BoatUpdater {
    update(boat: Boat): Promise<Try<Boat>>;
}

export class BoatEditModalPresenter implements Presenter {
    public boat: Boat;

    constructor(initialBoat: Boat, private boatUpdater: BoatUpdater) {
        this.boat = {
            ...initialBoat,
        };
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onBoatNameChange = (name: string) => {
        this.boat = {
            ...this.boat,
            name,
        };
    };

    public onBoatTypeChange = (type: BoatType | 'none') => {
        this.boat = {
            ...this.boat,
            type: type === 'none' ? null : type,
        };
    };

    public onSave = async () => {
        try {
            await this.boatUpdater.update(this.boat);
        } catch (error) {
            toast('Something went wrong while saving boat.', {
                type: toast.TYPE.ERROR,
            });
            throw error;
        }
    };
}
