import {SplitTimeSocket} from '../ui/boats_screen/overlays/internal/overlays/internal/split_time_provider';
import {fromEvent, Observable} from 'rxjs';
import {SplitTime} from '../models/split_time';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';

export class DefaultSplitTimeSocket implements SplitTimeSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public forRaceId(raceId: string): Observable<SplitTime> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/split-times`);
    }
}
