import * as React from 'react';
import {FunctionComponent} from 'react';
import {Rect} from 'react-konva';
import {OverlayText} from './overlay_text';
import {SpringValue} from 'react-spring';
import {animated} from 'react-spring/konva';

interface OwnProps {
    x: number;
    y: number;
    highlighted?: boolean;
    name: string;
    number?: string;
    metric?: string;
    highlightFill?: string;
    opacity?: SpringValue<number>;
}

export const StatisticRowComponent: FunctionComponent<OwnProps> = ({
    x,
    y,
    highlighted,
    highlightFill,
    number,
    name,
    metric,
    opacity,
}) => {
    return (
        <animated.Group x={x} y={y} opacity={opacity}>
            {number && (
                <Rect
                    x={0}
                    y={0}
                    fill={highlighted ? (highlightFill ? highlightFill : '#04B6A9') : '#00C9FF'}
                    width={36}
                    height={36}
                    cornerRadius={[5, 0, 0, 5]}
                />
            )}
            {number && <OverlayText text={number} x={0} y={0} width={36} height={36} />}
            <Rect
                x={number ? 36 : 0}
                y={0}
                fill={'rgb(25,63,73,0.7)'}
                width={number ? 255 : 255 + 36}
                height={36}
                cornerRadius={number ? [0, 5, 5, 0] : 5}
            />
            <OverlayText text={name} x={number ? 36 + 10 : 10} y={0} width={200} height={36} align="left" />
            {metric && <OverlayText text={metric} x={255 - 50} y={0} width={65} height={36} align="right" />}
        </animated.Group>
    );
};
