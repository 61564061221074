import {auditTime, first} from 'rxjs/operators';
import {merge, Observable} from 'rxjs';
import {AnimationFrameAction} from 'rxjs/internal/scheduler/AnimationFrameAction';
import {AnimationFrameScheduler} from 'rxjs/internal/scheduler/AnimationFrameScheduler';

//Emits the first possible value, then throttles the observable by durations ms, and always emits last value
export function debounceThrottle<T>(obs: Observable<T>, duration: number): Observable<T> {
    return merge(obs.pipe(first()), obs.pipe(auditTime(duration)));
}

//Emits the first possible value, then throttles the observable until the next animation frame, and always emits last value
const customAnimationFrameScheduler = new AnimationFrameScheduler(AnimationFrameAction);
export function debounceThrottleAnimationFrame<T>(obs: Observable<T>, minDuration: number = 0): Observable<T> {
    return merge(obs.pipe(first()), obs.pipe(auditTime(minDuration, customAnimationFrameScheduler)));
}
