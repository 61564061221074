import * as React from 'react';
import {FunctionComponent} from 'react';
import {BlockItem} from './block_item';
import {Draggable} from 'react-beautiful-dnd';
import {DraggableType} from '../boats_grid_drag_handler_presenter';

interface OwnProps {
    id: string;
    type: DraggableType;
    index: number;
    dark?: boolean;
}

export const DraggableBlockItem: FunctionComponent<OwnProps> = ({id, type, dark, index, children}) => {
    return (
        <Draggable disableInteractiveElementBlocking={true} draggableId={id} type={type} index={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <BlockItem dark={dark}>{children}</BlockItem>
                </div>
            )}
        </Draggable>
    );
};
