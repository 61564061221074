import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {BoatType, boatTypeToString} from '../../../../../models/boat_type';

interface OwnProps {
    boatType: BoatType | null;
}

const Pill = styled.div`
    margin: 0 5px;
`;

export const BoatTypePill: FunctionComponent<OwnProps> = ({boatType}) => {
    if (boatType === null) {
        return null;
    }
    return <Pill className="badge badge-pill badge-secondary">{boatTypeToString(boatType)}</Pill>;
};
