/**
 * Inspired by https://github.com/arrow-kt/arrow/blob/master/modules/core/arrow-core-data/src/main/kotlin/arrow/core/Try.kt
 */
export abstract class Try<T> {
    public static raiseError<TStatic>(error: Error): Try<TStatic> {
        return new TryFailure(error);
    }

    public static just<TStatic>(value: TStatic): Try<TStatic> {
        return new TrySuccess<TStatic>(value);
    }

    public abstract fold<B>(onSuccess: (value: T) => B, onFailure: (error: Error) => B): B;

    public abstract getOrElse(callback: () => T): T;

    public abstract map<B>(callback: (value: T) => B): Try<B>;

    public abstract flatMap<B>(callback: (value: T) => Try<B>): Try<B>;

    public abstract handleError(callback: (error: Error) => void): void;
}

class TryFailure<T> extends Try<T> {
    constructor(private error: Error) {
        super();
    }

    public getOrElse(callback: () => T): T {
        return callback();
    }

    public fold<B>(onSuccess: (value: T) => B, onFailure: (error: Error) => B): B {
        return onFailure(this.error);
    }

    public map<B>(callback: (value: T) => B): Try<B> {
        //We're passing on the failure, so type T is not interesting anymore
        return this as any;
    }

    public flatMap<B>(callback: (value: T) => Try<B>): Try<B> {
        //We're passing on the failure, so type T is not interesting anymore
        return this as any;
    }

    public handleError(callback: (error: Error) => void): void {
        callback(this.error);
    }
}

class TrySuccess<T> extends Try<T> {
    constructor(public value: T) {
        super();
    }

    public getOrElse(callback: () => T): T {
        return this.value;
    }

    public fold<B>(onSuccess: (value: T) => B, onFailure: (error: Error) => B): B {
        return onSuccess(this.value);
    }

    public map<B>(callback: (value: T) => B): Try<B> {
        return Try.just(callback(this.value));
    }

    public flatMap<B>(callback: (value: T) => Try<B>): Try<B> {
        return callback(this.value);
    }

    public handleError(callback: (error: Error) => void): void {
        /* Noop */
    }
}
