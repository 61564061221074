import styled from 'styled-components';

export const AlertSquare = styled.div`
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 4px 2px;
    position: relative;

    svg {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 5px;
        left: 5px;
    }
`;
