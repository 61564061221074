import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {BoatsScreenComponent} from '../../../boats_screen_component';
import {StrokeRatesPresenter} from './stroke_rates_presenter';
import {formatStrokeRate} from '../../../../../support/format_stroke_rate';
import {StatisticsTable} from '../components/statistics_table';

interface OwnProps {
    x: number;
    y: number;
    boatScreenComponent: BoatsScreenComponent;
}

const StrokeRatesComponent: FunctionComponent<OwnProps & PresenterProps<StrokeRatesPresenter>> = observer(
    ({x, y, presenter}) => {
        return (
            <StatisticsTable
                x={x}
                y={y}
                title="Stroke rates"
                rows={presenter.strokeRates.map((strokeRate) => {
                    return {
                        name: strokeRate.boatName,
                        formattedMetric: formatStrokeRate(strokeRate.strokeRate),
                    };
                })}
            />
        );
    },
);

export const StrokeRates = withPresenter<StrokeRatesPresenter, OwnProps>(
    (props, component) => new StrokeRatesPresenter(props.boatScreenComponent.boatsProgressProvider),
    StrokeRatesComponent,
);
