import {merge, Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {map, shareReplay} from 'rxjs/operators';
import {Boat} from '../../models/boat';

export interface BoatsProvider {
    get(): Observable<Try<Boat[]>>;
}

export class CombiningBoatsProvider implements BoatsProvider {
    protected observable = merge(...this.boatsProviders.map((boatsProvider) => boatsProvider.get())).pipe(
        map((boatsTry: Try<Boat[]>) =>
            boatsTry.map((boats) => {
                const result = [...boats];
                result.sort((a, b) => a.name.localeCompare(b.name));

                return result;
            }),
        ),
        shareReplay(1),
    );

    constructor(private boatsProviders: BoatsProvider[]) {}

    public get(): Observable<Try<Boat[]>> {
        return this.observable;
    }
}
