import {Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Boat} from '../../models/boat';
import {map, tap, shareReplay} from 'rxjs/operators';
import {BoatsProvider} from './boats_provider';

export interface BoatsRepository {
    replace(boats: Boat[]): void;
}

export interface BoatsForRaceSocket {
    getForRaceId(raceId: string): Observable<Boat[]>;
}

export class SocketBoatProvider implements BoatsProvider {
    private observable = this.boatForRaceSocket.getForRaceId(this.raceId).pipe(
        tap((boats) => this.boatsRepository.replace(boats)),
        map((boats) => Try.just(boats)),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private boatForRaceSocket: BoatsForRaceSocket,
        private boatsRepository: BoatsRepository,
    ) {}

    public get(): Observable<Try<Boat[]>> {
        return this.observable;
    }
}
