import * as React from 'react';
import {FunctionComponent} from 'react';

interface OwnProps {
    className?: string;
    innerRef?: React.Ref<any>;
}

export const Row: FunctionComponent<OwnProps> = ({innerRef, children, className}) => {
    let calculatedClassName = 'row';
    if (className !== undefined) {
        calculatedClassName += ' ' + className;
    }
    return (
        <div ref={innerRef} className={calculatedClassName}>
            {children}
        </div>
    );
};
