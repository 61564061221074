import {fromEvent, Observable} from 'rxjs';
import {Device} from '../models/device';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {DevicesForRaceSocket} from '../business/devices/socket_device_provider';

export interface DeviceSocket extends DevicesForRaceSocket {}

export class DefaultDeviceSocket implements DeviceSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<Device[]> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/devices`);
    }
}
