import {Combination, CombinationProvider} from '../boat_block_presenter';
import {DevicesProvider} from '../../../../../business/devices/device_provider';
import {combineLatest, Observable} from 'rxjs';
import {Try} from '../../../../../support/monads/try';
import {map} from 'rxjs/operators';
import {Participant} from '../../../../../models/participant';
import {Device} from '../../../../../models/device';

export interface ParticipantsForBoatProvider {
    get(): Observable<Try<Participant[]>>;
}

export class ApiCombinationProvider implements CombinationProvider {
    constructor(
        private participantsForBoatProvider: ParticipantsForBoatProvider,
        private devicesProvider: DevicesProvider,
    ) {}

    public get(): Observable<Try<Combination[]>> {
        return combineLatest(
            this.participantsForBoatProvider.get(),
            this.devicesProvider.get(),
            (participantsTry, devicesTry): [Try<Participant[]>, Try<Device[]>] => [participantsTry, devicesTry],
        ).pipe(
            map(([participantsTry, devicesTry]) => {
                return participantsTry.flatMap((participants: Participant[]) =>
                    devicesTry.map((devices: Device[]) =>
                        participants.map((participant: Participant) => ({
                            participant: participant,
                            device: devices.find((device: Device) => device.participantId === participant.id) || null,
                        })),
                    ),
                );
            }),
        );
    }
}
