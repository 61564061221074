import {Presenter} from '../../../support/with_presenter';
import {runInAction} from 'mobx';
import {StartTimeProvider} from '../../../business/race/start_time_provider';
import {CountdownInteractor} from '../../../business/countdown/countdown_interactor';
import {CompositeSubscription} from '../../../support/composite_subscription';

export class CountdownPresenter implements Presenter {
    public countdown: number | null = null;

    private _subscriptions = new CompositeSubscription();

    constructor(private startTimeProvider: StartTimeProvider, private countdownInteractor: CountdownInteractor) {}

    public mount(): void {
        this._subscriptions.add(
            this.startTimeProvider.get().subscribe((startTimeMillis) => {
                if (startTimeMillis !== null) {
                    this.startCountdown(startTimeMillis);
                } else {
                    runInAction(() => {
                        this.countdown = null;
                    });
                }
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    private startCountdown(startTimeMillis: number) {
        const countDownSubscription = this.countdownInteractor.countdown(startTimeMillis).subscribe((countdown) => {
            runInAction(() => {
                const roundedCountdown = Math.round((countdown * -1) / 1000);
                this.countdown = roundedCountdown;

                if (roundedCountdown <= 0) {
                    countDownSubscription.unsubscribe();
                }
            });
        });
    }
}
