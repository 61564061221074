import styled from 'styled-components';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {AlertSquare} from '../../components/alert_square';
import {ConnectionIndicator} from '../boats_grid/devices_block/components/connection_indicator';
import {Block} from '../../../ui_mobile/components/block';
import {BoatWithCombinations} from '../../../business/boats/boats_with_combinations_provider';

const AlertSquareContainer = styled.div`
    top: 0.5rem;
    right: 0.5rem;
    * {
        padding: 0;
        margin: 0;
    }
`;

const BlockElement = styled(Block)`
    display: block;
    position: relative;
    min-height: 3rem;
    padding: 0;
    margin: auto auto 1rem auto;
`;

const BoatTitle = styled.div`
    border-bottom: 2px solid #eaeaea;
    padding: 0.75rem;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
`;

const ParticipantBlockElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
`;

interface OwnProps {
    boatWithCombinations: BoatWithCombinations;
}

export const BoatBlock: FunctionComponent<OwnProps> = ({boatWithCombinations}) => {
    return (
        <div className="row">
            <BlockElement className="col-10 offset-1">
                <BoatTitle>{boatWithCombinations.boat.name}</BoatTitle>
                {boatWithCombinations.combinations.map((combination) => {
                    return (
                        <ParticipantBlockElement key={combination.participant.id}>
                            {combination.participant.name}
                            <AlertSquareContainer>
                                <AlertSquare>
                                    <ConnectionIndicator
                                        ping={combination.device.ping}
                                        lastPingReceived={combination.device.lastPingReceivedMillis}
                                    />
                                </AlertSquare>
                            </AlertSquareContainer>
                        </ParticipantBlockElement>
                    );
                })}
            </BlockElement>
        </div>
    );
};
