import {BoatWithCombinations} from '../../../../../business/boats/boats_with_combinations_provider';
import {Race} from '../../../../../models/race';
import {Try} from '../../../../../support/monads/try';
import {CanvasSizes, RealtimeBoatProgress} from '../../../boats_screen_presenter';
import {OffsetCalculator} from '../camera_position_calculator';

export class MovingOffsetCalculator implements OffsetCalculator {
    constructor(private cameraTopPaddingInPx: number = 100) {}

    /**
     * If the fastest boat reached the top of the screen, start following that boat
     */
    public calculate(
        previousCalculatorOffset: number,
        race: Race,
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number {
        const furthestBoatX = Math.min(...realtimeBoatProgresses.map((b) => b.state.top));
        if (furthestBoatX - this.cameraTopPaddingInPx + previousCalculatorOffset < 0) {
            return -furthestBoatX + this.cameraTopPaddingInPx;
        }

        return previousCalculatorOffset;
    }
}
