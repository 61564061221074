import * as React from 'react';
import {FunctionComponent} from 'react';
import {Background} from '../../components/background';
import {observer} from 'mobx-react-lite';
import {DistanceBlock} from '../components/distance_block';
import {DistanceBlockForm} from './components/distance_block_form';
import {Heading1} from '../../components/heading';
import {Leader} from '../../components/text';
import {Race} from '../../../models/race';
import {isAuthorized} from '../../../business/permissions/is_authorized';
import {Permission} from '../../../enumerations/permission';
import {withPresenter, PresenterProps} from '../../../support/with_presenter';
import {SelectDistanceFormPresenter} from '../select_distance_form_presenter';
import {ApiRaceDistanceUpdater} from '../select_distance_form/internal/race_distance_updater';
import {NavigateFn} from '@reach/router';
import {LargeButton} from '../../../ui_mobile/components/form/button';

interface OwnProps {
    navigate: NavigateFn;
    race: Race;
}

export const MobileSelectDistanceFormComponent: FunctionComponent<
    OwnProps & PresenterProps<SelectDistanceFormPresenter>
> = observer(({presenter, race}) => {
    const distanceMetersPresets = [200, 500, 2000, 5000];
    const raceHasCustomDistance =
        distanceMetersPresets.some((distance) => distance === race.distanceMeters) === false && race.distanceMeters > 0;

    const canChange = isAuthorized(Permission.EDIT_RACE, race);

    return (
        <>
            <Background />
            <div className="container align-self-start">
                <div className="row">
                    <div className="col-12 text-center">
                        <Heading1>Select race distance</Heading1>
                        <Leader>
                            Select the distance you want to use for this race. All connected devices will setup this
                            distance.
                        </Leader>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="row">
                            {distanceMetersPresets.map((distanceMeters) => (
                                <div className="col-6" key={distanceMeters}>
                                    <DistanceBlock
                                        disabled={!canChange}
                                        distanceMeters={distanceMeters}
                                        active={race.distanceMeters === distanceMeters}
                                        onClick={() =>
                                            !presenter.busy && canChange && presenter.onDistanceNavigate(distanceMeters)
                                        }
                                    />
                                </div>
                            ))}
                            <div className="col-12">
                                <DistanceBlockForm
                                    disabled={!canChange}
                                    initialDistance={raceHasCustomDistance ? race.distanceMeters : undefined}
                                    distanceMeters={race.distanceMeters}
                                    onChange={(distanceMeters) =>
                                        canChange && presenter.onDistanceNavigate(distanceMeters)
                                    }
                                />
                            </div>
                        </div>
                        <LargeButton to="/" disabled={false} color="darkBlue">
                            Leave race
                            <span className="ion-md-exit" />
                        </LargeButton>
                    </div>
                </div>
            </div>
        </>
    );
});

export const MobileSelectDistanceForm = withPresenter<SelectDistanceFormPresenter, OwnProps>(
    (props, component) =>
        new SelectDistanceFormPresenter(
            props.race.id,
            props.navigate,
            new ApiRaceDistanceUpdater(
                props.race.id,
                component.raceRepositoryFactory.create(props.race),
                component.networkComponent.raceApi,
            ),
        ),
    MobileSelectDistanceFormComponent,
);
