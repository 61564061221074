import * as React from 'react';
import styled from 'styled-components';

const SwitchButtonLabel = styled.label`
    margin: 1.965rem 0 1.765rem 0.588rem;

    display: inline-block;
    height: 40px;
    position: relative;
    width: 80px;
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: pointer;
`;

const Tick = styled.div`
    height: calc(100% - 6px);
    width: 34px;
    margin: 3px;
    background-color: #bbb;
    border-radius: 4px;
    content: '';
    position: absolute;
    transition: ease-in-out 0.2s;
    left: 0px;
    top: 0px;

    &:before {
        opacity: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 32px;
        color: #fff;
        font-weight: bold;
        position: absolute;
    }
`;

const StyledInput = styled.input`
    opacity: 0;
    &:checked + ${Tick} {
        background-color: #00a862;
        left: calc(100% - 40px);

        &:before {
            opacity: 1;
        }
    }
`;

interface OwnProps {
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

export const SwitchButton: React.FunctionComponent<OwnProps> = ({className, checked, onChange}) => {
    return (
        <SwitchButtonLabel htmlFor="checkbox" className={className}>
            <StyledInput checked={checked} onChange={onChange} type="checkbox" id="checkbox" />
            <Tick className="ion-ios-checkmark" />
        </SwitchButtonLabel>
    );
};
