import styled from 'styled-components';

export const Token = styled.div`
    position: absolute;
    z-index: 15;
    top: 30%;
    text-align: center;
    color: white;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    font-size: 3em;
    background-color: #174d5b;
`;
