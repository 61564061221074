import {BehaviorSubject, Observable} from 'rxjs';
import {Participant} from '../models/participant';
import {CreateParticipantRepository} from '../ui/boats_overview_screen/boats_grid/participants_block/add_participant_form/internal/create_participant_interactor';
import {UpdateParticipantRepository} from '../ui/boats_overview_screen/boats_grid/internal/participant_to_boat_adder';
import {Option} from '../support/monads/option';
import {ParticipantsRepository as LocalStorageParticipantsRepository} from '../business/participants/local_storage_participant_provider';
import {ParticipantsRepository as SocketParticipantsRepository} from '../business/participants/socket_participant_provider';
import {ParticipantsRepository as ApiParticipantsRepository} from '../business/participants/api_participant_provider';

export interface ParticipantsRepository
    extends LocalStorageParticipantsRepository,
        SocketParticipantsRepository,
        ApiParticipantsRepository,
        CreateParticipantRepository,
        UpdateParticipantRepository {}

export class DefaultParticipantsRepository implements ParticipantsRepository {
    private subject = new BehaviorSubject<Participant[]>([]);

    public stream(): Observable<Participant[]> {
        return this.subject;
    }

    public get(): Participant[] {
        return this.subject.value;
    }

    public replace(participants: Participant[]): void {
        this.subject.next(participants);
    }

    public createOrUpdate(participant: Participant): void {
        this.subject.next([...this.subject.value.filter((p) => p.id !== participant.id), participant]);
    }

    public async byId(id: string): Promise<Option<Participant>> {
        const participant = this.subject.value.find((participant) => participant.id === id);

        return participant !== undefined ? Option.some(participant) : Option.none();
    }
}
