import {Try} from '../../../../support/monads/try';
import {Boat} from '../../../../models/boat';
import {CreateBoatInteractor} from '../boats_grid_presenter';

export interface CreateBoatApi {
    create(raceId: string, name: string): Promise<Try<Boat>>;
}

export interface CreateBoatRepository {
    createOrUpdate(boat: Boat): void;
}

export interface NextBoatNameProvider {
    get(): Promise<string>;
}

export class ApiCreateBoatInteractor implements CreateBoatInteractor {
    constructor(
        private raceId: string,
        private createBoatApi: CreateBoatApi,
        private createBoatRepository: CreateBoatRepository,
        private nextBoatNameProvider: NextBoatNameProvider,
    ) {}

    public async create(): Promise<Try<Boat>> {
        try {
            const name = await this.nextBoatNameProvider.get();
            const boatTry = await this.createBoatApi.create(this.raceId, name);
            boatTry.map((boat) => {
                this.createBoatRepository.createOrUpdate(boat);
            });

            return boatTry;
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
