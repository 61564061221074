import * as React from 'react';
import styled, {keyframes} from 'styled-components';
import {StyledTooltip} from '../../../../components/tooltip';
import {AlertSquare} from '../../../../components/alert_square';

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    margin: 4px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    border-left: 4px solid #ffffff;
    transform: translateZ(0);
    animation: ${rotate360} 1.5s linear infinite;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    &:after {
        border-radius: 50%;
        width: 24px;
        height: 24px;
    }
`;

export const WheelSpinIndicator: React.FunctionComponent = () => {
    return (
        <StyledTooltip label={'Wheel spinning'}>
            <AlertSquare style={{backgroundColor: 'rgb(255,170,85)'}}>
                <Spinner />
            </AlertSquare>
        </StyledTooltip>
    );
};
