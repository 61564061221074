import * as React from 'react';
import {makeAutoObservable} from 'mobx';
import {ClientComponent, component} from '../client_component';

export interface Presenter {
    mount(): any;

    unmount(): any;
}

export interface PresenterProps<P extends Presenter> {
    presenter: P;
}

interface OptionalPresenter<P extends Presenter> {
    presenter?: P;

    [key: string]: any;

    [key: number]: any;
}

export function withPresenter<TPresenter extends Presenter, TOriginalProps extends OptionalPresenter<TPresenter> = {}>(
    presenter: (props: TOriginalProps, component: ClientComponent) => TPresenter,
    UndecoratedComponent: React.ComponentType<TOriginalProps & PresenterProps<TPresenter>>,
) {
    return class WithPresenterComponent extends React.Component<TOriginalProps & OptionalPresenter<TPresenter>, {}> {
        // Define how your HOC is shown in ReactDevTools
        public static displayName = `withPresenter(${
            UndecoratedComponent.displayName || (UndecoratedComponent as any).name
        })`;
        private _presenter?: TPresenter;

        public componentDidMount() {
            this.getPresenter().mount();
        }

        public componentWillUnmount() {
            this.getPresenter().unmount();
        }

        private getPresenter(): TPresenter {
            //Allow force setting the presenter through a prop, handy when testing a single component with a mocked presenter
            if (this.props.presenter !== undefined) {
                return this.props.presenter as TPresenter;
            }
            //Make sure the presenter is only initialized once
            if (this._presenter === undefined) {
                this._presenter = presenter(this.props, component());
                makeAutoObservable(this._presenter);
            }
            return this._presenter;
        }

        public render() {
            const decoratedProps = (Object as any).assign({}, this.props, {presenter: this.getPresenter()});

            return React.createElement<TOriginalProps & PresenterProps<TPresenter>>(
                UndecoratedComponent,
                decoratedProps,
            );
        }
    };
}
