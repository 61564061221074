import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    margin: auto auto 1.75rem auto;
    background-color: #ffffff;
    padding: 0.5rem;
    color: #5f5f5f;
`;
