import {ServerTimeInteractor} from '../server_time_interactor';
import {animationFrameScheduler, interval, Observable} from 'rxjs';
import {TimeProvider} from './time_provider';
import {map, withLatestFrom} from 'rxjs/operators';

export interface ServerTimeProvider {
    getTime(): Observable<number>;
}

export class ServerTimeProviderImpl implements ServerTimeProvider {
    constructor(private serverTimeInteractor: ServerTimeInteractor, private timeProvider: TimeProvider) {}

    public getTime(): Observable<number> {
        return interval(0, animationFrameScheduler).pipe(
            withLatestFrom(this.serverTimeInteractor.serverTimes()),
            map(([_, serverTime]) => {
                return this.timeProvider.getTime() - serverTime.offsetMillis;
            }),
        );
    }
}
