export function formatSpeedMps(speedMps: number | null): string {
    if (speedMps === null) {
        return 'N/A';
    }
    return String(Math.round(10 * speedMps * 3.6) / 10);
}

export function formatSpeedMpsWithUnit(speedMps: number): string {
    return formatSpeedMps(speedMps) + ' km/h';
}
