import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';

const InputElement = styled.input`
    width: 100%;
    height: 81px;
    margin: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(25, 63, 73, 0.3);
    border: 0;
    text-align: center;
    font-size: 50px;
    color: #ffffff;
`;

const Label = styled.label`
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    position: relative;
    padding-left: 10px;
    text-transform: uppercase;
`;

interface OwnProps {
    label?: string;
}

export const FancyInput: FunctionComponent<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement> & OwnProps, HTMLInputElement>
> = (props) => {
    const {label} = props;
    return (
        <>
            {label && <Label>{label}</Label>}
            <InputElement {...(props as any)} />
        </>
    );
};
