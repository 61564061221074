export function formatDistanceMeters(distanceMeters: number, seperator = ' '): string {
    return Math.round(distanceMeters * 10) / 10 + seperator + 'm';
}

export function formatDistanceKiloMeters(distanceMeters: number, seperator = ' '): string {
    return Math.round(distanceMeters * 1000) / 1000 + seperator + 'k';
}

export function formatDistance(distanceMeters: number, seperator = ''): string {
    if (distanceMeters < 1000) {
        return formatDistanceMeters(distanceMeters, seperator);
    }
    return formatDistanceKiloMeters(distanceMeters, seperator);
}
