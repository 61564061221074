import {ParticipantForRaceSocket} from '../business/participants/socket_participant_provider';
import {fromEvent, Observable} from 'rxjs';
import {Participant} from '../models/participant';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';

export interface ParticipantSocket extends ParticipantForRaceSocket {}

export class DefaultParticipantSocket implements ParticipantSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<Participant> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/participant`);
    }
}
