import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 4.76rem 0.88rem 0;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    min-height: 100%;
`;
