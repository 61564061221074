import {DefaultRaceApi, RaceApi} from './race_api';
import {lazy} from '../support/lazy';
import {HttpLinkProvider} from './driver/http_link_provider';
import {DefaultParticipantApi, ParticipantApi} from './participant_api';
import {BoatsApi, DefaultBoatsApi} from './boats_api';
import {DefaultDeviceApi, DeviceApi} from './device_api';
import {DefaultParticipantSocket, ParticipantSocket} from './participant_socket';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {DefaultDeviceSocket, DeviceSocket} from './device_socket';
import {DefaultRaceSocket, RaceSocket} from './race_socket';
import {BoatsProgressSocket, DefaultBoatsProgressSocket} from './boat_socket';
import {DefaultStartResultSocket, StartResultSocket} from './start_result_socket';
import {DefaultTimeApi, TimeApi} from './time_api';
import {BoatSocket, DefaultBoatSocket} from './boats_socket';
import {DefaultToggleTokenSocket, ToggleTokenSocket} from './toggle_token_socket';
import {SplitTimeSocket} from '../ui/boats_screen/overlays/internal/overlays/internal/split_time_provider';
import {DefaultSplitTimeSocket} from './split_time_socket';

export interface NetworkComponent {
    raceApi: RaceApi;
    raceSocket: RaceSocket;
    participantApi: ParticipantApi;
    participantSocket: ParticipantSocket;
    deviceApi: DeviceApi;
    deviceSocket: DeviceSocket;
    boatsApi: BoatsApi;
    boatSocket: BoatSocket;
    timeApi: TimeApi;
    startResultSocket: StartResultSocket;
    boatsProgressSocket: BoatsProgressSocket;
    toggleTokenSocket: ToggleTokenSocket;
    splitTimeSocket: SplitTimeSocket;
}

export class DefaultNetworkComponent implements NetworkComponent {
    constructor(private endpoint: string) {}

    @lazy()
    private get httpLinkProvider(): HttpLinkProvider {
        return new HttpLinkProvider(this.endpoint);
    }

    @lazy()
    private get socketIoClientProvider(): SocketIoClientProvider {
        return new SocketIoClientProvider();
    }

    @lazy()
    public get raceApi(): RaceApi {
        return new DefaultRaceApi(this.httpLinkProvider);
    }

    @lazy()
    public get raceSocket(): RaceSocket {
        return new DefaultRaceSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get participantApi(): ParticipantApi {
        return new DefaultParticipantApi(this.httpLinkProvider);
    }

    @lazy()
    public get participantSocket(): ParticipantSocket {
        return new DefaultParticipantSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get deviceApi(): DeviceApi {
        return new DefaultDeviceApi(this.httpLinkProvider);
    }

    @lazy()
    public get deviceSocket(): DeviceSocket {
        return new DefaultDeviceSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get boatsApi(): BoatsApi {
        return new DefaultBoatsApi(this.httpLinkProvider);
    }

    @lazy()
    public get boatSocket(): BoatSocket {
        return new DefaultBoatSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get timeApi(): TimeApi {
        return new DefaultTimeApi(this.httpLinkProvider);
    }

    @lazy()
    public get startResultSocket(): StartResultSocket {
        return new DefaultStartResultSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get boatsProgressSocket(): BoatsProgressSocket {
        return new DefaultBoatsProgressSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get toggleTokenSocket(): ToggleTokenSocket {
        return new DefaultToggleTokenSocket(this.socketIoClientProvider);
    }

    @lazy()
    public get splitTimeSocket(): SplitTimeSocket {
        return new DefaultSplitTimeSocket(this.socketIoClientProvider);
    }
}
