export enum BoatType {
    ONE_X = 'ONE_X',
    TWO_CD = 'TWO_CD',
    TWO_CS = 'TWO_CS',
    TWO_X = 'TWO_X',
    FOUR_CD = 'FOUR_CD',
    FOUR_CS = 'FOUR_CS',
    FOUR_X = 'FOUR_X',
    EIGHT_CD = 'EIGHT_CD',
}

export const boatTypeToString = (boatType: BoatType): string => {
    switch (boatType) {
        case BoatType.ONE_X:
            return '1x';
        case BoatType.TWO_CD:
            return '2+';
        case BoatType.TWO_CS:
            return '2-';
        case BoatType.TWO_X:
            return '2x-';
        case BoatType.FOUR_CD:
            return '4+';
        case BoatType.FOUR_CS:
            return '4-';
        case BoatType.FOUR_X:
            return '4x-';
        case BoatType.EIGHT_CD:
            return '8+';
    }
};
