import {Presenter} from '../../../support/with_presenter';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {runInAction} from 'mobx';
import {
    BoatProgressWithCombinations,
    BoatProgressWithCombinationsProvider,
} from '../../../business/boats/boats_with_progress_and_combinations_provider';
import {debounceThrottleAnimationFrame} from '../../../support/rx_debounce_throttle';

export class DeviceStatesPresenter implements Presenter {
    public boatsWithCombinations: BoatProgressWithCombinations[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private boatProgressWithCombinatiosProvider: BoatProgressWithCombinationsProvider) {}

    public mount(): void {
        this.subscriptions.add(
            debounceThrottleAnimationFrame(this.boatProgressWithCombinatiosProvider.get(), 250)
                .pipe()
                .subscribe((combinationsTry) => {
                    combinationsTry.map((combinations) => {
                        runInAction(() => {
                            this.boatsWithCombinations = combinations;
                        });
                    });
                }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
