import {HttpLinkProvider} from './driver/http_link_provider';
import {Try} from '../support/monads/try';
import {Boat} from '../models/boat';
import {execute, ExecutionResult, GraphQLRequest, makePromise} from 'apollo-link';
import gql from 'graphql-tag';
import {CreateBoatApi} from '../ui/boats_overview_screen/boats_grid/internal/create_boat_interactor';
import {BoatsForRaceApi} from '../business/boats/api_boats_provider';
import {DeleteBoatApi} from '../ui/boats_overview_screen/boats_grid/boat_block/internal/boat_deleter';
import {UpdateBoatApi} from '../ui/boats_overview_screen/boat_edit_modal/internal/boat_updater';

export interface BoatsApi extends BoatsForRaceApi, CreateBoatApi, DeleteBoatApi, UpdateBoatApi {}

export class DefaultBoatsApi implements BoatsApi {
    constructor(private httpLinkProvider: HttpLinkProvider) {}

    public async getForRaceId(raceId: string): Promise<Try<Boat[]>> {
        const operation: GraphQLRequest = {
            query: gql`
                query($raceId: String!) {
                    boatsByRaceId(raceId: $raceId) {
                        id
                        raceId
                        name
                        type
                        preliminaryFinishTimeMillis
                        definitiveFinishTimeMillis
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.boatsByRaceId);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async create(raceId: string, name: string): Promise<Try<Boat>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!, $name: String!) {
                    createBoat(raceId: $raceId, name: $name) {
                        id
                        raceId
                        name
                        type
                        preliminaryFinishTimeMillis
                        definitiveFinishTimeMillis
                    }
                }
            `,
            variables: {
                raceId,
                name,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.createBoat);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async update(boat: Boat): Promise<Try<Boat>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($id: String!, $name: String!, $type: BoatType) {
                    updateBoat(id: $id, name: $name, type: $type) {
                        id
                        raceId
                        name
                        type
                        preliminaryFinishTimeMillis
                        definitiveFinishTimeMillis
                    }
                }
            `,
            variables: {
                id: boat.id,
                name: boat.name,
                type: boat.type,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.createBoat);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async delete(boatId: string): Promise<Try<Boat>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($id: String!) {
                    deleteBoat(id: $id) {
                        id
                        raceId
                        name
                        type
                        preliminaryFinishTimeMillis
                        definitiveFinishTimeMillis
                    }
                }
            `,
            variables: {
                id: boatId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.deleteBoat);
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
