import {easeCubicOut, easeExpOut} from 'd3-ease';
import {BoatsProgressProvider, SocketBoatsProgressProvider} from '../../business/boats/boats_progress_provider';
import {DefaultBoatsWithCombinationsProvider} from '../../business/boats/boats_with_combinations_provider';
import {DefaultLanesProvider, LanesProvider} from './internal/lanes_provider';
import {lazy} from '../../support/lazy';
import {Race} from '../../models/race';
import {ClientComponent} from '../../client_component';
import {DefaultStartTimeProvider, StartTimeProvider} from '../../business/race/start_time_provider';
import {SplitTimesProvider} from './overlays/internal/overlays/split_times_overlay_strategy';
import {DefaultSplitTimeProvider} from './overlays/internal/overlays/internal/split_time_provider';
import {CollectedSplitTimesProvider} from './overlays/overlays/split_times/split_times_presenter';
import {DefaultCollectedSplitTimesProvider} from './internal/collected_split_times_provider';
import {BoatsProvider} from '../../business/boats/boats_provider';
import {RealtimeBoatsScreenStateProvider} from './boats_screen_presenter';
import {DefaultCameraPositionCalculator} from './internal/calculators/camera_position_calculator';
import {DefaultCanvasSizesCalculator} from './internal/calculators/canvas_sizes_calculator';
import {ViewPortSizeProvider} from './support/viewport_sizes';
import {DefaultAnimatedCameraPositionCalculator} from './internal/calculators/animated_camera_position_calculator';
import {StartLineOffsetCalculator} from './internal/calculators/offset_calculators/start_line_offset_calculator';
import {MovingOffsetCalculator} from './internal/calculators/offset_calculators/moving_offset_calculator';
import {FinishLineOffsetCalculator} from './internal/calculators/offset_calculators/finish_line_offset_calculator';
import {
    DefaultAnimatedValueCalculator,
    UpdateIntervalAnimatedValueCalculator,
} from './internal/calculators/animated_value_calculator';
import {DefaultRealtimeBoatPositionsCalculator} from './internal/calculators/realtime_boat_positions_calculator';
import {DefaultAnimatedCanvasSizesCalculator} from './internal/calculators/animated_canvas_sizes_calculator';
import {DefaultRealtimeBoatsScreenStateProvider} from './internal/boats_screen_state_provider';
import {DefaultAnimatedBoatsProgressCalculator} from './internal/calculators/animated_boats_progress_calculator';

export interface BoatsScreenComponent {
    boatsProvider: BoatsProvider;
    boatsProgressProvider: BoatsProgressProvider;
    lanesProvider: LanesProvider;
    startTimeProvider: StartTimeProvider;
    splitTimesProvider: SplitTimesProvider;
    collectedSplitTimesProvider: CollectedSplitTimesProvider;
    realtimeBoatsScreenStateProvider: RealtimeBoatsScreenStateProvider;
}

export class DefaultBoatsScreenComponent implements BoatsScreenComponent {
    constructor(
        private initialRace: Race,
        private viewPortSizeProvider: ViewPortSizeProvider,
        private component: ClientComponent,
    ) {}

    @lazy()
    public get boatsProvider(): BoatsProvider {
        return this.component.boatsProviderFactory.create(this.initialRace);
    }

    @lazy()
    private get boatsWithCombinationsProvider() {
        return new DefaultBoatsWithCombinationsProvider(
            this.boatsProvider,
            this.component.participantsProviderFactory.create(this.initialRace),
            this.component.devicesProviderFactory.create(this.initialRace),
        );
    }

    @lazy()
    public get boatsProgressProvider(): BoatsProgressProvider {
        return new SocketBoatsProgressProvider(
            this.initialRace.id,
            this.startTimeProvider,
            this.boatsWithCombinationsProvider,
            this.component.networkComponent.boatsProgressSocket,
        );
    }

    @lazy()
    public get lanesProvider(): LanesProvider {
        return new DefaultLanesProvider(this.boatsProgressProvider, 1);
    }

    @lazy()
    public get startTimeProvider(): StartTimeProvider {
        return new DefaultStartTimeProvider(
            this.initialRace,
            this.component.raceProviderFactory.create(this.initialRace),
        );
    }

    @lazy()
    public get splitTimesProvider(): SplitTimesProvider {
        return new DefaultSplitTimeProvider(this.initialRace.id, this.component.networkComponent.splitTimeSocket);
    }

    @lazy()
    public get collectedSplitTimesProvider(): CollectedSplitTimesProvider {
        return new DefaultCollectedSplitTimesProvider(
            this.initialRace.id,
            this.component.networkComponent.splitTimeSocket,
        );
    }

    @lazy()
    public get realtimeBoatsScreenStateProvider(): RealtimeBoatsScreenStateProvider {
        return new DefaultRealtimeBoatsScreenStateProvider(
            this.boatsWithCombinationsProvider,
            this.viewPortSizeProvider,
            this.boatsProgressProvider,
            this.lanesProvider,
            new DefaultCanvasSizesCalculator(this.viewPortSizeProvider),
            new DefaultAnimatedCanvasSizesCalculator(new DefaultAnimatedValueCalculator(5000)),
            new DefaultAnimatedBoatsProgressCalculator({
                create: () => new UpdateIntervalAnimatedValueCalculator(500, 1500, easeExpOut),
            }),
            new DefaultRealtimeBoatPositionsCalculator(),
            new DefaultCameraPositionCalculator(this.initialRace, [
                new StartLineOffsetCalculator(),
                new MovingOffsetCalculator(),
                new FinishLineOffsetCalculator(),
            ]),
            new DefaultAnimatedCameraPositionCalculator(new DefaultAnimatedValueCalculator(750, easeCubicOut)),
        );
    }
}
