import {Race} from './race';
import {Boat} from './boat';
import {Participant} from './participant';

export enum StartState {
    /* A race start is requested, might still be waiting */
    INITIALIZED = 'initialized',
    /* We're continuing to start */
    COMMENCING = 'commencing',
    /* Someone hasn't sent out their ack signals */
    TIMEOUT = 'timeout',
    /* We have started */
    SUCCESS = 'success',
    /* Aborted manually */
    ABORTED = 'aborted',
}

export type StartResult =
    | RaceStartInitialized
    | RaceStartCommencing
    | RaceStartSuccess
    | RaceStartAborted
    | RaceStartTimeout;

export interface RaceStartInitialized {
    state: StartState.INITIALIZED;
    raceId: string;
}

export interface RaceStartCommencing {
    state: StartState.COMMENCING;
    startTimeMillis: number;
    raceId: string;
    race: Race;
    boats: Boat[];
    participants: Participant[];
}

export interface RaceStartSuccess {
    state: StartState.SUCCESS;
    raceId: string;
}

export interface RaceStartAborted {
    state: StartState.ABORTED;
    raceId: string;
}

export interface RaceStartTimeout {
    state: StartState.TIMEOUT;
    raceId: string;
    acknowledgedDeviceIds: string[];
    erroredDeviceIds: string[];
}
