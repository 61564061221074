import * as React from 'react';
import {Group, Rect} from 'react-konva';
import {Label} from './label';

interface OwnProps {
    y: number;
    viewportWidth: number;
    blockSizePx: number;
}

interface MemoizableOwnProps {
    viewportWidth: number;
    blockSizePx: number;
}

const MemoizableFinishLine: React.FunctionComponent<MemoizableOwnProps> = React.memo(({blockSizePx, viewportWidth}) => {
    const amountFitInWidth = Math.floor(viewportWidth / blockSizePx);
    const blockWidth = (viewportWidth - amountFitInWidth * blockSizePx) / amountFitInWidth + blockSizePx;

    const blockXPositions = Array.from({length: amountFitInWidth}, (_, i) => i * blockWidth);
    return (
        <>
            <Group x={(viewportWidth - 270) / 2} y={100}>
                <Rect x={0} y={0} fill="#FFFFFF" width={270} height={56} cornerRadius={5} />
                <Label text="FINISH" fill="#000000" />
            </Group>
            {blockXPositions.map((xPos, i) => {
                const yPos = i % 2 === 0 ? -blockWidth : -blockWidth * 2;
                return (
                    <Rect
                        key={xPos + '_' + yPos}
                        x={xPos}
                        y={yPos}
                        width={blockWidth}
                        height={blockWidth}
                        fill="#fff"
                    />
                );
            })}
        </>
    );
});

export const FinishLine: React.FunctionComponent<OwnProps> = React.memo(({y, blockSizePx, viewportWidth}) => {
    return (
        <Group x={0} y={y}>
            <MemoizableFinishLine viewportWidth={viewportWidth} blockSizePx={blockSizePx} />
        </Group>
    );
});
