import * as React from 'react';
import {useMediaQuery} from 'react-responsive';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {NavigateFn} from '@reach/router';

import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {SelectDistanceScreenPresenter} from './select_distance_screen_presenter';
import {Race} from '../../models/race';
import {SelectDistanceForm} from './select_distance_form/select_distance_form';
import {LobbyHeader} from '../components/lobby_header';
import {MobileSelectDistanceForm} from './select_distance_form_mobile/select_distance_form';
import {Wrapper} from '../components/wrapper';
import {Nav} from '../components/nav';

interface OwnProps {
    race: Race;
    navigate?: NavigateFn;
}

const SelectDistanceScreenSplit: FunctionComponent<OwnProps & PresenterProps<SelectDistanceScreenPresenter>> = observer(
    ({presenter, navigate}) => {
        const isBigScreen = useMediaQuery({minWidth: 992});

        if (!navigate) {
            return null;
        }

        return isBigScreen ? (
            <>
                <LobbyHeader race={presenter.race} />
                <SelectDistanceForm race={presenter.race} navigate={navigate} />
            </>
        ) : (
            <Wrapper>
                <Nav race={presenter.race} />
                <MobileSelectDistanceForm race={presenter.race} navigate={navigate} />
            </Wrapper>
        );
    },
);

export const SelectDistanceScreen = withPresenter<SelectDistanceScreenPresenter, OwnProps>(
    (props, component) =>
        new SelectDistanceScreenPresenter(props.race, component.raceProviderFactory.create(props.race)),
    SelectDistanceScreenSplit,
);
