import {Observable} from 'rxjs';

import {ServerTime, ServerTimeInteractor} from '../server_time_interactor';
import {shareReplay} from 'rxjs/operators';

export class SharedServerTimeInteractor implements ServerTimeInteractor {
    private _observable?: Observable<ServerTime>;

    constructor(private serverTimeInteractor: ServerTimeInteractor) {}

    public serverTimes(): Observable<ServerTime> {
        if (this._observable === undefined) {
            this._observable = this.serverTimeInteractor.serverTimes().pipe(shareReplay(1));
        }
        return this._observable;
    }
}
