import {Presenter} from '../../../../../support/with_presenter';
import {BoatsProgressProvider} from '../../../../../business/boats/boats_progress_provider';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {debounceThrottleAnimationFrame} from '../../../../../support/rx_debounce_throttle';
import {runInAction} from 'mobx';

export interface StrokeRateViewModel {
    boatName: string;
    strokeRate: number | null;
}

export class StrokeRatesPresenter implements Presenter {
    public strokeRates: StrokeRateViewModel[] = [];

    private subscription: Subscription | undefined;

    constructor(private boatsProgressProvider: BoatsProgressProvider) {}

    public mount(): void {
        this.subscription = debounceThrottleAnimationFrame(this.boatsProgressProvider.get(), 1000)
            .pipe(
                map((boatProgresses) =>
                    boatProgresses.map((boatProgress) => {
                        return {
                            boatName: boatProgress.name,
                            strokeRate: boatProgress.strokeRate,
                        };
                    }),
                ),
            )
            .subscribe((strokeRates) => {
                runInAction(() => {
                    this.strokeRates = strokeRates;
                });
            });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
