import * as React from 'react';
import {FunctionComponent} from 'react';
import {Text} from 'react-konva';

export const Label: FunctionComponent<{text: string; fill?: string}> = ({text, fill}) => {
    return (
        <Text
            text={text}
            verticalAlign="middle"
            wrap="none"
            align={'center'}
            fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
            fontSize={22}
            ellipsis={true}
            lineHeight={36}
            fontStyle="bold"
            fill={fill ? fill : 'white'}
            x={0}
            y={2}
            width={270}
            height={56}
        />
    );
};
