import {BehaviorSubject, Observable} from 'rxjs';
import {Race} from '../models/race';
import {UpdateRaceRepository} from '../ui/select_distance_screen/select_distance_form/internal/race_distance_updater';
import {RaceRepository as RaceProviderRaceRepository} from '../business/race/race_provider';

export interface RaceRepository extends RaceProviderRaceRepository, UpdateRaceRepository {}

export class DefaultRaceRepository implements RaceRepository {
    private subject = new BehaviorSubject(this.initialRace);

    constructor(private initialRace: Race) {}

    public get(): Observable<Race> {
        return this.subject;
    }

    public update(race: Race): void {
        this.subject.next(race);
    }
}
