import {AnimatedCameraOffsetCalculator} from '../boats_screen_state_provider';
import {AnimatedValueCalculator} from './animated_value_calculator';

export class DefaultAnimatedCameraPositionCalculator implements AnimatedCameraOffsetCalculator {
    constructor(private animatedPositionCalculator: AnimatedValueCalculator) {}

    public calculate(offset: number, timestamp: number): number {
        this.animatedPositionCalculator.update(offset, timestamp);

        return this.animatedPositionCalculator.get(timestamp);
    }
}
