import {ParticipantsProvider} from './participant_provider';
import {Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Participant} from '../../models/participant';
import {map, tap, shareReplay} from 'rxjs/operators';

export interface ParticipantsRepository {
    get(): Participant[];

    replace(participants: Participant[]): void;
}

export interface ParticipantForRaceSocket {
    getForRaceId(raceId: string): Observable<Participant>;
}

export class SocketParticipantProvider implements ParticipantsProvider {
    protected observable = this.participantForRaceSocket.getForRaceId(this.raceId).pipe(
        map((participant) => {
            const participants = this.participantsRepository.get();
            return [...participants.filter((p) => p.id !== participant.id), participant];
        }),
        tap((participants) => this.participantsRepository.replace(participants)),
        map((participants) => Try.just(participants)),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private participantForRaceSocket: ParticipantForRaceSocket,
        private participantsRepository: ParticipantsRepository,
    ) {}

    public get(): Observable<Try<Participant[]>> {
        return this.observable;
    }
}
