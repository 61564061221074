import {BoatWithCombinations} from '../../../../../business/boats/boats_with_combinations_provider';
import {Race} from '../../../../../models/race';
import {Try} from '../../../../../support/monads/try';
import {CanvasSizes, RealtimeBoatProgress} from '../../../boats_screen_presenter';
import {getBiggestBoatHeightPx} from '../../../support/image_data';
import {OffsetCalculator} from '../camera_position_calculator';

export class StartLineOffsetCalculator implements OffsetCalculator {
    /**
     * Calculate our "startOffset", basically offset 0 - the length of the longest boat,
     * this so that all the boats are visible at the start
     */
    public calculate(
        previousCalculatorOffset: number,
        race: Race,
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number {
        const combinationsParticipantCounts = boatsWithCombinationsTry
            .getOrElse(() => [])
            .map((boat) => boat.combinations.length);
        const realtimeParticipantCounts = realtimeBoatProgresses.map((b) => b.boat.participantCount);
        const biggestBoatInPx = getBiggestBoatHeightPx([
            ...combinationsParticipantCounts,
            ...realtimeParticipantCounts,
        ]);

        return -(biggestBoatInPx * canvasSizes.imageSizeMultiplier);
    }
}
