import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {BoatsOverviewScreenPresenter} from './boats_overview_screen_presenter';
import {RouteComponentProps} from '@reach/router';
import {Race} from '../../models/race';
import {LobbyHeader} from '../components/lobby_header';
import {BoatsGrid} from './boats_grid/boats_grid';
import {useMediaQuery} from 'react-responsive';
import {Wrapper} from '../components/wrapper';
import {Nav} from '../components/nav';
import {BoatsScreen} from './mobile_boats_grid/boats_screen';

interface OwnProps {
    race: Race;
}

const BoatsOverviewScreenComponent: FunctionComponent<
    OwnProps & RouteComponentProps & PresenterProps<BoatsOverviewScreenPresenter>
> = observer(({presenter}) => {
    const isBigScreen = useMediaQuery({minWidth: 992});

    return isBigScreen ? (
        <>
            <LobbyHeader race={presenter.race} />
            <BoatsGrid race={presenter.race} />
        </>
    ) : (
        <Wrapper>
            <Nav race={presenter.race} />
            <BoatsScreen race={presenter.race} />
        </Wrapper>
    );
});

export const BoatsOverviewScreen = withPresenter<BoatsOverviewScreenPresenter, OwnProps>(
    (props, component) =>
        new BoatsOverviewScreenPresenter(props.race, component.raceProviderFactory.create(props.race)),
    BoatsOverviewScreenComponent,
);
