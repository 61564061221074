import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Row} from '../../../../components/row';
import {Col} from '../../../../components/col';

interface OwnProps {
    title: string | React.ComponentElement<any, any>;
    icon?: string;
    fixedWidth?: boolean;
}

interface WrapperProps {
    fixedWidth?: boolean;
}

const Wrapper = styled.div`
    position: relative;
    width: ${(props: WrapperProps) => (props.fixedWidth === true ? '335px' : 'auto')};
    display: ${(props: WrapperProps) => (props.fixedWidth === true ? 'flex' : 'block')};
    min-height: 230px;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding: 0 15px;
    margin: 15px;
    overflow: hidden;
`;

interface TitleProps {
    hasIcon?: boolean;
}

const Title = styled.div`
    padding: 10px 0;
    border-bottom: 2px solid #fafafa;
    margin: 0 -15px;
    vertical-align: baseline;
    h3 {
        margin: 0 18px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        color: #5f5f5f;
        position: relative;
        padding-left: ${(props: TitleProps) => (props.hasIcon === true ? '30px' : '0px')};
        text-transform: uppercase;
        span {
            left: 0;
            position: absolute;
            font-size: 24px;
            width: 24px;
            height: 15px;
            color: #5f5f5f;
        }
        button {
            float: right;
            background: none;
            border: 0;
        }
    }
`;

const Content = styled.div`
    padding: 10px 0;
`;

export const Block: FunctionComponent<OwnProps> = ({title, icon, fixedWidth, children}) => {
    return (
        <Wrapper fixedWidth={fixedWidth} className="d-flex flex-column">
            <Row>
                <Col numCols={12}>
                    <Title hasIcon={icon !== undefined}>
                        <h3>
                            {icon ? <span className={icon} /> : null}
                            {title}
                        </h3>
                    </Title>
                </Col>
            </Row>
            <Row className="mh-100 overflow-auto">
                <Col numCols={12}>
                    <Content>{children}</Content>
                </Col>
            </Row>
        </Wrapper>
    );
};
