import * as React from 'react';
import {FunctionComponent} from 'react';
import {Circle} from './circle';
import {BlockItem} from '../../components/block_item';
import {CombinationWrapper} from './combination_wrapper';
import {Combination} from '../boat_block_presenter';
import {
    DraggableType,
    DROPPABLE_BOAT_PREFIX,
    DROPPABLE_PARTICIPANT_PREFIX,
    toDroppablePrefix,
} from '../../boats_grid_drag_handler_presenter';
import {Droppable} from 'react-beautiful-dnd';
import {Boat} from '../../../../../models/boat';
import {DraggableDeviceBlockItem} from '../../components/draggable_device_block';
import {DraggableParticipantBlock} from '../../components/draggable_participant_block';
import {Race} from '../../../../../models/race';

interface OwnProps {
    index: number;
    combination: Combination;
    boat: Boat;
    race: Race;
}

export const CombinationRow: FunctionComponent<OwnProps> = ({index, combination, boat, race}) => {
    return (
        <CombinationWrapper>
            <Circle>{index}</Circle>
            <Droppable
                isDropDisabled={true}
                droppableId={toDroppablePrefix(DROPPABLE_BOAT_PREFIX, index) + boat.id}
                type={DraggableType.Participant}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <DraggableParticipantBlock index={index} participant={combination.participant} race={race} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Droppable
                isDropDisabled={combination.device !== null}
                droppableId={toDroppablePrefix(DROPPABLE_PARTICIPANT_PREFIX, index) + combination.participant.id}
                type={DraggableType.Device}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {combination.device === null ? (
                            <BlockItem inactive={!snapshot.isDraggingOver}>Device</BlockItem>
                        ) : (
                            <DraggableDeviceBlockItem
                                dark
                                key={combination.device.id}
                                index={index}
                                id={combination.device.id}
                                type={DraggableType.Device}
                                device={combination.device}
                                race={race}
                            />
                        )}
                        <div style={{display: 'none'}}>{provided.placeholder}</div>
                    </div>
                )}
            </Droppable>
        </CombinationWrapper>
    );
};
