import * as React from 'react';
import {FunctionComponent} from 'react';
import {Group, Image as ReactKonvaImage} from 'react-konva';
import {boatImagesData as images} from '../support/image_data';

interface OwnProps {
    offscreenCanvas: HTMLCanvasElement | null;
    x: number;
    y: number;
    participantCount: number;
    imageSizeMultiplier: number;
}

export const Boat: FunctionComponent<OwnProps> = React.memo(
    ({offscreenCanvas, x, y, participantCount, imageSizeMultiplier: size}) => {
        if (offscreenCanvas === null) {
            return null;
        }

        return (
            <Group x={x} y={y}>
                <ReactKonvaImage
                    perfectDrawEnabled={false}
                    image={offscreenCanvas as any}
                    width={images.front.width * size}
                    height={images.front.height * size}
                    crop={{
                        x: images.front.x * size,
                        y: images.front.y * size,
                        width: images.front.width * size,
                        height: images.front.height * size,
                    }}
                />
                {Array.from({length: participantCount}).map((_, index) => {
                    return (
                        <ReactKonvaImage
                            perfectDrawEnabled={false}
                            y={images.front.height * size + images.rower.height * size * index}
                            key={index}
                            image={offscreenCanvas as any}
                            width={images.rower.width * size}
                            height={images.rower.height * size}
                            crop={{
                                x: images.rower.x * size,
                                y: images.rower.y * size,
                                width: images.rower.width * size,
                                height: images.rower.height * size,
                            }}
                        />
                    );
                })}
                <ReactKonvaImage
                    perfectDrawEnabled={false}
                    y={images.front.height * size + images.rower.height * size * participantCount}
                    image={offscreenCanvas as any}
                    width={images.rear.width * size}
                    height={images.rear.height * size}
                    crop={{
                        x: images.rear.x * size,
                        y: images.rear.y * size,
                        width: images.rear.width * size,
                        height: images.rear.height * size,
                    }}
                />
            </Group>
        );
    },
);
