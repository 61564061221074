import {merge, Observable} from 'rxjs';
import {Participant} from '../../models/participant';
import {Try} from '../../support/monads/try';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';

export interface ParticipantsProvider {
    get(): Observable<Try<Participant[]>>;
}

export class CombiningParticipantsProvider implements ParticipantsProvider {
    protected observable = merge(
        ...this.participantsProviders.map((participantsProvider) => participantsProvider.get()),
    ).pipe(distinctUntilChanged(), shareReplay(1));

    constructor(private participantsProviders: ParticipantsProvider[]) {}

    public get(): Observable<Try<Participant[]>> {
        return this.observable;
    }
}
