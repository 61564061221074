import {Race} from '../../models/race';
import {RaceProvider, SocketRaceProvider} from './race_provider';
import {RaceRepositoryFactory} from '../../persistence/race_repository_factory';
import {RaceSocket} from '../../network/race_socket';

export class RaceProviderFactory {
    private repositories = new Map<string, RaceProvider>();

    constructor(private raceSocket: RaceSocket, private raceRepositoryFactory: RaceRepositoryFactory) {}

    public create(race: Race): RaceProvider {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(
                race.id,
                new SocketRaceProvider(race.id, this.raceSocket, this.raceRepositoryFactory.create(race)),
            );
        }
        return this.repositories.get(race.id)!;
    }
}
