import {Try} from '../support/monads/try';
import {Race} from '../models/race';
import {HttpLinkProvider} from './driver/http_link_provider';
import {execute, ExecutionResult, GraphQLRequest, makePromise} from 'apollo-link';
import gql from 'graphql-tag';
import {UpdateRaceApi as DistanceUpdateRaceApi} from '../ui/select_distance_screen/select_distance_form/internal/race_distance_updater';
import {RaceType} from '../enumerations/race_type';
import {StartRaceApi} from '../ui/race_overview_screen/components/internal/race_starter';
import {AbortRaceApi} from '../ui/race_overview_screen/components/internal/race_aborter';
import {UpdateRaceApi as StartIntentUpdateRaceApi} from '../ui/race_overview_screen/components/internal/start_intent_interactor';

export interface RaceApi extends DistanceUpdateRaceApi, StartIntentUpdateRaceApi, StartRaceApi, AbortRaceApi {
    create(distanceMeters: number, email: string): Promise<Try<Race>>;

    getByToken(token: string): Promise<Try<Race>>;

    get(id: string): Promise<Try<Race>>;
}

export class DefaultRaceApi implements RaceApi {
    constructor(private httpLinkProvider: HttpLinkProvider) {}

    public async create(distanceMeters: number, email: string): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($distanceMeters: Int!, $type: RaceType, $email: String) {
                    createRace(distanceMeters: $distanceMeters, type: $type, email: $email) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                distanceMeters,
                email,
                type: RaceType.FREE,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.createRace);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async getByToken(token: string): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                query($token: String!) {
                    raceByToken(token: $token) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                token,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.raceByToken);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async get(id: string): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                query($id: String!) {
                    raceById(id: $id) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                id,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.raceById);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async updateIntentToStartAtMillis(raceId: string, intentToStartAtMillis: number | null): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!, $intentToStartAtMillis: Float) {
                    updateRace(id: $raceId, intentToStartAtMillis: $intentToStartAtMillis) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                raceId,
                intentToStartAtMillis,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.updateRace);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async updateDistanceMeters(raceId: string, distanceMeters: number): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!, $distanceMeters: Int!) {
                    updateRace(id: $raceId, distanceMeters: $distanceMeters) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                raceId,
                distanceMeters,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.updateRace);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async start(raceId: string): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!) {
                    startRace(id: $raceId) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.startRace);
        } catch (e) {
            return Try.raiseError(e);
        }
    }

    public async abort(raceId: string): Promise<Try<Race>> {
        const operation: GraphQLRequest = {
            query: gql`
                mutation($raceId: String!) {
                    abortRace(id: $raceId) {
                        id
                        distanceMeters
                        intentToStartAtMillis
                        startTimeMillis
                        state
                        token
                    }
                }
            `,
            variables: {
                raceId,
            },
        };

        try {
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just(result.data.abortRace);
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
