import {OverlayStrategy} from '../strategy_based_active_overlays_provider';
import {merge, Observable, of, SchedulerLike} from 'rxjs';
import {Overlay} from '../../overlays_presenter';
import {SplitTime} from '../../../../../models/split_time';
import {delay, distinctUntilChanged, filter, map, share, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {AsyncScheduler} from 'rxjs/internal/scheduler/AsyncScheduler';
import {AsyncAction} from 'rxjs/internal/scheduler/AsyncAction';
import {lazy} from '../../../../../support/lazy';

export interface SplitTimesProvider {
    splitTimes: Observable<SplitTime>;
}

export class SplitTimesOverlayStrategy implements OverlayStrategy {
    private splitTimeDistanceUpdates: Observable<number> = this.splitTimesProvider.splitTimes.pipe(
        map((splitTime) => splitTime.distanceMeters),
        filter((distanceMeters) => distanceMeters > 0),
        distinctUntilChanged(),
        share(),
    );

    @lazy()
    public get updates(): Observable<Overlay[]> {
        return merge(
            this.splitTimeDistanceUpdates.pipe(map(() => true)),
            this.splitTimeDistanceUpdates.pipe(switchMap(() => of(false).pipe(delay(20000, this.scheduler)))),
        ).pipe(
            startWith(false),
            distinctUntilChanged(),
            map((shouldShow) => (shouldShow ? [Overlay.SplitTimes] : [])),
            shareReplay(1),
        );
    }

    constructor(
        private splitTimesProvider: SplitTimesProvider,
        private scheduler: SchedulerLike = new AsyncScheduler(AsyncAction),
    ) {}
}
