import * as React from 'react';
import {FunctionComponent} from 'react';
import {round} from '../support/round';
import {Rect} from 'react-konva';

interface OwnProps {
    x: number;
    meterInPx: number;
    viewportOffsetPx: number;
    canvasHeight: number;
}

export const Buoys: FunctionComponent<OwnProps> = ({meterInPx, x, viewportOffsetPx, canvasHeight}) => {
    const buoys: Array<{distance: number; y: number}> = [];
    const viewportBottomMeters = Math.floor(viewportOffsetPx / meterInPx / 10) * 10;
    const viewportTopMeters = viewportBottomMeters + Math.ceil((viewportOffsetPx + canvasHeight) / meterInPx / 10) * 10;

    let locationMeters = viewportBottomMeters;
    const intervalMeters = 10;

    do {
        const y = round(canvasHeight - locationMeters * meterInPx, 0);
        if (locationMeters !== 0) {
            buoys.push({
                distance: locationMeters,
                y: y,
            });
        }
        locationMeters += intervalMeters;
    } while (locationMeters < viewportTopMeters);

    return (
        <>
            {buoys.map((buoy) => (
                <Rect key={buoy.distance} x={x} y={buoy.y + viewportOffsetPx} width={2} height={4} fill="#FFFFFF" />
            ))}
        </>
    );
};
