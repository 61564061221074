function lerp(startValue: number, targetValue: number, interpolation: number) {
    return startValue * (1 - interpolation) + targetValue * interpolation;
}

export interface AnimatedValueCalculator {
    update(targetValue: number, timestamp: number): void;
    get(timestamp: number): number;
}

export class DefaultAnimatedValueCalculator implements AnimatedValueCalculator {
    protected lastUpdateTimestamp: number | null = null;
    protected targetValue: number | null = null;
    protected currentValue: number | null = null;
    protected lastValue: number | null = null;

    protected lastGetWasSameTimestampAsUpdate = false;

    constructor(protected animationDuration = 500, private ease?: (timeDelta: number) => number) {}

    public update(targetValue: number, timestamp: number) {
        if (targetValue !== this.targetValue) {
            //If the last get was called with the same timestamp as the last update, the value will always stay at the start
            //Since a linear interpolation between 0 & 1 which gets called with 0 will always return the same value
            //Simply dont update the last timestamp in this case and that will force animation to continue
            this.lastUpdateTimestamp = this.lastGetWasSameTimestampAsUpdate ? this.lastUpdateTimestamp : timestamp;

            this.targetValue = targetValue;
            this.currentValue = this.lastValue ?? targetValue;
        }
    }

    public get(timestamp: number) {
        const timeDelta = Math.min(1, (timestamp - (this.lastUpdateTimestamp ?? 0)) / this.animationDuration);
        const easedTimeDelta = this.ease ? this.ease(timeDelta) : timeDelta;
        this.lastValue = lerp(this.currentValue ?? 0, this.targetValue ?? 0, easedTimeDelta);
        this.lastGetWasSameTimestampAsUpdate = timestamp === this.lastUpdateTimestamp;
        return this.lastValue;
    }
}

export class UpdateIntervalAnimatedValueCalculator extends DefaultAnimatedValueCalculator {
    private lastFiveUpdateTimestamps: number[] = [];

    constructor(
        animationDuration = 500,
        private animationDurationPadding: number,
        ease?: (timeDelta: number) => number,
    ) {
        super(animationDuration, ease);
    }

    private getAverageDelta() {
        const deltas: number[] = [];
        for (let index = 0; index < this.lastFiveUpdateTimestamps.length - 1; index++) {
            const timestamp = this.lastFiveUpdateTimestamps[index];
            const nextTimestamp = this.lastFiveUpdateTimestamps[index + 1];

            deltas.push(nextTimestamp - timestamp);
        }
        return deltas;
    }

    private average(array: number[]) {
        return array.reduce((prev, curr) => prev + curr, 0) / array.length;
    }

    public update(targetValue: number, timestamp: number) {
        if (targetValue !== this.targetValue) {
            this.lastFiveUpdateTimestamps.push(timestamp);
            if (this.lastFiveUpdateTimestamps.length > 5) {
                this.lastFiveUpdateTimestamps.shift();
            }

            const averageUpdateTime = this.average(this.getAverageDelta());
            if (averageUpdateTime) {
                this.animationDuration =
                    averageUpdateTime + (this.animationDurationPadding ? this.animationDurationPadding : 0);
            }

            super.update(targetValue, timestamp);
        }
    }
}
