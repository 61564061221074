import * as React from 'react';
import {FunctionComponent} from 'react';
import {Device} from '../../../../../models/device';
import {ConnectionIndicator} from './connection_indicator';
import {WheelSpinIndicator} from './wheel_spin_indicator';
import styled from 'styled-components';

interface OwnProps {
    device: Device;
}

export const AlertSquareContainer = styled.div`
    margin-left: -5px;
    margin-right: 4px;
    float: left;
`;

export const DeviceState: FunctionComponent<OwnProps> = ({device}) => {
    return (
        <AlertSquareContainer>
            <ConnectionIndicator ping={device.ping} lastPingReceived={device.lastPingReceivedMillis} />
            {device.isWheelSpinning && <WheelSpinIndicator />}
        </AlertSquareContainer>
    );
};
