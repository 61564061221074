import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Row} from '../../components/row';
import {Header} from '../components/header';
import {Heading1} from '../components/heading';
import {BackgroundPhoto} from '../../components/background_photo';
import {Match, RouteComponentProps} from '@reach/router';
import {Content} from '../components/content';
import {Container} from '../components/container';
import {Instruction} from './components/instruction';
import {Code} from './screens/code';
import {Create} from './screens/create';
import styled from 'styled-components';

const InlogRow = styled(Row)`
    align-content: start;
    text-align: center;

    @media (min-width: 992px) {
        align-content: center;
        text-align: left;
    }
`;

interface OwnProps {}
export const LoginScreen: FunctionComponent<OwnProps & RouteComponentProps> = observer(({navigate}) => {
    return (
        <>
            <Header />
            <Content>
                <BackgroundPhoto />
                <Container>
                    <InlogRow className="h-100">
                        <div className="col-8 offset-2 col-lg-5 offset-lg-1 mt-3">
                            <Heading1>Welcome, ready to set up your race</Heading1>
                            <Instruction>
                                Join a race or create one yourself! After this you'll receive a key you can share with
                                your devices.
                            </Instruction>
                        </div>
                        <div className="col-8 offset-2 col-lg-4 offset-lg-1 mt-3">
                            <Match path="/">
                                {({match}) =>
                                    match && <Code onFinish={(race) => navigate!(`/${race.id}/lobby`, {state: race})} />
                                }
                            </Match>
                            <Match path="/create">
                                {({match}) =>
                                    match && (
                                        <Create onFinish={(race) => navigate!(`/${race.id}/lobby`, {state: race})} />
                                    )
                                }
                            </Match>
                        </div>
                    </InlogRow>
                </Container>
            </Content>
        </>
    );
});
