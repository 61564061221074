import styled from 'styled-components';

export const CombinationWrapper = styled.div`
    display: flex;
    margin: 0 0 5px 0;
    padding: 0;
    > div {
        margin-right: 10px;
    }
    > div:last-child {
        margin-right: 0;
    }
`;
