import {leftPad} from './left_pad';

export function formatFinishTimeMs(timeMs: number): string {
    return formatFinishTimeSeconds(timeMs / 1000);
}

export function formatFinishTimeSeconds(timeSeconds: number, lowPrecision = false): string {
    let wholeSeconds = Math.floor(timeSeconds);

    const fraction = timeSeconds - wholeSeconds;
    let roundedFraction = Math.ceil(fraction * 100);
    if (roundedFraction >= 100) {
        roundedFraction -= 100;
        wholeSeconds += 1;
    }

    const wholeMinutes = Math.floor(wholeSeconds / 60);
    const secondsLeft = wholeSeconds - wholeMinutes * 60;

    const secondsString = leftPad(secondsLeft, 2, '0');

    if (lowPrecision) {
        return `${wholeMinutes}:${secondsString}`;
    }
    const fractionString = leftPad(roundedFraction, 2, '0');

    return `${leftPad(wholeMinutes, 2, '0')}:${secondsString}.${fractionString}`;
}
