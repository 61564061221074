import styled from 'styled-components';

export const Circle = styled.div`
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px 0;
    border-radius: 30px;
    border: 2px solid #d8d8d8;
    color: #808080;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
`;
