export function formatStrokeRateWithUnit(strokeRate: number | null) {
    if (strokeRate === null) {
        return 'N/A';
    }

    return formatStrokeRate(strokeRate) + ' s/m';
}
export function formatStrokeRate(strokeRate: number | null): string {
    if (strokeRate === null) {
        return 'N/A';
    }
    return strokeRate.toFixed(1);
}
