import * as React from 'react';
import {Text} from 'react-konva';
import {Lane} from '../internal/lanes_provider';

interface OwnProps {
    lanes: readonly Lane[];
    viewPortOffsetPx: number;
    canvasHeight: number;
}

interface MemoizableOwnProps {
    lanes: readonly Lane[];
    viewPortOffsetPx: number;
}

const MemoizeableLaneNumbers: React.FunctionComponent<MemoizableOwnProps> = React.memo(({lanes, viewPortOffsetPx}) => {
    return (
        <>
            {lanes.map((lane, index) => {
                return (
                    <Text
                        key={lane.boatId || index}
                        text={String(index + 1)}
                        verticalAlign="middle"
                        wrap="none"
                        align="center"
                        fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                        fontSize={14}
                        ellipsis={true}
                        fontStyle="bold"
                        fill="white"
                        x={lane.x}
                        y={viewPortOffsetPx}
                        width={lane.width}
                    />
                );
            })}
        </>
    );
});

export const LaneNumbers: React.FunctionComponent<OwnProps> = ({canvasHeight, lanes, viewPortOffsetPx}) => {
    return (
        <MemoizeableLaneNumbers lanes={lanes} viewPortOffsetPx={Math.min(0, viewPortOffsetPx) + canvasHeight - 30} />
    );
};
