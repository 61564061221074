import {Observable, of} from 'rxjs';
import {lazy} from '../../../support/lazy';
import {distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs/operators';
import {formatTimeMs} from '../../../support/format_time';
import {ServerTimeProvider} from '../../../business/countdown/server_time/server_time_provider';
import {StartTimeProvider} from '../../../business/race/start_time_provider';
import {ClockProvider} from '../overlays/overlays/clock/clock_presenter';

export class DefaultClockProvider implements ClockProvider {
    @lazy()
    public get updates(): Observable<string | null> {
        return this.startTimeProvider.get().pipe(
            switchMap((startTimeMillis) => {
                if (startTimeMillis === null) {
                    return of(null);
                }
                return this.serverTimeProvider.getTime().pipe(
                    map((serverTime) => serverTime - startTimeMillis),
                    map((timeIntoRaceMillis) => (timeIntoRaceMillis < 0 ? 0 : timeIntoRaceMillis)),
                    distinctUntilChanged(),
                    map((timeIntoRaceMillis) => formatTimeMs(timeIntoRaceMillis)),
                    distinctUntilChanged(),
                );
            }),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    constructor(private startTimeProvider: StartTimeProvider, private serverTimeProvider: ServerTimeProvider) {}
}
