import {OverlayStrategy} from '../strategy_based_active_overlays_provider';
import {Overlay} from '../../overlays_presenter';
import {distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {StartTimeProvider} from '../../../../../business/race/start_time_provider';
import {Observable, of} from 'rxjs';
import {BoatsProgressProvider} from '../../../../../business/boats/boats_progress_provider';
import {debounceThrottle} from '../../../../../support/rx_debounce_throttle';

export class ClockOverlayStrategy implements OverlayStrategy {
    public updates: Observable<Overlay[]> = this.startTimeProvider.get().pipe(
        distinctUntilChanged(),
        switchMap((startTimeMillis) => {
            if (startTimeMillis === null) {
                return of([]);
            }
            return debounceThrottle(this.boatsProgressProvider.get(), 1000).pipe(
                map((boatProgresses) => {
                    return boatProgresses.length > 0 &&
                        boatProgresses.every((boatProgress) => boatProgress.definitiveFinishTimeMillis !== null)
                        ? []
                        : [Overlay.Clock];
                }),
            );
        }),
        startWith([]),
    );

    constructor(private startTimeProvider: StartTimeProvider, private boatsProgressProvider: BoatsProgressProvider) {}
}
