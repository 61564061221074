import styled from 'styled-components';
import {Col} from '../../components/col';

export const ContentCol = styled(Col)`
    background-color: #fafafa;
    overflow: auto;
    max-height: 100%;

    padding: 10px;
    @media (min-width: 992px) {
        padding: 30px;
    }
`;
