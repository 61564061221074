import {BoatDeleter} from '../boat_block_presenter';
import {Boat} from '../../../../../models/boat';
import {Try} from '../../../../../support/monads/try';

export interface DeleteBoatApi {
    delete(boatId: string): Promise<Try<Boat>>;
}

export class ApiBoatDeleter implements BoatDeleter {
    constructor(private boatId: string, private deleteBoatApi: DeleteBoatApi) {}

    public delete(): Promise<Try<Boat>> {
        return this.deleteBoatApi.delete(this.boatId);
    }
}
