import {fromEvent, Observable} from 'rxjs';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';

export interface ToggleTokenData {
    isTokenVisible: boolean;
}

export interface ToggleTokenSocket {
    getForRaceId(raceId: string): Observable<ToggleTokenData>;

    toggleForRaceId(raceId: string, isTokenVisible: boolean): void;
}

export class DefaultToggleTokenSocket implements ToggleTokenSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<ToggleTokenData> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/toggle-token`);
    }

    public toggleForRaceId(raceId: string, isTokenVisible: boolean) {
        return this.socketIoClientProvider.get().emit(`race/${raceId}/toggle-token`, {isTokenVisible});
    }
}
