import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {BoatsScreenComponent} from '../../../boats_screen_component';
import {StatisticsTable} from '../components/statistics_table';
import {SpeedsPresenter} from './speeds_presenter';
import {formatSpeedMps} from '../../../../../support/format_speed';

interface OwnProps {
    x: number;
    y: number;
    boatScreenComponent: BoatsScreenComponent;
}

const SpeedsComponent: FunctionComponent<OwnProps & PresenterProps<SpeedsPresenter>> = observer(({x, y, presenter}) => {
    return (
        <StatisticsTable
            x={x}
            y={y}
            title="Speed"
            rows={presenter.speeds.map((speed) => {
                return {
                    name: speed.boatName,
                    formattedMetric: formatSpeedMps(speed.strokeAverageSpeedMps),
                };
            })}
        />
    );
});

export const Speeds = withPresenter<SpeedsPresenter, OwnProps>(
    (props, component) => new SpeedsPresenter(props.boatScreenComponent.boatsProgressProvider),
    SpeedsComponent,
);
