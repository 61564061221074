import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Participant} from '../../../../../models/participant';
import {ParticipantEditModalPresenter} from './participant_edit_modal_presenter';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '../../../../components/modal';
import {ApiParticipantUpdater} from './internal/participant_updater';

interface OwnProps {
    isOpen: boolean;
    onClose: () => void;
    participant: Participant;
}

const ParticipantEditModalComponent: React.FunctionComponent<
    OwnProps & PresenterProps<ParticipantEditModalPresenter>
> = observer(({isOpen, onClose, presenter}) => {
    const {participant, onParticipantNameChange, onParticipantWeightKiloGramsChange, onSave} = presenter;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h5>Edit "{participant.name}"</h5>
                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </ModalHeader>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSave()
                        .then(() => onClose())
                        .catch((e) => console.error(e));
                }}>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor={'participant-name-' + participant.id}>Participant name</label>
                        <input
                            type="text"
                            className="form-control"
                            id={'participant-name-' + participant.id}
                            value={participant.name}
                            onChange={(e) => onParticipantNameChange(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={'participant-weight-' + participant.id}>Weight (kg)</label>
                        <input
                            type="number"
                            step={0.1}
                            className="form-control"
                            id={'participant-weight-' + participant.id}
                            value={participant.weightGrams === null ? '' : participant.weightGrams / 1000}
                            onChange={(e) =>
                                onParticipantWeightKiloGramsChange(
                                    e.target.value === '' ? null : parseFloat(e.target.value),
                                )
                            }
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Save changes
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
});

export const ParticipantEditModal = withPresenter<ParticipantEditModalPresenter, OwnProps>(
    (props, component) =>
        new ParticipantEditModalPresenter(
            props.participant,
            new ApiParticipantUpdater(component.networkComponent.participantApi),
        ),
    ParticipantEditModalComponent,
);
