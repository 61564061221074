import {BoatProgress} from '../../../../business/boats/boats_progress_provider';
import {CanvasSizes} from '../../boats_screen_presenter';
import {getMeterInPx} from '../../calculators/viewport_offset_calculator';
import {calculateImageSizeMultiplier} from '../../support/image_data';
import {ViewPortSizeProvider} from '../../support/viewport_sizes';
import {Lane} from '../lanes_provider';
import {CanvasSizesCalculator} from '../boats_screen_state_provider';

export class DefaultCanvasSizesCalculator implements CanvasSizesCalculator {
    private previousMeterInPx = Infinity;

    constructor(private viewPortSizeProvider: ViewPortSizeProvider) {}

    public calculate(boatProgresses: BoatProgress[], lanes: Lane[]): CanvasSizes {
        const imageSizeMultiplier = calculateImageSizeMultiplier(lanes);
        const finishLineBlockSizePx = 8 * imageSizeMultiplier;

        const viewportHeightPx = this.viewPortSizeProvider.canvasHeightPx;

        const meterInPx = Math.min(getMeterInPx(viewportHeightPx, boatProgresses), this.previousMeterInPx);
        if (this.previousMeterInPx > meterInPx) {
            this.previousMeterInPx = meterInPx;
        }

        if (this.previousMeterInPx === Infinity) {
            this.previousMeterInPx = getMeterInPx(viewportHeightPx, boatProgresses);
        }

        const sizes: CanvasSizes = {
            imageSizeMultiplier,
            finishLineBlockSizePx,
            meterInPx: this.previousMeterInPx,
            canvasWidthPx: this.viewPortSizeProvider.canvasWidthPx,
            canvasHeightPx: this.viewPortSizeProvider.canvasHeightPx,
        };

        return sizes;
    }
}
