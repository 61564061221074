import {from, Observable} from 'rxjs';
import {first, tap, shareReplay} from 'rxjs/operators';
import {Try} from '../../support/monads/try';
import {Participant} from '../../models/participant';
import {ParticipantsProvider} from './participant_provider';

export interface ParticipantsRepository {
    replace(participants: Participant[]): void;
}

export interface ParticipantsForRaceApi {
    getForRaceId(raceId: string): Promise<Try<Participant[]>>;
}

export class ApiParticipantProvider implements ParticipantsProvider {
    private observable = from(this.participantsForRaceApi.getForRaceId(this.raceId)).pipe(
        first(),
        tap((participantsTry) =>
            participantsTry.map((participants) => this.participantRepository.replace(participants)),
        ),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private participantsForRaceApi: ParticipantsForRaceApi,
        private participantRepository: ParticipantsRepository,
    ) {}

    public get(): Observable<Try<Participant[]>> {
        return this.observable;
    }
}
