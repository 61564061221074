import styled from 'styled-components';

export const Footer = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    border-top: 2px solid #fafafa;
    padding: 10px 20px;
    min-height: 72px;
`;
