import {Try} from '../../../support/monads/try';
import {Race} from '../../../models/race';
import {RaceApi} from '../../../network/race_api';

export interface JoinRaceInteractor {
    join(token: string): Promise<Try<Race>>;
}

export class DefaultJoinRaceInteractor implements JoinRaceInteractor {
    constructor(private raceApi: RaceApi) {}

    public join(token: string): Promise<Try<Race>> {
        return this.raceApi.getByToken(token);
    }
}
