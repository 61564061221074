import {Race} from '../models/race';
import {DefaultRaceRepository, RaceRepository} from './race_repository';

export class RaceRepositoryFactory {
    private repositories = new Map<string, RaceRepository>();

    public create(race: Race): RaceRepository {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(race.id, new DefaultRaceRepository(race));
        }
        return this.repositories.get(race.id)!;
    }
}
