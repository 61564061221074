import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../models/race';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {DeviceStatesPresenter} from './device_states_presenter';
import {Block} from '../../boats_overview_screen/boats_grid/components/block';
import styled from 'styled-components';
import {Row} from '../../../components/row';
import {Col} from '../../../components/col';
import {BlockItem} from '../../boats_overview_screen/boats_grid/components/block_item';
import {DeviceState} from '../../boats_overview_screen/boats_grid/devices_block/components/device_state';
import {DefaultBoatsWithCombinationsProvider} from '../../../business/boats/boats_with_combinations_provider';
import {ParticipantState} from '../../boats_overview_screen/boats_grid/participants_block/components/participant_state';
import {SocketBoatsProgressProvider} from '../../../business/boats/boats_progress_provider';
import {DefaultBoatProgressWithCombinationsProvider} from '../../../business/boats/boats_with_progress_and_combinations_provider';
import {BoatProgressRow} from '../components/boat_progress_row';
import {StrokesDownloadButton} from '../components/strokes_download_button';
import {DefaultStartTimeProvider} from '../../../business/race/start_time_provider';

interface OwnProps {
    race: Race;
}

const BoatBlock = styled.div`
    position: relative;
    border-radius: 0 5px 5px 5px;
    border: solid 2px #7c7c7c;
    margin: 10px 0 25px 0;
    padding: 10px 10px 5px 10px;
`;

const Label = styled.div`
    position: absolute;
    color: #ffffff;
    border-radius: 5px 5px 0 0;
    background-color: #7c7c7c;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 15px 0px;
    bottom: 100%;
    left: -2px;
`;

const CombinationRow = styled.div`
    display: flex;
    margin-bottom: 5px;

    > div:first-child {
        margin-right: 5px;
    }

    > div {
        flex-grow: 1;
        width: 0px;
        max-width: 200px;
    }
`;
const DeviceStatesComponent: FunctionComponent<OwnProps & PresenterProps<DeviceStatesPresenter>> = observer(
    ({presenter, race}) => {
        return (
            <Block title="Device states" icon="ion-md-pulse">
                {presenter.boatsWithCombinations.map((boatWithCombination) => {
                    return (
                        <BoatBlock key={boatWithCombination.boat.id}>
                            <Label>{boatWithCombination.boat.name}</Label>
                            <BoatProgressRow boatWithCombination={boatWithCombination} />
                            {boatWithCombination.combinations.map((combination) => (
                                <Row key={combination.device.id}>
                                    <Col numCols={12}>
                                        <CombinationRow>
                                            <BlockItem dark large>
                                                <DeviceState device={combination.device} />
                                                {combination.device.id.substr(0, 4)}
                                            </BlockItem>
                                            <BlockItem>
                                                <ParticipantState participant={combination.participant} />
                                                {combination.participant.name}
                                            </BlockItem>
                                            {boatWithCombination.boat.definitiveFinishTimeMillis !== null && (
                                                <StrokesDownloadButton
                                                    participantId={combination.participant.id}
                                                    participantName={combination.participant.name}
                                                    raceStartTimeMillis={race.startTimeMillis || 0}
                                                />
                                            )}
                                        </CombinationRow>
                                    </Col>
                                </Row>
                            ))}
                        </BoatBlock>
                    );
                })}
            </Block>
        );
    },
);

export const DeviceStates = withPresenter<DeviceStatesPresenter, OwnProps>((props, component) => {
    const boatsWithCombinationsProvider = new DefaultBoatsWithCombinationsProvider(
        component.boatsProviderFactory.create(props.race),
        component.participantsProviderFactory.create(props.race),
        component.devicesProviderFactory.create(props.race),
    );
    const startTimeProvider = new DefaultStartTimeProvider(
        props.race,
        component.raceProviderFactory.create(props.race),
    );
    const boatsProgressProvider = new SocketBoatsProgressProvider(
        props.race.id,
        startTimeProvider,
        boatsWithCombinationsProvider,
        component.networkComponent.boatsProgressSocket,
    );

    const boatProgressWithCombinationsProvider = new DefaultBoatProgressWithCombinationsProvider(
        boatsProgressProvider,
        component.participantsProviderFactory.create(props.race),
        component.devicesProviderFactory.create(props.race),
    );

    return new DeviceStatesPresenter(boatProgressWithCombinationsProvider);
}, DeviceStatesComponent);
