import * as React from 'react';
import {FunctionComponent} from 'react';
import {Race} from '../../../models/race';
import {Col} from '../../../components/col';
import {Row} from '../../../components/row';
import styled from 'styled-components';
import {BigScreen} from '../../big_screen/big_screen';

interface OwnProps {
    race: Race;
}

const Header = styled.div`
    height: 65px;
    width: 100%;
    padding: 0 30px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    line-height: 65px;
    text-align: left;
    color: #5f5f5f;
    background-color: #ffffff;
    border-radius: 5px 5px 0 0;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
`;

export const RaceOverview: FunctionComponent<OwnProps> = ({race}) => {
    return (
        <Wrapper className="d-flex flex-column">
            <Row>
                <Col numCols={12}>
                    <Header>Race overview</Header>
                </Col>
            </Row>
            <BigScreen isEmbedded race={race} />
        </Wrapper>
    );
};
