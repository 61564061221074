import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Participant} from '../../../../../models/participant';
import {FalseStartIndicator} from './false_start_indicator';

interface OwnProps {
    participant: Participant;
}

export const AlertSquareContainer = styled.div`
    margin: -2px 5px 0 -8px
    float: left;
`;
export const ParticipantState: FunctionComponent<OwnProps> = ({participant}) => {
    return <AlertSquareContainer>{participant.didFalseStart && <FalseStartIndicator />}</AlertSquareContainer>;
};
