import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BoatProgress, BoatsProgressProvider} from '../../../business/boats/boats_progress_provider';
import {
    BoatsWithCombinationsProvider,
    BoatWithCombinations,
} from '../../../business/boats/boats_with_combinations_provider';
import {
    CanvasSizes,
    RealtimeBoatProgress,
    RealtimeBoatsScreenState,
    RealtimeBoatsScreenStateProvider,
} from '../boats_screen_presenter';
import {getAnimationFrameObservable} from '../support/animation_frame_observable';
import {ViewPortSizeProvider} from '../support/viewport_sizes';
import {Lane, LanesProvider} from './lanes_provider';
import {Try} from '../../../support/monads/try';

export interface AnimatedBoatsProgressCalculator {
    calculate(boatProgresses: BoatProgress[], timestamp: number): BoatProgress[];
}

export interface AnimatedCameraOffsetCalculator {
    calculate(lastOffset: number, timestamp: number): number;
}

export interface AnimatedCanvasSizesCalculator {
    calculate(canvasSizes: CanvasSizes, timestamp: number): CanvasSizes;
}

export interface CameraOffsetCalculator {
    calculate(
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number;
}

export interface CanvasSizesCalculator {
    calculate(boatProgresses: BoatProgress[], lanes: Lane[]): CanvasSizes;
}

export interface RealtimeBoatPositionsCalculator {
    calculate(boatProgresses: BoatProgress[], canvasSizes: CanvasSizes): RealtimeBoatProgress[];
}

export class DefaultRealtimeBoatsScreenStateProvider implements RealtimeBoatsScreenStateProvider {
    constructor(
        private boatsWithCombinationsProvider: BoatsWithCombinationsProvider,
        private viewPortSizeProvider: ViewPortSizeProvider,
        private boatsProgressProvider: BoatsProgressProvider,
        private lanesProvider: LanesProvider,
        private canvasSizesCalculator: CanvasSizesCalculator,
        private animatedCanvasSizesCalculator: AnimatedCanvasSizesCalculator,
        private animatedBoatsProgressCalculator: AnimatedBoatsProgressCalculator,
        private realtimeBoatPositionsCalculator: RealtimeBoatPositionsCalculator,
        private cameraPositionCalculator: CameraOffsetCalculator,
        private animatedCameraPositionCalculator: AnimatedCameraOffsetCalculator,
    ) {}

    public stream(): Observable<RealtimeBoatsScreenState> {
        return combineLatest([
            this.boatsWithCombinationsProvider.get(),
            this.boatsProgressProvider.get(),
            this.lanesProvider.lanesStream(this.viewPortSizeProvider.canvasWidthPx),
            getAnimationFrameObservable(),
        ]).pipe(
            map(([boatsWithCombinationsTry, boatProgresses, lanes, timestamp]) => {
                return {
                    boatsWithCombinationsTry,
                    boatProgresses,
                    lanes,
                    timestamp,
                };
            }),
            map((data) => {
                const canvasSizes = this.animatedCanvasSizesCalculator.calculate(
                    this.canvasSizesCalculator.calculate(data.boatProgresses, data.lanes),
                    data.timestamp,
                );

                return {
                    ...data,
                    canvasSizes,
                };
            }),
            map((data) => {
                const boatProgresses = this.realtimeBoatPositionsCalculator.calculate(
                    this.animatedBoatsProgressCalculator.calculate(data.boatProgresses, data.timestamp),
                    data.canvasSizes,
                );

                return {
                    ...data,
                    boatProgresses,
                };
            }),
            map((data) => {
                const cameraOffset = this.animatedCameraPositionCalculator.calculate(
                    this.cameraPositionCalculator.calculate(
                        data.boatsWithCombinationsTry,
                        data.boatProgresses,
                        data.canvasSizes,
                    ),
                    data.timestamp,
                );

                return {
                    ...data,
                    cameraOffset,
                };
            }),
        );
    }
}
