import {Try} from '../../../support/monads/try';
import {Race} from '../../../models/race';
import {RaceApi} from '../../../network/race_api';

export interface CreateRaceInteractor {
    create(email: string): Promise<Try<Race>>;
}

export class DefaultCreateRaceInteractor implements CreateRaceInteractor {
    constructor(private raceApi: RaceApi) {}

    public create(email: string): Promise<Try<Race>> {
        return this.raceApi.create(500, email);
    }
}
