import * as React from 'react';
import {FunctionComponent} from 'react';
import {Device} from '../../../../models/device';
import {DraggableBlockItem} from './draggable_block_item';
import {DraggableType} from '../boats_grid_drag_handler_presenter';
import {DeviceState} from '../devices_block/components/device_state';
import {isAuthorized} from '../../../../business/permissions/is_authorized';
import {Permission} from '../../../../enumerations/permission';
import {BlockItem} from './block_item';
import {Race} from '../../../../models/race';

interface OwnProps {
    device: Device;
    race: Race;
    id: string;
    type: DraggableType;
    index: number;
    dark?: boolean;
}

export const DraggableDeviceBlockItem: FunctionComponent<OwnProps> = ({id, type, index, device, race}) => {
    const name = device.id.substr(0, 4);

    if (!isAuthorized(Permission.EDIT_DEVICE, race)) {
        return (
            <BlockItem dark>
                <DeviceState device={device} />
                {name}
            </BlockItem>
        );
    }

    return (
        <DraggableBlockItem dark id={id} type={type} index={index}>
            <DeviceState device={device} />
            {name}
        </DraggableBlockItem>
    );
};
