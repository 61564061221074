import * as React from 'react';
import {useEffect, useState} from 'react';
import {Lane} from '../internal/lanes_provider';
import {boatImagesData, boatImagesWidth, getBoatHeightPx} from '../support/image_data';
import {Boat as BoatComp} from './boat';
import boatFrontSvg from '../boat_images/boat-top.svg';
import boatRowerSvg from '../boat_images/boat-middle.svg';
import boatRearSvg from '../boat_images/boat-bottom.svg';
import {RealtimeBoatProgress} from '../boats_screen_presenter';

//Each lane has one separator lying inside it, there should always be a forced width
const laneSeparatorWidthPx = 2;

interface OwnProps {
    realtimeBoatProgresses: readonly RealtimeBoatProgress[];
    lanes: readonly Lane[];
    viewportOffsetPx: number;
    distanceMeters: number;
    meterInPx: number;
    canvasHeightPx: number;
    finishLineHeightPx: number;
    imageSizeMultiplier: number;
}

async function drawImageOnCanvas(
    ctx: CanvasRenderingContext2D,
    imageSrc: string,
    dx: number,
    dy: number,
    dw: number,
    dh: number,
) {
    return new Promise<void>((resolve) => {
        const boatFrontImage = new Image();
        boatFrontImage.src = imageSrc;
        boatFrontImage.onload = () => {
            ctx.clearRect(dx, dy, dw, dh);
            ctx.drawImage(boatFrontImage, dx, dy, dw, dh);
            resolve();
        };
    });
}

function metersToTop(canvasHeightPx: number, meters: number, meterInPx: number, marginPx: number): number {
    return canvasHeightPx - meters * meterInPx - marginPx;
}

function calculateFinishMarginPx(
    rowedDistanceMeters: number,
    targetDistanceMeters: number,
    finishLineHeightPx: number,
    boatHeightPx: number,
): number {
    const isBoatFinished = rowedDistanceMeters >= targetDistanceMeters;
    return isBoatFinished ? finishLineHeightPx + boatHeightPx : 0;
}

function calculateBoatStyle(
    realtimeBoatProgress: RealtimeBoatProgress,
    lanes: readonly Lane[],
    viewportOffsetPx: number,
    targetDistanceMeters: number,
    canvasHeightPx: number,
    meterInPx: number,
    finishLineHeightPx: number,
    imageSizeMultiplier: number,
) {
    const lane = lanes.find((l) => l.boatId === realtimeBoatProgress.boat.id);
    const boatHeightPx = getBoatHeightPx(realtimeBoatProgress.boat.participantCount) * imageSizeMultiplier;
    const finishMarginPx = calculateFinishMarginPx(
        realtimeBoatProgress.state.meters,
        targetDistanceMeters,
        finishLineHeightPx,
        boatHeightPx,
    );
    const cappedRowedDistance = Math.min(realtimeBoatProgress.state.meters, targetDistanceMeters);
    const boatTop = metersToTop(canvasHeightPx, cappedRowedDistance, meterInPx, finishMarginPx) + viewportOffsetPx;

    return {
        y: boatTop,
        x: lane ? lane.x : 0,
    };
}

export const AnimatingBoats: React.FunctionComponent<OwnProps> = ({
    realtimeBoatProgresses,
    lanes,
    viewportOffsetPx,
    distanceMeters,
    meterInPx,
    canvasHeightPx,
    finishLineHeightPx,
    imageSizeMultiplier,
}) => {
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [offscreenCanvas] = useState<HTMLCanvasElement>(() => document.createElement('canvas'));

    useEffect(() => {
        offscreenCanvas.height = 200 * imageSizeMultiplier;
        offscreenCanvas.width = imageSizeMultiplier * boatImagesWidth * 4;

        const ctx = offscreenCanvas.getContext('2d');
        if (ctx !== null) {
            ctx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            Promise.all([
                drawImageOnCanvas(
                    ctx,
                    boatFrontSvg,
                    boatImagesData.front.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.front.y * imageSizeMultiplier,
                    boatImagesData.front.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.front.height * imageSizeMultiplier,
                ),
                drawImageOnCanvas(
                    ctx,
                    boatRowerSvg,
                    boatImagesData.rower.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.rower.y * imageSizeMultiplier,
                    boatImagesData.rower.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.rower.height * imageSizeMultiplier,
                ),
                drawImageOnCanvas(
                    ctx,
                    boatRearSvg,
                    boatImagesData.rear.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.rear.y * imageSizeMultiplier,
                    boatImagesData.rear.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.rear.height * imageSizeMultiplier,
                ),
            ]).then(() => {
                setAllImagesLoaded(true);
            });
        }
    }, [imageSizeMultiplier, offscreenCanvas]);

    if (!allImagesLoaded) {
        return null;
    }

    return (
        <>
            {realtimeBoatProgresses.map((realtimeBoatProgress) => {
                const boatStyle = calculateBoatStyle(
                    realtimeBoatProgress,
                    lanes,
                    viewportOffsetPx,
                    distanceMeters,
                    canvasHeightPx,
                    meterInPx,
                    finishLineHeightPx,
                    imageSizeMultiplier,
                );
                return (
                    <BoatComp
                        key={realtimeBoatProgress.boat.id}
                        x={boatStyle.x}
                        y={boatStyle.y}
                        participantCount={realtimeBoatProgress.boat.participantCount}
                        offscreenCanvas={offscreenCanvas}
                        imageSizeMultiplier={imageSizeMultiplier}
                    />
                );
            })}
        </>
    );
};
