import {Race} from '../models/race';
import {DefaultBoatsRepository, BoatsRepository} from './boats_repository';

export class BoatsRepositoryFactory {
    private repositories = new Map<string, BoatsRepository>();

    public create(race: Race): BoatsRepository {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(race.id, new DefaultBoatsRepository());
        }
        return this.repositories.get(race.id)!;
    }
}
