import {Observable, of} from 'rxjs';
import {delay, first, flatMap, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {StartTimeProvider} from '../../../../../../business/race/start_time_provider';
import {ServerTimeProvider} from '../../../../../../business/countdown/server_time/server_time_provider';
import {lazy} from '../../../../../../support/lazy';

export interface StartSignalProvider {
    signal: Observable<boolean>;
}

export class DefaultStartSignalProvider implements StartSignalProvider {
    @lazy()
    public get signal(): Observable<boolean> {
        return this.startTimeProvider.get().pipe(
            switchMap((startTimeMillis) => {
                if (startTimeMillis === null) {
                    return of(false);
                }
                return this.serverTimeProvider.getTime().pipe(
                    first(),
                    flatMap((serverTime) => of(true).pipe(delay(startTimeMillis - serverTime))),
                );
            }),
            startWith(false),
            shareReplay(1),
        );
    }

    constructor(private startTimeProvider: StartTimeProvider, private serverTimeProvider: ServerTimeProvider) {}
}
