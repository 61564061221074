import * as React from 'react';
import ReachTooltip, {TooltipProps} from '@reach/tooltip';
import '@reach/tooltip/styles.css';

export const StyledTooltip: React.FunctionComponent<TooltipProps> = ({children, label}) => {
    return (
        <ReachTooltip
            label={label}
            style={{
                background: 'rgba(0, 0, 0, 0.75)',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                padding: '0.5em 1em',
            }}>
            {children}
        </ReachTooltip>
    );
};
