import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {Row} from '../../../components/row';
import {Col} from '../../../components/col';
import {FancyInput} from '../components/fancy_input';
import {HugeSubmitButton} from '../components/huge_submit_button';
import {CodeContainer} from '../components/code_container';
import {CodePresenter} from './code_presenter';
import {DefaultJoinRaceInteractor} from '../internal/join_race_interactor';
import {Race} from '../../../models/race';
import {RouteComponentProps} from '@reach/router';
import {HugeLink} from '../components/huge_link';

interface OwnProps {
    onFinish: (race: Race) => void;
}

const CodeComponent: FunctionComponent<OwnProps & RouteComponentProps & PresenterProps<CodePresenter>> = observer(
    ({presenter}) => {
        return (
            <CodeContainer className={presenter.error !== null ? 'shake' : undefined}>
                <Row>
                    <Col numCols={12}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                presenter.onSubmit();
                            }}>
                            <FancyInput
                                autoFocus
                                type="text"
                                value={presenter.token}
                                onChange={(e) => presenter.onTokenChange(e.target.value)}
                            />
                        </form>
                    </Col>
                </Row>
                <Row>
                    <Col numCols={12}>
                        <HugeSubmitButton onClick={() => presenter.onJoinClick()}>
                            <span>Join race</span>
                        </HugeSubmitButton>
                    </Col>
                </Row>
                <Row>
                    <Col numCols={12}>
                        <HugeLink whiteBackground to="/create">
                            <span>Create race</span>
                        </HugeLink>
                    </Col>
                </Row>
            </CodeContainer>
        );
    },
);

export const Code = withPresenter<CodePresenter, OwnProps & RouteComponentProps>(
    (props, component) =>
        new CodePresenter(props.onFinish, new DefaultJoinRaceInteractor(component.networkComponent.raceApi)),
    CodeComponent,
);
