import {NextBoatNameProvider} from './create_boat_interactor';
import {Boat} from '../../../../models/boat';
import {Option} from '../../../../support/monads/option';

export interface BoatByNameRepository {
    getByName(name: string): Promise<Option<Boat>>;
}

export class DefaultNextBoatNameProvider implements NextBoatNameProvider {
    constructor(private boatByNameRepository: BoatByNameRepository) {}

    public async get(): Promise<string> {
        let i = 1;

        let boatOption: Option<Boat> = Option.none();
        let name = `Boat ${i}`;
        do {
            name = `Boat ${i}`;
            boatOption = await this.boatByNameRepository.getByName(name);
        } while (!boatOption.isEmpty() && i++);

        return name;
    }
}
