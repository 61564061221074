import {Presenter} from '../../../../../support/with_presenter';
import {runInAction} from 'mobx';
import {Participant} from '../../../../../models/participant';
import {Try} from '../../../../../support/monads/try';
import {toast} from 'react-toastify';

export interface CreateParticipantInteractor {
    create(name: string): Promise<Try<Participant>>;
}

export class AddParticipantFormPresenter implements Presenter {
    public busy: boolean = false;

    public isAdding: boolean = false;

    public participantName: string = '';

    private readonly escapeKeyCode = 27;

    constructor(private createParticipantInteractor: CreateParticipantInteractor) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onStartAddingClick() {
        this.isAdding = true;
    }

    public onParticipantInputChange(value: string) {
        this.participantName = value;
    }

    public onParticipantInputKeyPress(keyCode: number) {
        if (keyCode === this.escapeKeyCode) {
            this.isAdding = false;
            this.participantName = '';
        }
    }

    public onCloseClick() {
        this.isAdding = false;
        this.participantName = '';
    }

    public onAddClick() {
        this.handleAdd();
    }

    public onSubmit() {
        this.handleAdd();
    }

    private async handleAdd() {
        if (this.busy) {
            return;
        }

        this.busy = true;
        (await this.createParticipantInteractor.create(this.participantName)).fold(
            () => {
                runInAction(() => {
                    this.busy = false;
                    this.isAdding = false;
                    this.participantName = '';
                });
            },
            () => {
                runInAction(() => {
                    this.busy = false;
                });
                toast('Something went wrong while creating participant', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
    }
}
