import * as React from 'react';
import {StyledTooltip} from '../../../../components/tooltip';
import {AlertSquare} from '../../../../components/alert_square';

const pingPositiveThreshold = 300;
const pingDisconnectedThreshold = 9000;
const lastPingDiscconnectedThreshold = 10000;

enum ConnectionState {
    OK,
    DANGER,
    DISCONNECTED,
}

const determineConnectionState = (ping: number | null, lastPingReceived: number | null): ConnectionState => {
    if (ping === null || lastPingReceived === null) {
        return ConnectionState.DISCONNECTED;
    }
    if (Date.now() - lastPingDiscconnectedThreshold > lastPingReceived || ping > pingDisconnectedThreshold) {
        //If device longer then 10s offline, invalidate
        return ConnectionState.DISCONNECTED;
    }

    if (ping > pingPositiveThreshold) {
        return ConnectionState.DANGER;
    }
    if (ping <= pingPositiveThreshold) {
        return ConnectionState.OK;
    }

    return ConnectionState.DISCONNECTED;
};

const getConnectionColor = (state: ConnectionState): string => {
    switch (state) {
        case ConnectionState.OK:
            return 'rgb(0,168,98)';
        case ConnectionState.DANGER:
            return 'rgb(235,82,85)';
        case ConnectionState.DISCONNECTED:
            return 'rgb(124,124,124)';
    }
};

const getConnectionIcon = (state: ConnectionState): JSX.Element => {
    switch (state) {
        case ConnectionState.OK:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14.305" viewBox="0 0 18 14.305">
                    <g data-name="Icon material-signal-wifi-3-bar">
                        <path
                            d="M9.009 14.3l8.992-11.2a14.932 14.932 0 00-9-3.093 14.906 14.906 0 00-9 3.093l8.992 11.2.008.008z"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            d="M2.457 6.147l6.541 8.149.008.008.008-.008 6.541-8.149a10.807 10.807 0 00-6.554-2.281 10.807 10.807 0 00-6.544 2.281z"
                            fill="#fff"
                        />
                    </g>
                </svg>
            );
        case ConnectionState.DANGER:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14.303" viewBox="0 0 18 14.303">
                    <g data-name="Icon material-signal-wifi-statusbar-connected-no-internet-3">
                        <path
                            d="M17.034 4.291l.965-1.2A14.989 14.989 0 008.995 0a14.934 14.934 0 00-9 3.089l8.989 11.2.007.014.007-.007 5-6.229V4.291z"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            d="M13.982 7.817v-3.4a10.54 10.54 0 00-4.96-1.272 10.851 10.851 0 00-6.688 2.411l6.681 8.739.007.007.007-.007zm1.417 5h1.417v1.488h-1.417zm0-7.437h1.417v5.95h-1.417z"
                            fill="#fff"
                        />
                    </g>
                </svg>
            );
        case ConnectionState.DISCONNECTED:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.51" viewBox="0 0 18 15.51">
                    <path
                        data-name="Icon material-signal-wifi-off"
                        d="M18.001 4.299a14.932 14.932 0 00-9-3.093 14.277 14.277 0 00-3.209.371l7.987 7.979zm-5.1 6.356L2.251 0l-.982.99 1.585 1.592A14 14 0 00.001 4.299l8.992 11.2.008.008.008-.008 3.015-3.759 2.567 2.567.982-.982-2.675-2.675z"
                        fill="#fff"
                    />
                </svg>
            );
    }
};

const getTooltipLabel = (state: ConnectionState, ping: number | null): string => {
    switch (state) {
        case ConnectionState.OK:
            return 'Ping: ' + ping;
        case ConnectionState.DANGER:
            return 'High ping: ' + ping;
        case ConnectionState.DISCONNECTED:
            return 'Disconnected';
    }
};

interface OwnProps {
    ping: number | null;
    lastPingReceived: number | null;
}

export const ConnectionIndicator: React.FunctionComponent<OwnProps> = ({ping, lastPingReceived}) => {
    //Just update this component every second
    const [, setDate] = React.useState(0);
    React.useEffect(() => {
        const interval = setInterval(() => setDate(Date.now()), 1000);
        return () => clearInterval(interval);
    }, []);

    const connectionState = determineConnectionState(ping, lastPingReceived);
    return (
        <StyledTooltip label={getTooltipLabel(connectionState, ping)}>
            <AlertSquare style={{backgroundColor: getConnectionColor(connectionState)}}>
                {getConnectionIcon(connectionState)}
            </AlertSquare>
        </StyledTooltip>
    );
};
