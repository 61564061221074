import styled from 'styled-components';

export const Logo = styled.h1`
    background: url('/images/rp3-logo-eracing.svg') no-repeat left center transparent;
    height: 100%;
    width: 200px;
    font-size: 0;
    margin-left: 1.765rem;
    display: inline-block;
    background-size: contain;
`;
