import {BoatProgress} from '../../../../business/boats/boats_progress_provider';
import {CanvasSizes, RealtimeBoatProgress} from '../../boats_screen_presenter';
import {RealtimeBoatPositionsCalculator} from '../boats_screen_state_provider';

function metersToTopX(canvasHeightPx: number, meters: number, meterInPx: number, marginPx: number): number {
    return canvasHeightPx - meters * meterInPx - marginPx;
}

export class DefaultRealtimeBoatPositionsCalculator implements RealtimeBoatPositionsCalculator {
    public calculate(boatProgresses: BoatProgress[], canvasSizes: CanvasSizes): RealtimeBoatProgress[] {
        return boatProgresses.map((boatProgress) => {
            return {
                boat: boatProgress,
                state: {
                    top: metersToTopX(
                        canvasSizes.canvasHeightPx,
                        boatProgress.distanceMeters,
                        canvasSizes.meterInPx,
                        0,
                    ),
                    meters: boatProgress.distanceMeters,
                },
            };
        });
    }
}
