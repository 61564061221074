import {BoatUpdater} from '../boat_edit_modal_presenter';
import {Boat} from '../../../../models/boat';
import {Try} from '../../../../support/monads/try';

export interface UpdateBoatApi {
    update(boat: Boat): Promise<Try<Boat>>;
}

export class ApiBoatUpdater implements BoatUpdater {
    constructor(private updateBoatApi: UpdateBoatApi) {}

    public update(boat: Boat): Promise<Try<Boat>> {
        return this.updateBoatApi.update(boat);
    }
}
