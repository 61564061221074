import {runInAction} from 'mobx';
import {Presenter} from '../../../support/with_presenter';
import {Race} from '../../../models/race';
import {StartResult} from '../../../models/start_result';
import {Participant} from '../../../models/participant';
import {Device} from '../../../models/device';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {RaceProvider} from '../../../business/race/race_provider';
import {StartResultProvider} from '../../../business/start_result/start_result_provider';
import {ParticipantsProvider} from '../../../business/participants/participant_provider';
import {DevicesProvider} from '../../../business/devices/device_provider';

export class StatusBoxPresenter implements Presenter {
    public race: Race;
    public startResult?: StartResult;
    public participants: Participant[] = [];
    public devices: Device[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        initialRace: Race,
        private raceProvider: RaceProvider,
        private startResultProvider: StartResultProvider,
        private participantsProvider: ParticipantsProvider,
        private devicesProvider: DevicesProvider,
    ) {
        this.race = initialRace;
    }

    public mount(): void {
        this.subscriptions.add(
            this.raceProvider.get().subscribe((raceTry) => {
                raceTry.map((race) => {
                    runInAction(() => {
                        this.race = race;
                    });
                });
            }),
            this.startResultProvider.get().subscribe((startResultTry) => {
                startResultTry.map((startResult) => {
                    runInAction(() => {
                        this.startResult = startResult;
                    });
                });
            }),
            this.participantsProvider.get().subscribe((participantsTry) => {
                participantsTry.map((participants) => {
                    runInAction(() => {
                        this.participants = participants;
                    });
                });
            }),
            this.devicesProvider.get().subscribe((devicesTry) => {
                devicesTry.map((devices) => {
                    runInAction(() => {
                        this.devices = devices;
                    });
                });
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
