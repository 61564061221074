import styled from 'styled-components';

export const Heading1 = styled.h1`
    color: white;
    font-size: 3rem;
`;

export const Heading2 = styled.h2`
    font-size: 2.75rem;
    color: #fff;
`;

export const Heading3 = styled.h3`
    font-size: 2.5rem;
    line-height: 49px;
    color: #fff;
    margin: 30px 15px;
    font-weight: bold;
`;
