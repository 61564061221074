import {Observable} from 'rxjs';
import {BoatProgress, BoatsProgressProvider} from '../../../business/boats/boats_progress_provider';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {lazy} from '../../../support/lazy';

export interface Lane {
    title?: string;
    x: number;
    width: number;
    boatId?: string;
}

export interface LanesProvider {
    lanesStream(containerWidthPx: number): Observable<Lane[]>;
}

export class DefaultLanesProvider implements LanesProvider {
    private readonly maxLaneWidth = 100;

    @lazy()
    private get boatProgresses(): Observable<BoatProgress[]> {
        return this.boatsProvider.get().pipe(
            distinctUntilChanged((a, b) => a.map((boat) => boat.id).join(',') === b.map((boat) => boat.id).join(',')),
            map((boats) => {
                const sortedBoats = [...boats];
                sortedBoats.sort((a, b) => a.name.localeCompare(b.name));
                return sortedBoats;
            }),
        );
    }

    constructor(private boatsProvider: BoatsProgressProvider, private minNumOfLanes: number) {}

    public lanesStream(containerWidthPx: number): Observable<Lane[]> {
        return this.boatProgresses.pipe(
            map<BoatProgress[], Lane[]>((boats) => {
                const numOfLanes = Math.max(this.minNumOfLanes, boats.length);
                const laneWidth = this.calculateLaneWidth(numOfLanes, containerWidthPx);

                //Do the actual water padding calculation based on the floored laneWidth, this will result in the lanes
                //And padding to always be a round number, else the lanes will have gradient artifacts since they will
                //Either have a little bit of space between them, or overlap ever so slightly
                const waterPadding = containerWidthPx - laneWidth * numOfLanes;

                const laneXPositions = Array.from({length: numOfLanes}, (_, i) =>
                    Math.round(i * laneWidth + waterPadding / 2),
                );

                return laneXPositions.reduce<Lane[]>((acc, laneXPosition, index) => {
                    const boat = boats[index - laneXPositions.length + boats.length];
                    const lane: Lane = {
                        title: boat ? boat.name : undefined,
                        boatId: boat ? boat.id : undefined,
                        width: laneWidth,
                        x: laneXPosition,
                    };
                    return [...acc, lane];
                }, []);
            }),
        );
    }

    private calculateInitialWaterPadding(numOfLanes: number, containerWidth: number) {
        const minPadding = containerWidth - Math.floor(containerWidth / numOfLanes) * numOfLanes;
        const lanePercentageWidth = Math.floor(containerWidth - (containerWidth / 100) * 9.25 * numOfLanes);

        return Math.max(minPadding, lanePercentageWidth, 0);
    }

    private calculateLaneWidth(numOfLanes: number, containerWidth: number) {
        const laneWidth = Math.floor(
            (containerWidth - this.calculateInitialWaterPadding(numOfLanes, containerWidth)) / numOfLanes,
        );
        return laneWidth > this.maxLaneWidth ? this.maxLaneWidth : laneWidth;
    }
}
