import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Row} from '../../components/row';
import {Col} from '../../components/col';
import {NavigationBar} from './navigation_bar';

const Wrapper = styled.header`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0;
    width: 100%;
    min-height: fit-content;
    overflow-x: hidden;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: rgba(0, 0, 0, 0.1);
    }
`;

interface OwnProps {}

export enum RaceState {
    IDLE = 'idle',
    INITIATING_START = 'initiating_start',
    RACING = 'racing',
    ABORTED = 'aborted',
}

export const Header: FunctionComponent<OwnProps> = ({children}) => {
    return (
        <Wrapper>
            <NavigationBar />
            <Row>
                <Col numCols={12}>{children}</Col>
            </Row>
        </Wrapper>
    );
};
