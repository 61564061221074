import styled, {css} from 'styled-components';
import {Link} from '@reach/router';
import {baseButtonStyles} from '../../../ui/components/button';

interface OwnProps {
    disabled: boolean;
    color?: 'green' | 'gray' | 'blue' | 'darkBlue';
}

const colors = {
    green: '#00a862',
    greenActive: '#099359',
    gray: '#808080',
    grayActive: '#707070',
    blue: '#00c9ff',
    blueActive: '#00c0f4',
    darkBlue: '#193f49',
    darkBlueActive: '#215968',
};

export const LargeButton = styled(Link)`
    ${baseButtonStyles}
    font-size: 1.5rem;
    width: 100%;
    height: 3.5rem;
    padding: 0;
    margin: 0.5rem 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    color: #ffffff;
    background-color: ${(props: OwnProps) => colors[props.color || 'green']};
    outline: none !important;
    &:hover,
    &:active,
    &:focus {
        background-color: ${(props: OwnProps) => colors[((props.color || 'green') + 'Active') as keyof typeof colors]};
    }
    &:focus {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
    }
    span {
        color: #ffffff;
    }

    ${(props: OwnProps) =>
        props.disabled &&
        css`
            background-color: gray;
            cursor: not-allowed;
            &:hover {
                text-decoration: none;
            }
        `}
`;
