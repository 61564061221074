import {Presenter} from '../../support/with_presenter';
import {runInAction} from 'mobx';
import {BoatProgress, BoatsProgressProvider} from '../../business/boats/boats_progress_provider';
import {Lane, LanesProvider} from './internal/lanes_provider';
import {CompositeSubscription} from '../../support/composite_subscription';
import {ViewPortSizeProvider} from './support/viewport_sizes';
import {debounceThrottleAnimationFrame} from '../../support/rx_debounce_throttle';
import {BoatsScreenComponent} from './boats_screen_component';
import {Observable} from 'rxjs';

export interface CanvasSizes {
    imageSizeMultiplier: number;
    finishLineBlockSizePx: number;
    meterInPx: number;
    canvasWidthPx: number;
    canvasHeightPx: number;
}

export interface RealtimeBoatProgress {
    boat: BoatProgress;
    state: {
        top: number;
        meters: number;
    };
}

export interface RealtimeBoatsScreenState {
    boatProgresses: RealtimeBoatProgress[];
    cameraOffset: number;
    canvasSizes: CanvasSizes;
}

export interface RealtimeBoatsScreenStateProvider {
    stream(): Observable<RealtimeBoatsScreenState>;
}

export class BoatsScreenPresenter implements Presenter {
    public lanes: readonly Lane[] | null = null;
    public boats: readonly BoatProgress[] | null = null;
    public realtimeBoatProgresses: readonly RealtimeBoatProgress[] | null = null;
    public distanceMeters = 500;

    public canvasSizes: CanvasSizes | null = null;
    public cameraOffset: number | null = null;

    private _subscriptions = new CompositeSubscription();

    constructor(
        distanceMeters: number,
        private viewPortSizeProvider: ViewPortSizeProvider,
        private boatsProvider: BoatsProgressProvider,
        private lanesProvider: LanesProvider,
        public boatsScreenComponent: BoatsScreenComponent,
        private realtimeBoatsScreenStateProvider: RealtimeBoatsScreenStateProvider,
    ) {
        this.distanceMeters = distanceMeters;
    }

    public mount(): void {
        this._subscriptions.add(
            debounceThrottleAnimationFrame(this.boatsProvider.get(), 250).subscribe((boats) => {
                runInAction(() => {
                    this.boats = boats;
                });
            }),
        );

        this._subscriptions.add(
            this.boatsScreenComponent.collectedSplitTimesProvider.splitTimes.subscribe(() => {
                /* Noop, just make sure we're following the lifecycle of the boats screen */
            }),
        );

        this._subscriptions.add(
            this.lanesProvider.lanesStream(this.viewPortSizeProvider.canvasWidthPx).subscribe((lanes) => {
                runInAction(() => {
                    this.lanes = lanes;
                });
            }),
        );
        this._subscriptions.add(
            this.realtimeBoatsScreenStateProvider.stream().subscribe((realtimeBoatsScreenState) => {
                runInAction(() => {
                    this.canvasSizes = realtimeBoatsScreenState.canvasSizes;
                    this.cameraOffset = realtimeBoatsScreenState.cameraOffset;
                    this.realtimeBoatProgresses = realtimeBoatsScreenState.boatProgresses;
                });
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
