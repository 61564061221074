import * as React from 'react';
import {FunctionComponent} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import {DraggableType, DROPPABLE_BOAT_PREFIX, toDroppablePrefix} from '../../boats_grid_drag_handler_presenter';
import {BlockItem} from '../../components/block_item';
import {Circle} from './circle';
import {CombinationWrapper} from './combination_wrapper';
import {Boat} from '../../../../../models/boat';

interface OwnProps {
    boat: Boat;
    index: number;
}

export const PlaceholderRow: FunctionComponent<OwnProps> = ({index, boat}) => {
    return (
        <CombinationWrapper>
            <Circle>{index}</Circle>
            <Droppable
                droppableId={toDroppablePrefix(DROPPABLE_BOAT_PREFIX, '' + index) + boat.id}
                type={DraggableType.Participant}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <BlockItem inactive={!snapshot.isDraggingOver}>Participant</BlockItem>
                        <div style={{display: 'none'}}>{provided.placeholder}</div>
                    </div>
                )}
            </Droppable>
            <BlockItem inactive>Device</BlockItem>
        </CombinationWrapper>
    );
};
