import {CombiningParticipantsProvider, ParticipantsProvider} from './participant_provider';
import {Race} from '../../models/race';
import {ParticipantsRepositoryFactory} from '../../persistence/participants_repository_factory';
import {ApiParticipantProvider} from './api_participant_provider';
import {SocketParticipantProvider} from './socket_participant_provider';
import {ParticipantApi} from '../../network/participant_api';
import {ParticipantSocket} from '../../network/participant_socket';
import {LocalStorageParticipantProvider} from './local_storage_participant_provider';

export class ParticipantsProviderFactory {
    private repositories = new Map<string, ParticipantsProvider>();

    constructor(
        private participantApi: ParticipantApi,
        private participantSocket: ParticipantSocket,
        private participantsRepositoryFactory: ParticipantsRepositoryFactory,
    ) {}

    public create(race: Race): ParticipantsProvider {
        if (!this.repositories.has(race.id)) {
            const participantsRepository = this.participantsRepositoryFactory.create(race);
            this.repositories.set(
                race.id,
                new CombiningParticipantsProvider([
                    new LocalStorageParticipantProvider(participantsRepository),
                    new ApiParticipantProvider(race.id, this.participantApi, participantsRepository),
                    new SocketParticipantProvider(race.id, this.participantSocket, participantsRepository),
                ]),
            );
        }
        return this.repositories.get(race.id)!;
    }
}
