/**
 * Inspired by https://github.com/arrow-kt/arrow/blob/master/modules/core/arrow-core-data/src/main/kotlin/arrow/core/Option.kt
 */
export abstract class Option<T> {
    abstract isEmpty(): boolean;

    public static some<TStatic>(value: TStatic): Option<TStatic> {
        return new Some<TStatic>(value);
    }

    public static none<TStatic>(): Option<TStatic> {
        return new None<TStatic>();
    }

    public abstract fold<B>(onValue: (value: T) => B, onEmpty: () => B): B;
}

class Some<T> extends Option<T> {
    constructor(private value: T) {
        super();
    }

    public isEmpty(): boolean {
        return false;
    }

    public fold<B>(onValue: (value: T) => B, onEmpty: () => B): B {
        return onValue(this.value);
    }
}

class None<T> extends Option<T> {
    public isEmpty(): boolean {
        return true;
    }

    public fold<B>(onValue: (value: T) => B, onEmpty: () => B): B {
        return onEmpty();
    }
}
