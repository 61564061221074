import styled, {css} from 'styled-components';

export const baseButtonStyles = css`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #00c9ff;
    color: #ffffff;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    border: none;
    padding: 0;
    width: 300px;

    &:hover {
        color: white;
        text-decoration: none;
        background: #00c0f4;
        border-color: #00c0f4;
    }
    &:focus {
        background: #00c0f4;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5);
    }
    span[class^='ion-'] {
        width: 18px;
        font-size: 20px;
        margin: 0 5px;
    }
`;

export const smallButtonStyles = css`
    padding: 0.5em 2em;
    font-size: 15px;
    border: solid 2px #00c9ff;
    &.btn-secondary {
        color: #00c9ff;
        background: #fff;
        &:hover {
            color: #00c0f4;
        }
    }
`;

export const Button = styled.button`
    ${baseButtonStyles}
`;

export const SmallButton = styled.button`
    ${baseButtonStyles}
    ${smallButtonStyles}
`;
