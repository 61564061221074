import {ServerTime, ServerTimeInteractor} from '../server_time_interactor';
import {Observable, merge, interval, concat} from 'rxjs';
import {flatMap} from 'rxjs/operators';

export class BurstServerTimeInteractor implements ServerTimeInteractor {
    private _burstN = 0;

    constructor(
        private serverTimeInteractor: ServerTimeInteractor,
        private initialBurstSize: number,
        private burstSize: number,
        private burstIntervalMs: number,
    ) {}

    public serverTimes(): Observable<ServerTime> {
        return merge(this.burst(), interval(this.burstIntervalMs).pipe(flatMap(() => this.burst())));
    }

    private burst(): Observable<ServerTime> {
        this._burstN++;
        return concat(...this.range().map(() => this.serverTimeInteractor.serverTimes()));
    }

    private range(): number[] {
        return Array.from({length: this.getBurstSize()}, (_, i) => i + 1);
    }

    private getBurstSize() {
        if (this._burstN <= 1) {
            return this.initialBurstSize;
        }
        return this.burstSize;
    }
}
