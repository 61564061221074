import * as React from 'react';
import {FunctionComponent} from 'react';
import {BoatsScreenPresenter} from './boats_screen_presenter';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {observer} from 'mobx-react-lite';
import {Layer, Rect, Stage} from 'react-konva';
import {Lanes} from './components/lanes';
import {round} from './support/round';
import {WaterContent} from './components/water_content';
import {ViewPortSizeProvider} from './support/viewport_sizes';
import {Race} from '../../models/race';
import {DefaultBoatsScreenComponent} from './boats_screen_component';
import {Overlays} from './overlays/overlays';
import {BuoyLanes} from './components/buoy_lanes';
import {LaneNumbers} from './components/lane_numbers';
import {BigBuoys} from './components/big_buoys';
import {AnimatingBoats} from './components/animating_boats';

interface OwnProps {
    race: Race;
    viewPortSizeProvider: ViewPortSizeProvider;
}

const BoatsScreenComponent: FunctionComponent<OwnProps & PresenterProps<BoatsScreenPresenter>> = observer(
    ({presenter, race, children}) => {
        const {lanes, boats, realtimeBoatProgresses, distanceMeters, canvasSizes, cameraOffset} = presenter;

        if (
            lanes === null ||
            boats === null ||
            realtimeBoatProgresses === null ||
            canvasSizes === null ||
            cameraOffset === null
        ) {
            return null;
        }

        return (
            <Stage width={canvasSizes.canvasWidthPx} height={canvasSizes.canvasHeightPx}>
                <Layer listening={false}>
                    <Rect width={canvasSizes.canvasWidthPx} height={canvasSizes.canvasHeightPx} fill="#069CC1" />
                    <Lanes lanes={lanes} height={canvasSizes.canvasHeightPx} width={canvasSizes.canvasWidthPx} />
                    <BuoyLanes
                        viewportOffsetPx={round(cameraOffset, 2)}
                        meterInPx={round(canvasSizes.meterInPx, 2)}
                        lanes={lanes}
                        distanceMeters={distanceMeters}
                        canvasHeight={canvasSizes.canvasHeightPx}
                        canvasWidth={canvasSizes.canvasWidthPx}
                    />
                    <LaneNumbers
                        lanes={lanes}
                        viewPortOffsetPx={round(cameraOffset, 2)}
                        canvasHeight={canvasSizes.canvasHeightPx}
                    />
                    <BigBuoys
                        key="big-buoys"
                        meterInPx={round(canvasSizes.meterInPx, 2)}
                        viewportOffsetPx={round(cameraOffset, 2)}
                        distanceMeters={distanceMeters}
                        canvasWidth={canvasSizes.canvasWidthPx}
                        canvasHeight={canvasSizes.canvasHeightPx}
                    />
                    <WaterContent
                        viewportOffsetPx={round(cameraOffset, 2)}
                        meterInPx={round(canvasSizes.meterInPx, 2)}
                        distanceMeters={distanceMeters}
                        finishLineBlockSizePx={canvasSizes.finishLineBlockSizePx}
                        canvasWidth={canvasSizes.canvasWidthPx}
                        canvasHeight={canvasSizes.canvasHeightPx}
                        boatsScreenComponent={presenter.boatsScreenComponent}
                    />

                    <AnimatingBoats
                        viewportOffsetPx={round(cameraOffset, 2)}
                        realtimeBoatProgresses={realtimeBoatProgresses}
                        lanes={lanes}
                        distanceMeters={distanceMeters}
                        meterInPx={canvasSizes.meterInPx}
                        canvasHeightPx={canvasSizes.canvasHeightPx}
                        finishLineHeightPx={canvasSizes.finishLineBlockSizePx * 2}
                        imageSizeMultiplier={canvasSizes.imageSizeMultiplier}
                    />
                </Layer>
                <Overlays
                    race={race}
                    lanes={lanes}
                    boatScreenComponent={presenter.boatsScreenComponent}
                    canvasWidth={canvasSizes.canvasWidthPx}
                    canvasHeight={canvasSizes.canvasHeightPx}
                />
                {children}
            </Stage>
        );
    },
);

export const BoatsScreen = withPresenter<BoatsScreenPresenter, OwnProps>((props, component) => {
    const boatsScreenComponent = new DefaultBoatsScreenComponent(props.race, props.viewPortSizeProvider, component);

    return new BoatsScreenPresenter(
        props.race.distanceMeters,
        props.viewPortSizeProvider,
        boatsScreenComponent.boatsProgressProvider,
        boatsScreenComponent.lanesProvider,
        boatsScreenComponent,
        boatsScreenComponent.realtimeBoatsScreenStateProvider,
    );
}, BoatsScreenComponent);
