import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../models/race';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {Row} from '../../../components/row';
import {SidebarCol} from '../../components/sidebar_col';
import {Heading3} from '../../components/heading';
import {ContentCol} from '../../components/content_col';
import {Container} from '../../components/container';
import {SelectDistanceFormPresenter} from '../select_distance_form_presenter';
import {DistanceBlock} from '../components/distance_block';
import {ApiRaceDistanceUpdater} from './internal/race_distance_updater';
import {Footer} from '../../components/footer';
import {NextScreenButton} from '../../components/buttons';
import {Content} from '../../components/content';
import {DistanceBlockForm} from './components/distance_block_form';
import {isAuthorized} from '../../../business/permissions/is_authorized';
import {Permission} from '../../../enumerations/permission';
import {NavigateFn} from '@reach/router';
import styled from 'styled-components';

const BlockWrapper = styled.div`
    float: left;
    display: block;
    width: 240px;
    height: 240px;
    margin: 10px;
`;

interface OwnProps {
    navigate: NavigateFn;
    race: Race;
}

const SelectDistanceFormComponent: FunctionComponent<OwnProps & PresenterProps<SelectDistanceFormPresenter>> = observer(
    ({presenter, race}) => {
        const distanceMetersPresets = [200, 500, 2000, 5000];
        const raceHasCustomDistance =
            distanceMetersPresets.some((distance) => distance === race.distanceMeters) === false &&
            race.distanceMeters > 0;

        const canChange = isAuthorized(Permission.EDIT_RACE, race);

        return (
            <>
                <Content>
                    <Container>
                        <Row className="h-100">
                            <SidebarCol numCols={3} className="d-none d-lg-block">
                                <Heading3>Select race distance</Heading3>
                                <p>
                                    Select the distance you want to use for this race. All connected devices will setup
                                    this distance.
                                </p>
                            </SidebarCol>
                            <ContentCol className="col-12 col-lg-9">
                                <BlockWrapper>
                                    <DistanceBlockForm
                                        disabled={!canChange}
                                        initialDistance={raceHasCustomDistance ? race.distanceMeters : undefined}
                                        distanceMeters={race.distanceMeters}
                                        onChange={(distanceMeters) =>
                                            canChange && presenter.onDistanceClick(distanceMeters)
                                        }
                                    />
                                </BlockWrapper>
                                {distanceMetersPresets.map((distanceMeters) => (
                                    <BlockWrapper key={distanceMeters}>
                                        <DistanceBlock
                                            disabled={!canChange}
                                            distanceMeters={distanceMeters}
                                            active={race.distanceMeters === distanceMeters}
                                            onClick={() => {
                                                if (!presenter.busy && canChange) {
                                                    presenter.onDistanceClick(distanceMeters);
                                                }
                                            }}
                                        />
                                    </BlockWrapper>
                                ))}
                            </ContentCol>
                        </Row>
                    </Container>
                </Content>
                <Footer>
                    <div />
                    <NextScreenButton to={`/${race.id}/boats`} state={race}>
                        Next step
                        <span className="ion-ios-arrow-forward" />
                    </NextScreenButton>
                </Footer>
            </>
        );
    },
);

export const SelectDistanceForm = withPresenter<SelectDistanceFormPresenter, OwnProps>(
    (props, component) =>
        new SelectDistanceFormPresenter(
            props.race.id,
            props.navigate,
            new ApiRaceDistanceUpdater(
                props.race.id,
                component.raceRepositoryFactory.create(props.race),
                component.networkComponent.raceApi,
            ),
        ),
    SelectDistanceFormComponent,
);
