import * as React from 'react';
import {FunctionComponent} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';

interface OwnProps {
    string: string;
}

const Button = styled.button`
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    font-weight: inherit;
`;

export const CopyButton: FunctionComponent<OwnProps> = ({children, string}) => {
    return (
        <Button
            onClick={() => {
                navigator.clipboard.writeText(string);
                toast('Copied to clipboard', {
                    type: toast.TYPE.INFO,
                });
            }}>
            {children}
        </Button>
    );
};
