import * as React from 'react';
import {Group} from 'react-konva';
import {FinishLine} from './finish_line';
import {yPositionIsInViewport} from '../calculators/position_in_viewport_calculator';
import {StartLine} from './start_line';
import {Countdown} from './countdown';
import {BoatsScreenComponent} from '../boats_screen_component';
import {SplitLines} from './split_lines';

interface OwnProps {
    viewportOffsetPx: number;
    meterInPx: number;
    distanceMeters: number;
    canvasWidth: number;
    canvasHeight: number;
    finishLineBlockSizePx: number;
    boatsScreenComponent: BoatsScreenComponent;
}

export const WaterContent: React.FunctionComponent<OwnProps> = ({
    viewportOffsetPx,
    meterInPx,
    distanceMeters,
    canvasHeight,
    canvasWidth,
    finishLineBlockSizePx,
    boatsScreenComponent,
}) => {
    const finishLineY = canvasHeight - distanceMeters * meterInPx;
    const displayFinishLine = yPositionIsInViewport(finishLineY, canvasHeight, viewportOffsetPx);
    const startLineY = canvasHeight;
    const displayStartLine = yPositionIsInViewport(startLineY, canvasHeight, viewportOffsetPx);

    return (
        <Group y={viewportOffsetPx}>
            <SplitLines
                height={finishLineBlockSizePx}
                viewportOffsetPx={viewportOffsetPx}
                meterInPx={meterInPx}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                distanceMeters={distanceMeters}
            />
            {displayStartLine && (
                <Countdown canvasWidth={canvasWidth} y={startLineY} boatsScreenComponent={boatsScreenComponent} />
            )}
            {displayStartLine && <StartLine canvasWidth={canvasWidth} height={finishLineBlockSizePx} y={startLineY} />}
            {displayFinishLine && (
                <FinishLine viewportWidth={canvasWidth} blockSizePx={finishLineBlockSizePx} y={finishLineY} />
            )}
        </Group>
    );
};
