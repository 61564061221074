import {Race} from '../../models/race';
import {BoatsRepositoryFactory} from '../../persistence/boats_repository_factory';
import {BoatsProvider, CombiningBoatsProvider} from './boats_provider';
import {BoatsApi} from '../../network/boats_api';
import {ApiBoatsProvider} from './api_boats_provider';
import {BoatsForRaceSocket, SocketBoatProvider} from './socket_boats_provider';
import {LocalStorageBoatsProvider} from './local_storage_boats_provider';

export class BoatsProviderFactory {
    private repositories = new Map<string, BoatsProvider>();

    constructor(
        private boatApi: BoatsApi,
        private boatsForRaceSocket: BoatsForRaceSocket,
        private boatsRepositoryFactory: BoatsRepositoryFactory,
    ) {}

    public create(race: Race): BoatsProvider {
        if (!this.repositories.has(race.id)) {
            const boatsRepository = this.boatsRepositoryFactory.create(race);
            this.repositories.set(
                race.id,
                new CombiningBoatsProvider([
                    new LocalStorageBoatsProvider(boatsRepository),
                    new ApiBoatsProvider(race.id, this.boatApi, boatsRepository),
                    new SocketBoatProvider(race.id, this.boatsForRaceSocket, boatsRepository),
                ]),
            );
        }
        return this.repositories.get(race.id)!;
    }
}
