import {CombingDevicesProvider, DevicesProvider} from './device_provider';
import {Race} from '../../models/race';
import {DevicesRepositoryFactory} from '../../persistence/devices_repository_factory';
import {ApiDeviceProvider, DevicesForRaceApi} from './api_device_provider';
import {DevicesForRaceSocket, SocketDeviceProvider} from './socket_device_provider';

export class DevicesProviderFactory {
    private repositories = new Map<string, DevicesProvider>();

    constructor(
        private devicesForRaceApi: DevicesForRaceApi,
        private devicesForRaceSocket: DevicesForRaceSocket,
        private devicesRepositoryFactory: DevicesRepositoryFactory,
    ) {}

    public create(race: Race): DevicesProvider {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(
                race.id,
                new CombingDevicesProvider([
                    new ApiDeviceProvider(race.id, this.devicesForRaceApi, this.devicesRepositoryFactory.create(race)),
                    new SocketDeviceProvider(
                        race.id,
                        this.devicesForRaceSocket,
                        this.devicesRepositoryFactory.create(race),
                    ),
                ]),
            );
        }
        return this.repositories.get(race.id)!;
    }
}
