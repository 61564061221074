import styled from 'styled-components';
import {FunctionComponent} from 'react';
import * as React from 'react';
import {CopyButton} from './copy_button';
import {Race} from '../../models/race';

const NavElement = styled.nav`
    min-height: 3rem;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid white;
    background: white;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
`;

const LogoElement = styled.div`
    background: url('/images/rp3-logo-eracing.svg') no-repeat left center transparent;
    height: 3rem;
    max-width: 11.7rem;
    font-size: 0;
    margin: 0 0 0 1.76rem;
    justify-content: start;
    display: flex;
    background-size: contain;
`;

const RoomCode = styled.span`
    color: #7c7c7c;
    font-size: 0.75rem;
    line-height: 3rem;
    text-align: right;
    display: inline-flex;
    font-weight: bold;
    padding: 0 1.76rem 0 0;
`;

interface OwnProps {
    race: Race;
}

export const Nav: FunctionComponent<OwnProps> = ({race}) => {
    return (
        <NavElement>
            <div className="row">
                <div className="col-6">
                    <LogoElement />
                </div>
                <div className="col-6 text-right">
                    <RoomCode>
                        <CopyButton string={race.token}>{race.token}</CopyButton>
                    </RoomCode>
                </div>
            </div>
        </NavElement>
    );
};
