import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {BoatsScreenComponent} from '../../../boats_screen_component';
import {SplitTimesPresenter} from './split_times_presenter';
import {formatTimeMs} from '../../../../../support/format_time';
import {StatisticsTable} from '../components/statistics_table';

interface OwnProps {
    x: number;
    y: number;
    boatScreenComponent: BoatsScreenComponent;
}

const SplitTimesComponent: FunctionComponent<OwnProps & PresenterProps<SplitTimesPresenter>> = observer(
    ({x, y, presenter}) => {
        return (
            <StatisticsTable
                hightlightFirst
                x={x}
                y={y}
                title="Split times"
                rows={presenter.splitTimes.map((splitTime) => {
                    return {
                        name: splitTime.boatName,
                        formattedMetric: formatTimeMs(splitTime.splitTimeMillis),
                    };
                })}
            />
        );
    },
);

export const SplitTimes = withPresenter<SplitTimesPresenter, OwnProps>(
    (props, component) =>
        new SplitTimesPresenter(
            props.boatScreenComponent.collectedSplitTimesProvider,
            props.boatScreenComponent.boatsProvider,
        ),
    SplitTimesComponent,
);
