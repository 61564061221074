import {Presenter} from '../../../support/with_presenter';
import {runInAction} from 'mobx';
import {Try} from '../../../support/monads/try';
import {toast} from 'react-toastify';
import {Boat} from '../../../models/boat';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {BoatsProvider} from '../../../business/boats/boats_provider';
import {debounceThrottleAnimationFrame} from '../../../support/rx_debounce_throttle';

export interface CreateBoatInteractor {
    create(): Promise<Try<Boat>>;
}

export class BoatsGridPresenter implements Presenter {
    public busy: boolean = false;

    public boats: Boat[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private boatsProvider: BoatsProvider, private createBoatInteractor: CreateBoatInteractor) {}

    public mount(): void {
        this.subscriptions.add(
            debounceThrottleAnimationFrame(this.boatsProvider.get()).subscribe((boatsTry) => {
                boatsTry.fold(
                    (boats) => {
                        runInAction(() => {
                            this.boats = boats;
                        });
                    },
                    () => {
                        toast('Something went wrong while loading boats', {
                            type: toast.TYPE.ERROR,
                        });
                    },
                );
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public async onAddBoatClick() {
        this.busy = true;

        (await this.createBoatInteractor.create()).fold(
            () => {
                runInAction(() => {
                    this.busy = false;
                });
            },
            () => {
                runInAction(() => {
                    this.busy = false;
                });
                toast('Something went wrong while adding boat', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
    }
}
