import {DefaultNetworkComponent, NetworkComponent} from './network/network_component';
import {lazy} from './support/lazy';
import {RaceRepositoryFactory} from './persistence/race_repository_factory';
import {ParticipantsRepositoryFactory} from './persistence/participants_repository_factory';
import {BoatsRepositoryFactory} from './persistence/boats_repository_factory';
import {ParticipantsProviderFactory} from './business/participants/participant_provider_factory';
import {DevicesProviderFactory} from './business/devices/device_provider_factory';
import {DevicesRepositoryFactory} from './persistence/devices_repository_factory';
import {RaceProviderFactory} from './business/race/race_provider_factory';
import {BoatsProviderFactory} from './business/boats/boats_provider_factory';
import {ServerTimeComponent, DefaultServerTimeComponent} from './business/countdown/server_time_component';
import {StartResultProviderFactory} from './business/start_result/start_result_provider_factory';
import {StartResultRepositoryFactory} from './persistence/start_result_repository_factory';

export interface ClientComponent {
    raceRepositoryFactory: RaceRepositoryFactory;
    raceProviderFactory: RaceProviderFactory;
    startResultRepositoryFactory: StartResultRepositoryFactory;
    startResultProviderFactory: StartResultProviderFactory;
    participantsRepositoryFactory: ParticipantsRepositoryFactory;
    devicesRepositoryFactory: DevicesRepositoryFactory;
    boatsRepositoryFactory: BoatsRepositoryFactory;
    boatsProviderFactory: BoatsProviderFactory;
    participantsProviderFactory: ParticipantsProviderFactory;
    devicesProviderFactory: DevicesProviderFactory;
    networkComponent: NetworkComponent;
    serverTimeComponent: ServerTimeComponent;
}

class DefeaultClientComponent implements ClientComponent {
    private endpoint = '/graphql';

    @lazy()
    public get networkComponent(): NetworkComponent {
        return new DefaultNetworkComponent(this.endpoint);
    }

    @lazy()
    public get serverTimeComponent(): ServerTimeComponent {
        return new DefaultServerTimeComponent(this.networkComponent.timeApi);
    }

    @lazy()
    public get raceRepositoryFactory(): RaceRepositoryFactory {
        return new RaceRepositoryFactory();
    }

    @lazy()
    public get raceProviderFactory(): RaceProviderFactory {
        return new RaceProviderFactory(this.networkComponent.raceSocket, this.raceRepositoryFactory);
    }

    @lazy()
    public get startResultRepositoryFactory(): StartResultRepositoryFactory {
        return new StartResultRepositoryFactory();
    }

    @lazy()
    public get startResultProviderFactory(): StartResultProviderFactory {
        return new StartResultProviderFactory(
            this.networkComponent.startResultSocket,
            this.startResultRepositoryFactory,
        );
    }

    @lazy()
    public get participantsRepositoryFactory(): ParticipantsRepositoryFactory {
        return new ParticipantsRepositoryFactory();
    }

    @lazy()
    public get devicesRepositoryFactory(): DevicesRepositoryFactory {
        return new DevicesRepositoryFactory();
    }

    @lazy()
    public get boatsRepositoryFactory(): BoatsRepositoryFactory {
        return new BoatsRepositoryFactory();
    }

    @lazy()
    public get boatsProviderFactory(): BoatsProviderFactory {
        return new BoatsProviderFactory(
            this.networkComponent.boatsApi,
            this.networkComponent.boatSocket,
            this.boatsRepositoryFactory,
        );
    }

    @lazy()
    public get participantsProviderFactory(): ParticipantsProviderFactory {
        return new ParticipantsProviderFactory(
            this.networkComponent.participantApi,
            this.networkComponent.participantSocket,
            this.participantsRepositoryFactory,
        );
    }

    @lazy()
    public get devicesProviderFactory(): DevicesProviderFactory {
        return new DevicesProviderFactory(
            this.networkComponent.deviceApi,
            this.networkComponent.deviceSocket,
            this.devicesRepositoryFactory,
        );
    }
}

let instance: ClientComponent;

export function component(): ClientComponent {
    if (instance === undefined) {
        instance = new DefeaultClientComponent();
    }

    return instance;
}
