import {Race} from '../../models/race';
import {StartResultProvider, SocketStartResultProvider} from './start_result_provider';
import {StartResultSocket} from '../../network/start_result_socket';
import {StartResultRepositoryFactory} from '../../persistence/start_result_repository_factory';

export class StartResultProviderFactory {
    private repositories = new Map<string, StartResultProvider>();

    constructor(
        private startResultSocket: StartResultSocket,
        private startResultRepositoryFactory: StartResultRepositoryFactory,
    ) {}

    public create(race: Race): StartResultProvider {
        if (!this.repositories.has(race.id)) {
            this.repositories.set(
                race.id,
                new SocketStartResultProvider(
                    race.id,
                    this.startResultSocket,
                    this.startResultRepositoryFactory.create(race),
                ),
            );
        }
        return this.repositories.get(race.id)!;
    }
}
