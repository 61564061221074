import {fromEvent, Observable} from 'rxjs';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {StartResult} from '../models/start_result';
import {StartResultForRaceIdSocket} from '../business/start_result/start_result_provider';

export interface StartResultSocket extends StartResultForRaceIdSocket {}

export class DefaultStartResultSocket implements StartResultSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<StartResult> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/start-result`);
    }
}
