import {Presenter} from '../../../../support/with_presenter';
import {Device} from '../../../../models/device';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {toast} from 'react-toastify';
import {Observable} from 'rxjs';
import {Try} from '../../../../support/monads/try';
import {runInAction} from 'mobx';

export interface DevicesWithoutParticipantProvider {
    get(): Observable<Try<Device[]>>;
}

export class DevicesBlockPresenter implements Presenter {
    public devices: Device[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private devicesWithoutParticipantProvider: DevicesWithoutParticipantProvider) {}

    public mount(): void {
        this.subscriptions.add(
            this.devicesWithoutParticipantProvider.get().subscribe((devicesTry) => {
                devicesTry.fold(
                    (devices) => {
                        runInAction(() => {
                            this.devices = devices;
                        });
                    },
                    () => {
                        toast('Something went wrong while loading devices', {
                            type: toast.TYPE.ERROR,
                        });
                    },
                );
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
