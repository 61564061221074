import {RaceAborter} from '../race_control_button_presenter';
import {Race} from '../../../../models/race';
import {Try} from '../../../../support/monads/try';

export interface AbortRaceApi {
    abort(raceId: string): Promise<Try<Race>>;
}

export interface UpdateRaceRepository {
    update(race: Race): void;
}

export class ApiRaceAborter implements RaceAborter {
    constructor(
        private raceId: string,
        private abortRaceApi: AbortRaceApi,
        private updateRaceRepository: UpdateRaceRepository,
    ) {}

    public async abort(): Promise<Try<Race>> {
        return (await this.abortRaceApi.abort(this.raceId)).map((race) => {
            this.updateRaceRepository.update(race);
            return race;
        });
    }
}
