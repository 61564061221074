import {fromEvent, Observable} from 'rxjs';
import {SocketIoClientProvider} from './driver/socket_io_client_provider';
import {BoatProgressForRaceSocket, BoatProgressStatusUpdate} from '../business/boats/boats_progress_provider';

export interface BoatsProgressSocket extends BoatProgressForRaceSocket {}

export class DefaultBoatsProgressSocket implements BoatsProgressSocket {
    constructor(private socketIoClientProvider: SocketIoClientProvider) {}

    public getForRaceId(raceId: string): Observable<BoatProgressStatusUpdate> {
        return fromEvent(this.socketIoClientProvider.get(), `/race/${raceId}/boat-progress`);
    }
}
