import styled from 'styled-components';

interface BlockProps {
    active: boolean;
    disabled: boolean;
}

export const Block = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    margin: auto auto 1.75rem auto;
    background-color: #ffffff;
    padding: 0.5rem;
    color: #5f5f5f;

    padding-top: 100%;
    margin: auto auto 1.75rem auto;
    background-color: ${(props: BlockProps) => {
        if (props.disabled) {
            return props.active ? '#7c7c7c' : '#ffffff';
        }
        return props.active ? '#00c9ff' : '#ffffff';
    }};
    color: ${(props: BlockProps) => (props.active ? '#ffffff' : '#5f5f5f')};
    cursor: ${(props: BlockProps) => (props.disabled ? 'inherit' : 'pointer')};
`;

interface BlockCircleProps {
    active: boolean;
    disabled: boolean;
}

export const BlockCircle = styled.span`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 75%;
    height: 75%;
    line-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 4px solid;
    border-color: ${(props: BlockCircleProps) => {
        if (props.disabled) {
            return props.active ? '#ffffff' : '#f0f0f0';
        }
        return props.active ? '#ffffff' : '#00c9ff';
    }};
    border-radius: 100%;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${(props: BlockCircleProps) => (props.active ? '#ffffff' : '#5f5f5f')};
`;
