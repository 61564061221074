import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../models/race';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {BoatsGridPresenter} from './boats_grid_presenter';
import {Container} from '../../components/container';
import {Row} from '../../../components/row';
import {SidebarCol} from '../../components/sidebar_col';
import {Heading3} from '../../components/heading';
import {ContentCol} from '../../components/content_col';
import {Footer} from '../../components/footer';
import {PreviousScreenButton} from '../../components/buttons';
import {Col} from '../../../components/col';
import {ButtonBlock} from './components/button_block';
import {ApiCreateBoatInteractor} from './internal/create_boat_interactor';
import {DefaultNextBoatNameProvider} from './internal/next_boat_name_provider';
import {BoatBlock} from './boat_block/boat_block';
import {ParticipantsBlock} from './participants_block/participants_block';
import {BoatsGridDragHandler} from './boats_grid_drag_handler';
import {DevicesBlock} from './devices_block/devices_block';
import {NextScreenButton} from '../../components/buttons';
import {Content} from '../../components/content';
import {Permission} from '../../../enumerations/permission';
import {Can} from '../../components/can';

interface OwnProps {
    race: Race;
}

const BoatsGridComponent: FunctionComponent<OwnProps & PresenterProps<BoatsGridPresenter>> = observer(
    ({race, presenter}) => {
        return (
            <BoatsGridDragHandler race={race}>
                <Content>
                    <Container>
                        <Row className="h-100">
                            <SidebarCol numCols={3} className="d-none d-lg-block">
                                <Heading3>Divide participants</Heading3>
                                <p>To start a race, drag and drop participants and devices into their boats.</p>

                                <Row className="overflow-hidden">
                                    <Col numCols={12} className="mh-100 overflow-hidden d-flex flex-column">
                                        <ParticipantsBlock race={race} />
                                    </Col>
                                </Row>

                                <Row className="overflow-hidden">
                                    <Col numCols={12} className="mh-100 overflow-hidden d-flex flex-column">
                                        <DevicesBlock race={race} />
                                    </Col>
                                </Row>
                            </SidebarCol>
                            <ContentCol className="col-12 col-lg-9">
                                <Row>
                                    <Col numCols={12} className="d-flex flex-row flex-wrap justify-content-start">
                                        {presenter.boats.map((boat, index) => (
                                            <BoatBlock key={boat.id} boat={boat} race={race} />
                                        ))}
                                        <Can permission={Permission.CREATE_BOAT} race={race}>
                                            <ButtonBlock
                                                disabled={presenter.busy}
                                                fixedWidth
                                                title="Add another boat"
                                                icon="ion-md-add-circle-outline"
                                                onClick={() => presenter.onAddBoatClick()}
                                            />
                                        </Can>
                                    </Col>
                                </Row>
                            </ContentCol>
                        </Row>
                    </Container>
                </Content>
                <Footer>
                    <PreviousScreenButton to={`/${race.id}/lobby`} state={race}>
                        <span className="ion-ios-arrow-back" />
                        Previous step
                    </PreviousScreenButton>
                    <NextScreenButton to={`/${race.id}/race-overview`} state={race}>
                        Next step
                        <span className="ion-ios-arrow-forward" />
                    </NextScreenButton>
                </Footer>
            </BoatsGridDragHandler>
        );
    },
);

export const BoatsGrid = withPresenter<BoatsGridPresenter, OwnProps>(
    (props, component) =>
        new BoatsGridPresenter(
            component.boatsProviderFactory.create(props.race),
            new ApiCreateBoatInteractor(
                props.race.id,
                component.networkComponent.boatsApi,
                component.boatsRepositoryFactory.create(props.race),
                new DefaultNextBoatNameProvider(component.boatsRepositoryFactory.create(props.race)),
            ),
        ),
    BoatsGridComponent,
);
