import {BoatWithCombinations} from '../../../../business/boats/boats_with_combinations_provider';
import {Race} from '../../../../models/race';
import {Try} from '../../../../support/monads/try';
import {CanvasSizes, RealtimeBoatProgress} from '../../boats_screen_presenter';
import {CameraOffsetCalculator} from '../boats_screen_state_provider';

export interface OffsetCalculator {
    calculate(
        previousCalculatorOffset: number,
        race: Race,
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number;
}

export class DefaultCameraPositionCalculator implements CameraOffsetCalculator {
    constructor(private race: Race, private offsetCalculators: OffsetCalculator[]) {}

    public calculate(
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number {
        const offset = this.offsetCalculators.reduce((previousOffset, offsetCalculator) => {
            return offsetCalculator.calculate(
                previousOffset,
                this.race,
                boatsWithCombinationsTry,
                realtimeBoatProgresses,
                canvasSizes,
            );
        }, 0);

        return offset;
    }
}
