import {Presenter} from '../../../../support/with_presenter';
import {Participant} from '../../../../models/participant';
import {runInAction} from 'mobx';
import {Observable} from 'rxjs';
import {Try} from '../../../../support/monads/try';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Device} from '../../../../models/device';
import {Boat} from '../../../../models/boat';

export interface Combination {
    participant: Participant;
    device: Device | null;
}

export interface CombinationProvider {
    get(): Observable<Try<Combination[]>>;
}

export interface BoatDeleter {
    delete(): Promise<Try<Boat>>;
}

export class BoatBlockPresenter implements Presenter {
    public combinations: Combination[] = [];
    public isEditModalOpen = false;

    private subscriptions = new CompositeSubscription();

    constructor(private combinationProvider: CombinationProvider, private boatDeleter: BoatDeleter) {}

    public mount(): void {
        this.subscriptions.add(
            this.combinationProvider.get().subscribe((combinationsTry) => {
                combinationsTry.map((combinations) => {
                    runInAction(() => {
                        this.combinations = combinations;
                    });
                });
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onDeleteClick = () => {
        if (window.confirm('Are you sure you want to delete this boat?')) {
            this.boatDeleter.delete();
        }
    };

    public onBoatEditClick = () => {
        this.isEditModalOpen = true;
    };

    public closeBoatEditModal = () => {
        this.isEditModalOpen = false;
    };
}
