import * as React from 'react';
import {Race} from '../../models/race';
import {RouteComponentProps, NavigateFn} from '@reach/router';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {RaceLoadingScreenPresenter} from './race_loading_screen_presenter';
import {observer} from 'mobx-react-lite';
import {Header} from '../components/header';
import styled from 'styled-components';
import {StatusBoxWrapper} from './components/status_box_wrapper';

interface OwnProps {
    render: (race: Race, navigate?: NavigateFn) => JSX.Element;
}

const LoadingScreen = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
`;

const RaceLoadingScreenComponent: React.FunctionComponent<
    OwnProps & PresenterProps<RaceLoadingScreenPresenter> & RouteComponentProps
> = observer(({presenter, render, navigate}) => {
    const {race} = presenter;

    if (race === null) {
        return (
            <LoadingScreen>
                <Header />
                <StatusBoxWrapper className="align-self-center mt-5">
                    <span className="ion-md-information-circle" />
                    Loading...
                </StatusBoxWrapper>
            </LoadingScreen>
        );
    }

    return <>{render(race, navigate)}</>;
});

export const RaceLoadingScreen = withPresenter<
    RaceLoadingScreenPresenter,
    OwnProps & RouteComponentProps<{raceId: string}>
>(
    (props, component) =>
        new RaceLoadingScreenPresenter(
            props.location!.state as Race | unknown,
            props.raceId!,
            component.networkComponent.raceApi,
        ),
    RaceLoadingScreenComponent,
);
