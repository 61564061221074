import styled from 'styled-components';

export const StatusBoxWrapper = styled.div`
    position: absolute;
    width: 350px;
    min-height: 135px;
    padding: 30px;
    border-radius: 5px;
    background-color: #174d5b;
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    z-index: 10;

    span {
        font-size: 20px;
        display: block;
        width: 100%;
        opacity: 0.5;
        padding: 0 0 20px 0;
    }
`;
