import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../models/race';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {RaceControlButtonPresenter} from './race_control_button_presenter';
import styled from 'styled-components';
import {RaceState} from '../../../enumerations/race_state';
import {ApiRaceStarter} from './internal/race_starter';
import {ApiRaceAborter} from './internal/race_aborter';
import {baseButtonStyles} from '../../components/button';

interface OwnProps {
    race: Race;
}

interface StartButtonProps {
    red?: boolean;
}

export const Button = styled.button`
    ${baseButtonStyles}
    height: 52px;

    background-color: ${(props: StartButtonProps) => (props.red === true ? '#eb5255' : '#00a862')};

    &:focus,
    &:hover {
        background-color: ${(props: StartButtonProps) => (props.red === true ? '#eb5255' : '#00a862')};
    }

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem
            ${(props: StartButtonProps) => (props.red === true ? 'rgba(235, 82, 85, 0.5)' : 'rgba(0, 168, 98, 0.5)')};
    }
`;

const RaceControlButton: FunctionComponent<OwnProps & PresenterProps<RaceControlButtonPresenter>> = observer(
    ({race, presenter}) => {
        switch (race.state) {
            case RaceState.IDLE:
                return (
                    <Button onClick={() => presenter.onStartClick()} disabled={presenter.busy}>
                        Start race
                        <span className="ion-ios-arrow-forward" />
                    </Button>
                );
            case RaceState.INITIATING_START:
                return (
                    <Button red disabled={presenter.busy} onClick={() => presenter.onAbortClick()}>
                        Abort
                        <span className="ion-md-exit" />
                    </Button>
                );
            case RaceState.RACING:
                return (
                    <Button red disabled={presenter.busy} onClick={() => presenter.onAbortClick()}>
                        Abort
                        <span className="ion-md-exit" />
                    </Button>
                );
            case RaceState.ABORTED:
                return (
                    <Button disabled={presenter.busy} onClick={() => presenter.onStartClick()}>
                        Start race
                        <span className="ion-ios-arrow-forward" />
                    </Button>
                );
        }
        throw new Error('Unknown race state ');
    },
);

export const StartButton = withPresenter<RaceControlButtonPresenter, OwnProps>(
    (props, component) =>
        new RaceControlButtonPresenter(
            new ApiRaceStarter(
                props.race.id,
                component.networkComponent.raceApi,
                component.raceRepositoryFactory.create(props.race),
            ),
            new ApiRaceAborter(
                props.race.id,
                component.networkComponent.raceApi,
                component.raceRepositoryFactory.create(props.race),
            ),
        ),
    RaceControlButton,
);
