import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface OwnProps {
    large?: boolean;
    inactive?: boolean;
    dark?: boolean;
}

interface WrapperProps {
    large?: boolean;
    inactive?: boolean;
    dark?: boolean;
}

const Wrapper = styled.div`
    display: inline-block;

    width: ${(props: WrapperProps) => (props.large === true ? '160px' : '125px')};
    height: 40px;
    border-radius: 4px;
    border: solid 2px;
    border: ${(props: WrapperProps) => (props.dark === true ? 'solid 0px' : 'solid 2px')};
    border-color: ${(props: WrapperProps) => (props.inactive === true ? '#f0f0f0' : '#d8d8d8')};
    background-color: ${(props: WrapperProps) => {
        if (props.dark === true) {
            return '#193f49';
        }
        if (props.inactive === true) {
            return '#f7f7f7';
        }
        return '#ffffff';
    }};
    margin: 0;
    position: relative;
    padding: 0 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: ${(props: WrapperProps) => (props.dark === true ? '40px' : '36px')};
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(props: WrapperProps) => {
        if (props.dark === true) {
            return '#ffffff';
        }
        if (props.inactive === true) {
            return '#d8d8d8';
        }
        return '#808080';
    }};
`;

export const BlockItem: FunctionComponent<OwnProps> = ({inactive, dark, large, children}) => {
    return (
        <Wrapper inactive={inactive} dark={dark} large={large}>
            {children}
        </Wrapper>
    );
};
