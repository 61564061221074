import {merge, Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';
import {Device} from '../../models/device';

export interface DevicesProvider {
    get(): Observable<Try<Device[]>>;
}

export class CombingDevicesProvider implements DevicesProvider {
    protected observable = merge(...this.devicesProviders.map((devicesProvider) => devicesProvider.get())).pipe(
        distinctUntilChanged(),
        shareReplay(1),
    );

    constructor(private devicesProviders: DevicesProvider[]) {}

    public get(): Observable<Try<Device[]>> {
        return this.observable;
    }
}
