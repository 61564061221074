import {BoatWithCombinations} from '../../../../../business/boats/boats_with_combinations_provider';
import {Race} from '../../../../../models/race';
import {Try} from '../../../../../support/monads/try';
import {CanvasSizes, RealtimeBoatProgress} from '../../../boats_screen_presenter';
import {OffsetCalculator} from '../camera_position_calculator';

export class FinishLineOffsetCalculator implements OffsetCalculator {
    /**
     * If every boat is finished, place the finish line in the middle of the screen
     */
    public calculate(
        previousCalculatorOffset: number,
        race: Race,
        boatsWithCombinationsTry: Try<BoatWithCombinations[]>,
        realtimeBoatProgresses: RealtimeBoatProgress[],
        canvasSizes: CanvasSizes,
    ): number {
        const isEveryBoatFinished =
            realtimeBoatProgresses.length > 0 &&
            realtimeBoatProgresses.every((rbp) => rbp.boat.distanceMeters >= race.distanceMeters);
        if (isEveryBoatFinished) {
            return (
                race.distanceMeters * canvasSizes.meterInPx -
                canvasSizes.canvasHeightPx / 2 +
                canvasSizes.finishLineBlockSizePx
            );
        }

        return previousCalculatorOffset;
    }
}
