import {Participant} from '../../../../../../models/participant';
import {Try} from '../../../../../../support/monads/try';
import {ParticipantUpdater} from '../participant_edit_modal_presenter';

export interface UpdateParticipantApi {
    update(id: string, boatId: string | null, name: string, weightGrams: number | null): Promise<Try<Participant>>;
}

export class ApiParticipantUpdater implements ParticipantUpdater {
    constructor(private updateParticipantApi: UpdateParticipantApi) {}

    public update(participant: Participant): Promise<Try<Participant>> {
        return this.updateParticipantApi.update(
            participant.id,
            participant.boatId,
            participant.name,
            participant.weightGrams,
        );
    }
}
