import {DevicesProvider} from './device_provider';
import {Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Device} from '../../models/device';
import {map, tap, shareReplay} from 'rxjs/operators';

export interface DevicesRepository {
    replace(devices: Device[]): void;
}

export interface DevicesForRaceSocket {
    getForRaceId(raceId: string): Observable<Device[]>;
}

export class SocketDeviceProvider implements DevicesProvider {
    private observable = this.deviceForRaceSocket.getForRaceId(this.raceId).pipe(
        tap((devices) => this.devicesRepository.replace(devices)),
        map((devices) => Try.just(devices)),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private deviceForRaceSocket: DevicesForRaceSocket,
        private devicesRepository: DevicesRepository,
    ) {}

    public get(): Observable<Try<Device[]>> {
        return this.observable;
    }
}
