import {runInAction} from 'mobx';
import {Presenter} from '../../../support/with_presenter';
import {Race} from '../../../models/race';
import {CreateRaceInteractor} from '../internal/create_race_interactor';
import * as Joi from '@hapi/joi';

const schema = Joi.object({
    email: Joi.string()
        .required()
        .email({tlds: {allow: false}}),
});

export class CreatePresenter implements Presenter {
    public busy = false;
    public error: string | null = null;
    public email: string = '';

    public get canSubmit(): boolean {
        const {error} = schema.validate({email: this.email});

        return error === undefined;
    }

    constructor(private onFinish: (race: Race) => void, private createRaceInteractor: CreateRaceInteractor) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onEmailChange(email: string) {
        this.email = email;
    }

    public onSubmit() {
        if (this.canSubmit) {
            this.onCreateClick();
        }
    }

    public async onCreateClick() {
        this.error = null;
        this.busy = true;

        (await this.createRaceInteractor.create(this.email)).fold(
            (value) => {
                runInAction(() => {
                    this.busy = false;
                    this.onFinish(value);
                });
            },
            (e) => {
                runInAction(() => {
                    this.busy = false;
                    this.error = e.message;
                });
            },
        );
    }
}
