export enum Permission {
    EDIT_RACE,

    EDIT_DEVICES,
    EDIT_DEVICE,

    EDIT_PARTICIPANTS,
    CREATE_PARTICIPANT,
    EDIT_PARTICIPANT,

    CREATE_BOAT,
    EDIT_BOAT,
}
