import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Circle, Group, Rect} from 'react-konva';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {CountdownPresenter} from './countdown_presenter';
import {BoatsScreenComponent} from '../boats_screen_component';
import {Label} from './label';

interface OwnProps {
    y: number;
    canvasWidth: number;
    boatsScreenComponent: BoatsScreenComponent;
}

const CountdownComponent: FunctionComponent<OwnProps & PresenterProps<CountdownPresenter>> = observer(
    ({presenter, canvasWidth, y}) => {
        const width = 270;
        const height = 56;

        if (presenter.countdown === null) {
            return (
                <Group x={(canvasWidth - width) / 2} y={y - 100}>
                    <Rect x={0} y={0} fill="#193F49" width={width} height={height} cornerRadius={5} />
                    <Label text="START" />
                </Group>
            );
        }

        const circleColor = presenter.countdown <= 0 ? '#00A862' : '#EB5255';

        return (
            <Group x={(canvasWidth - width) / 2} y={y - 100}>
                <Rect x={0} y={0} fill="#193F49" width={width} height={height} cornerRadius={5} />
                <Circle x={height / 2} y={height / 2} fill={circleColor} radius={24 / 2} />
                <Circle x={width - height / 2} y={height / 2} fill={circleColor} radius={24 / 2} />
                {presenter.countdown <= 0 && <Label text="GO!" />}
                {presenter.countdown > 0 && <Label text="ATTENTION" />}
            </Group>
        );
    },
);

export const Countdown = withPresenter<CountdownPresenter, OwnProps>(
    (props, component) =>
        new CountdownPresenter(
            props.boatsScreenComponent.startTimeProvider,
            component.serverTimeComponent.countdownInteractor,
        ),
    CountdownComponent,
);
