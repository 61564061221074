import {StartIntentInteractor} from '../start_intent_button_presenter';
import {Observable} from 'rxjs';
import {Try} from '../../../../support/monads/try';
import {Race} from '../../../../models/race';
import {ServerTimeProvider} from '../../../../business/countdown/server_time/server_time_provider';
import {first, map, startWith} from 'rxjs/operators';
import {RaceProvider} from '../../../../business/race/race_provider';

export interface UpdateRaceApi {
    updateIntentToStartAtMillis(raceId: string, intentToStartAtMillis: number | null): Promise<Try<Race>>;
}

export class ApiStartIntentInteractor implements StartIntentInteractor {
    public intentToStartAtMillis: Observable<number | null> = this.raceProvider.get().pipe(
        map((raceTry) => {
            return raceTry.fold(
                (race) => race.intentToStartAtMillis,
                () => null,
            );
        }),
        startWith(this.race.intentToStartAtMillis),
    );

    constructor(
        private race: Race,
        private updateRaceApi: UpdateRaceApi,
        private raceProvider: RaceProvider,
        private serverTimeProvider: ServerTimeProvider,
    ) {}

    public async send(): Promise<void> {
        const time = await this.serverTimeProvider.getTime().pipe(first()).toPromise();
        await this.updateRaceApi.updateIntentToStartAtMillis(this.race.id, time);
    }

    public async undo(): Promise<void> {
        await this.updateRaceApi.updateIntentToStartAtMillis(this.race.id, null);
    }
}
