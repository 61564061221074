import * as React from 'react';
import {FunctionComponent} from 'react';
import {ToggleTokenPresenter} from './toggle_token_presenter';
import {withPresenter, PresenterProps} from '../../support/with_presenter';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {SwitchButton} from '../components/switch_button';

const ToggleContainer = styled.div`
    height: 100px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 100px;
    letter-spacing: normal;
    text-align: left;
    color: #7c7c7c;
    padding: 0 1.765rem 0 0;
`;

interface OwnProps {
    raceId: string;
}

const ToggleTokenButtonComponent: FunctionComponent<OwnProps & PresenterProps<ToggleTokenPresenter>> = observer(
    ({presenter}) => {
        const {isTokenVisible, setIsTokenVisible} = presenter;

        return (
            <ToggleContainer className="float-right d-none d-lg-block">
                Show token on beamer
                <SwitchButton
                    onChange={() => setIsTokenVisible(!isTokenVisible)}
                    checked={isTokenVisible}
                    className="float-right"
                />
            </ToggleContainer>
        );
    },
);

export const ToggleTokenButton = withPresenter<ToggleTokenPresenter, OwnProps>(
    (props, component) => new ToggleTokenPresenter(component.networkComponent.toggleTokenSocket, props.raceId),
    ToggleTokenButtonComponent,
);
