import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';

import {StatusBoxPresenter} from './status_box_presenter';
import {Race} from '../../../models/race';
import {StartResult, StartState} from '../../../models/start_result';
import {Participant} from '../../../models/participant';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {RaceState} from '../../../enumerations/race_state';
import {StatusBoxWrapper} from '../../race_loading_screen/components/status_box_wrapper';

interface OwnProps {
    race: Race;
}

const AbortedExplanation: FunctionComponent<{startResult?: StartResult; participants: Participant[]}> = ({
    startResult,
    participants,
}) => {
    if (startResult !== undefined) {
        switch (startResult.state) {
            case StartState.INITIALIZED:
                return <div>Race initialized.</div>;
            case StartState.COMMENCING:
                return <div>Race is starting.</div>;
            case StartState.TIMEOUT:
                return (
                    <div>
                        Race aborted because following devices didn't send ack signal:
                        <br />
                        <br />
                        {startResult.erroredDeviceIds.map((id) => (
                            <div key={id}>{id.substr(0, 4)}</div>
                        ))}
                    </div>
                );
            case StartState.SUCCESS:
            case StartState.ABORTED: {
                const falseStartedParticipants = participants.filter((p) => p.didFalseStart === true);
                if (falseStartedParticipants.length > 0) {
                    return (
                        <div>
                            Race aborted because following participants did a false start:
                            <br />
                            <br />
                            {falseStartedParticipants.map((p) => (
                                <div key={p.id}>{p.name}</div>
                            ))}
                        </div>
                    );
                }
            }
        }
    }

    return <div>Race is aborted.</div>;
};

const StatusBoxComponent: FunctionComponent<OwnProps & PresenterProps<StatusBoxPresenter>> = observer(
    ({race, presenter}) => {
        const {startResult, participants, devices} = presenter;

        const participantsWithSpinningDevice = devices
            .filter((device) => device.isWheelSpinning === true)
            .map((device) => participants.find((participant) => participant.id === device.participantId))
            .filter((participant): participant is Participant => participant !== undefined);

        switch (race.state) {
            case RaceState.IDLE:
                if (race.intentToStartAtMillis !== null) {
                    return (
                        <StatusBoxWrapper className="align-self-center">
                            <span className="ion-md-information-circle" />
                            Rowers are notified that we're about to start
                        </StatusBoxWrapper>
                    );
                }
                return (
                    <StatusBoxWrapper className="align-self-center">
                        <span className="ion-md-information-circle" />
                        Race progress wil be shown in this window after race is started.
                    </StatusBoxWrapper>
                );
            case RaceState.INITIATING_START:
                return (
                    <StatusBoxWrapper className="align-self-center">
                        <span className="ion-md-alert" />

                        {participantsWithSpinningDevice.length === 0 ? (
                            "Race start is initiating, we're waiting till all wheels stopped spinning."
                        ) : (
                            <>
                                <div>
                                    Race start is initiazing, we're waiting for the following participants their wheels
                                    to stop spinning:
                                    <br />
                                    <br />
                                    {participantsWithSpinningDevice.map((participant) => (
                                        <div key={participant.id}>{participant.name}</div>
                                    ))}
                                </div>
                            </>
                        )}
                    </StatusBoxWrapper>
                );
            case RaceState.RACING:
                return null;
            case RaceState.ABORTED:
                return (
                    <StatusBoxWrapper className="align-self-center">
                        <span className="ion-md-close-circle" />
                        <AbortedExplanation startResult={startResult} participants={participants} />
                    </StatusBoxWrapper>
                );
        }

        return null;
    },
);

export const StatusBox = withPresenter<StatusBoxPresenter, OwnProps>(
    (props, component) =>
        new StatusBoxPresenter(
            props.race,
            component.raceProviderFactory.create(props.race),
            component.startResultProviderFactory.create(props.race),
            component.participantsProviderFactory.create(props.race),
            component.devicesProviderFactory.create(props.race),
        ),
    StatusBoxComponent,
);
