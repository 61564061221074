import * as React from 'react';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {BoatsScreen} from '../boats_screen/boats_screen';
import {ViewPortSizeProvider} from '../boats_screen/support/viewport_sizes';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {Link, navigate, RouteComponentProps} from '@reach/router';
import {BigScreenPresenter} from './big_screen_presenter';
import {observer} from 'mobx-react-lite';
import {RaceState} from '../../enumerations/race_state';
import {Race} from '../../models/race';
import {Token} from './components/token';
import styled, {keyframes} from 'styled-components';
import {StatusBox} from './components/status_box';

interface OwnProps {
    race: Race;
    isEmbedded?: boolean;
}

const fullscreenButtonFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const fullscreenButtonFadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const FullscreenButton = styled.button`
    position: absolute;
    right: 0;
    bottom: 0;
    background: none;
    border: none;
    color: #fff;
    font-size: 40px;
    width: 60px;
    height: 60px;
    margin: 0;
    animation: ${fullscreenButtonFadeOut} 2s linear forwards;
    opacity: 0;
    &:hover {
        animation: ${fullscreenButtonFadeIn} 0.5s linear forwards;
        opacity: 1;
    }
`;

const FullscreenLink = styled(Link)`
    position: absolute;
    right: 0;
    bottom: 0;
    background: none;
    border: none;
    color: #fff;
    font-size: 40px;
    width: 60px;
    height: 60px;
    text-align: center;

    &:hover {
        color: #fff;
    }
`;

export const BigScreenComponent: FunctionComponent<OwnProps & PresenterProps<BigScreenPresenter>> = observer(
    ({presenter, isEmbedded}) => {
        const [viewPortSizeProvider, setViewPortSizeProvider] = useState<ViewPortSizeProvider | null>(null);
        const {race, isTokenVisible} = presenter;

        const [fullscreen, setFullscreen] = useState(false);

        //We can't use useRef since we're combining both the measureRef as well as this ref in our system
        const ref = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (fullscreen && ref.current !== null) {
                ref.current.requestFullscreen();
            }
        }, [fullscreen, ref.current]);

        useEffect(() => {
            let resizeObserver: any = null;
            const current = ref.current;
            if (current !== null) {
                // TypeScript doesn't know the resize observer yet
                // @ts-ignore
                resizeObserver = new ResizeObserver(() => {
                    const boundingClientRect = current.getBoundingClientRect();
                    setViewPortSizeProvider(
                        new ViewPortSizeProvider(boundingClientRect.width, boundingClientRect.height),
                    );
                });
                resizeObserver.observe(ref.current);
            }

            return () => {
                if (resizeObserver !== null && ref.current !== null) {
                    resizeObserver.unobserve(ref.current);
                }
            };
        }, [ref.current]);

        return (
            <div ref={ref} className="position-relative d-flex flex-grow-1 justify-content-center overflow-hidden">
                {isTokenVisible && race !== null && <Token>Race token: {race.token}</Token>}
                <StatusBox race={race} />
                {viewPortSizeProvider !== null ? (
                    race.state === RaceState.RACING ? (
                        <BoatsScreen
                            key="racing"
                            withIndcators
                            race={race}
                            viewPortSizeProvider={viewPortSizeProvider}
                        />
                    ) : (
                        <BoatsScreen key="idle" race={race} viewPortSizeProvider={viewPortSizeProvider} />
                    )
                ) : null}
                {isEmbedded ? (
                    <FullscreenLink className="ion-md-easel" to={`/big/${race.id}`} />
                ) : fullscreen ? (
                    <FullscreenButton
                        className="ion-md-contract"
                        onClick={() => {
                            if (document.fullscreenElement) {
                                document.exitFullscreen();
                            }
                            setFullscreen(false);
                            navigate(`/${race.id}/race-overview`);
                        }}
                    />
                ) : (
                    <FullscreenButton className="ion-md-expand" onClick={() => setFullscreen(true)} />
                )}
            </div>
        );
    },
);

export const BigScreen = withPresenter<BigScreenPresenter, OwnProps & RouteComponentProps<{raceId: string}>>(
    (props, component) => {
        return new BigScreenPresenter(
            props.race,
            component.raceProviderFactory.create(props.race),
            component.networkComponent.toggleTokenSocket,
        );
    },
    BigScreenComponent,
);
