import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';

interface OwnProps {
    title: string;
    disabled?: boolean;
    onClick?: () => void;
    icon?: string;
    fixedWidth?: boolean;
}

interface WrapperProps {
    fixedWidth?: boolean;
}

const Wrapper = styled.button`
    background: none;
    float: left;
    position: relative;
    display: inline-block;
    min-height: 230px;
    width: ${(props: WrapperProps) => (props.fixedWidth === true ? '330px' : 'auto')};
    border-radius: 5px;
    padding: 18px;
    margin: 15px;
    border: 2px dashed #cfcfcf;
`;

const Title = styled.div`
    width: 100%;
    margin: 20px 0 0 0;
    h3 {
        margin: 0 18px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #cfcfcf;
    }
    span {
        font-size: 34px;
        line-height: 34px;
        color: #cfcfcf;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
`;

const Content = styled.div`
    padding: 5px 0;
`;

export const ButtonBlock: FunctionComponent<OwnProps> = ({title, disabled, onClick, icon, fixedWidth, children}) => {
    return (
        <Wrapper fixedWidth={fixedWidth} onClick={onClick} disabled={disabled}>
            <Title>
                {icon ? <span className={icon} /> : null}
                <h3>{title}</h3>
            </Title>
            <Content>{children}</Content>
        </Wrapper>
    );
};
