import * as React from 'react';
import {FunctionComponent} from 'react';
import {Group} from 'react-konva';
import {StatisticRowComponent} from './row';
import {Title} from './title';
import {animated, useSpring, useTrail} from 'react-spring/konva';

export interface StatisticRow {
    name: string;
    formattedMetric: string;
}

interface OwnProps {
    x: number;
    y: number;
    title: string;
    rows: StatisticRow[];
    hightlightFirst?: boolean;
}

export const StatisticsTable: FunctionComponent<OwnProps> = ({x, y, title, rows, hightlightFirst}) => {
    const rowsPerColumn = 5;
    const numColumns = Math.ceil(rows.length / rowsPerColumn);

    const backgroundProps = useSpring({
        to: {
            width: Math.max(300, numColumns * 295 + 5),
            height: 36 * 6 - 5,
        },
        delay: 500,
    });

    const trail = useTrail(rows.length, {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        delay: 500,
    });

    return (
        <Group x={x} y={y}>
            {/* Header */}
            <Title title={title} />

            {/* Background */}
            <animated.Rect
                {...backgroundProps}
                x={0}
                y={36}
                fill={'rgb(25,63,73,0.5)'}
                cornerRadius={numColumns > 1 ? [0, 5, 5, 5] : [0, 0, 5, 5]}
            />

            {/* Rows */}
            {trail.map((rowProps, index) => {
                const columnNumber = Math.floor(index / rowsPerColumn);
                const rowNumber = 1 + (index % rowsPerColumn);

                const row = rows[index];

                return (
                    <StatisticRowComponent
                        {...rowProps}
                        key={index}
                        x={columnNumber * (255 + 36 + 5) + 5}
                        y={rowNumber * 36 + rowNumber * 5}
                        number={String(index + 1)}
                        highlighted={hightlightFirst && index === 0}
                        name={row.name}
                        metric={row.formattedMetric}
                    />
                );
            })}
        </Group>
    );
};
