import * as React from 'react';
import {FunctionComponent} from 'react';

interface OwnProps {
    numCols?: number;
    className?: string;
    offsetCols?: number;
    innerRef?: React.Ref<any>;
}

export const Col: FunctionComponent<OwnProps> = ({innerRef, children, numCols, offsetCols, className}) => {
    let calculatedClassName = '';
    if (numCols !== undefined) {
        calculatedClassName += 'col-' + numCols;
    }
    if (offsetCols !== undefined) {
        calculatedClassName += ' offset-' + offsetCols;
    }
    if (className !== undefined) {
        calculatedClassName += ' ' + className;
    }
    return (
        <div ref={innerRef} className={calculatedClassName}>
            {children}
        </div>
    );
};
