import {toast} from 'react-toastify';
import {Participant} from '../../../../../models/participant';
import {Presenter} from '../../../../../support/with_presenter';
import {Try} from '../../../../../support/monads/try';

export interface ParticipantUpdater {
    update(participant: Participant): Promise<Try<Participant>>;
}

export class ParticipantEditModalPresenter implements Presenter {
    public participant: Participant;

    constructor(initialParticipant: Participant, private participantUpdater: ParticipantUpdater) {
        this.participant = {
            ...initialParticipant,
        };
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onParticipantNameChange = (name: string) => {
        this.participant = {
            ...this.participant,
            name,
        };
    };

    public onParticipantWeightKiloGramsChange = (weightKiloGrams: number | null) => {
        this.participant = {
            ...this.participant,
            weightGrams: weightKiloGrams === null ? null : weightKiloGrams * 1000,
        };
    };

    public onSave = async () => {
        try {
            await this.participantUpdater.update(this.participant);
        } catch (error) {
            toast('Something went wrong while saving participant.', {
                type: toast.TYPE.ERROR,
            });
            throw error;
        }
    };
}
