import {AnimatedValueCalculator, UpdateIntervalAnimatedValueCalculator} from './animated_value_calculator';
import {BoatProgress} from '../../../../business/boats/boats_progress_provider';
import {AnimatedBoatsProgressCalculator} from '../boats_screen_state_provider';

interface UpdateIntervalAnimatedValueCalculatorFactory {
    create(): UpdateIntervalAnimatedValueCalculator;
}

export class DefaultAnimatedBoatsProgressCalculator implements AnimatedBoatsProgressCalculator {
    private animatedValuesCalculators = new Map<string, AnimatedValueCalculator>();

    constructor(private updateIntervalAnimatedValueCalculatorFactory: UpdateIntervalAnimatedValueCalculatorFactory) {}

    private getAnimatedValueCalculator(boatId: string): AnimatedValueCalculator {
        const existingCalculator = this.animatedValuesCalculators.get(boatId);
        if (existingCalculator) {
            return existingCalculator;
        }

        const newCalculator = this.updateIntervalAnimatedValueCalculatorFactory.create();
        this.animatedValuesCalculators.set(boatId, newCalculator);
        return newCalculator;
    }

    public calculate(boatProgresses: BoatProgress[], timestamp: number): BoatProgress[] {
        return boatProgresses.map((boatProgress) => {
            const animatedValueCalculator = this.getAnimatedValueCalculator(boatProgress.id);
            animatedValueCalculator.update(boatProgress.distanceMeters, timestamp);

            return {
                ...boatProgress,
                distanceMeters: animatedValueCalculator.get(timestamp),
            };
        });
    }
}
