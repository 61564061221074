import {scheduled} from 'rxjs';
import {AnimationFrameAction} from 'rxjs/internal/scheduler/AnimationFrameAction';
import {AnimationFrameScheduler} from 'rxjs/internal/scheduler/AnimationFrameScheduler';
import {map, repeat, shareReplay} from 'rxjs/operators';

const customAnimationFrameScheduler = new AnimationFrameScheduler(AnimationFrameAction);
const animationFrameObservable = scheduled([customAnimationFrameScheduler.now()], customAnimationFrameScheduler).pipe(
    repeat(),
    map(() => customAnimationFrameScheduler.now()),
    shareReplay(1),
);

export function getAnimationFrameObservable() {
    return animationFrameObservable;
}
