import {CollectedSplitTimesProvider} from '../overlays/overlays/split_times/split_times_presenter';
import {SplitTimeSocket} from '../overlays/internal/overlays/internal/split_time_provider';
import {Observable} from 'rxjs';
import {SplitTime} from '../../../models/split_time';
import {lazy} from '../../../support/lazy';
import {scan, shareReplay} from 'rxjs/operators';

export class DefaultCollectedSplitTimesProvider implements CollectedSplitTimesProvider {
    @lazy()
    public get splitTimes(): Observable<SplitTime[]> {
        return this.splitTimeSocket.forRaceId(this.raceId).pipe(
            scan((p: SplitTime[], c: SplitTime) => {
                if (p.length === 0 || p[p.length - 1].distanceMeters < c.distanceMeters) {
                    return [c];
                }
                return [...p, c];
            }, []),
            shareReplay(1),
        );
    }

    constructor(private raceId: string, private splitTimeSocket: SplitTimeSocket) {}
}
