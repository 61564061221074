import * as React from 'react';
import {FunctionComponent, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {Group} from 'react-konva';
import {animated, useChain} from 'react-spring/konva';
import {useSpring} from 'react-spring';
import {OverlayText} from './overlay_text';

interface OwnProps {
    title: string;
}

export const Title: FunctionComponent<OwnProps> = observer(({title}) => {
    const backgroundRefA = useRef<any>();
    const backgroundPropsA = useSpring({
        config: {
            duration: 200,
        },
        from: {width: 0},
        to: {width: 150},
        ref: backgroundRefA,
    });

    const backgroundRefB = useRef<any>();
    const backgroundPropsB = useSpring({
        config: {
            duration: 200,
        },
        from: {width: 0},
        to: {width: 150},
        ref: backgroundRefB,
    });

    const foregroundRef = useRef<any>();
    const foregroundProps = useSpring({
        config: {
            duration: 200,
        },
        from: {opacity: 0},
        to: {opacity: 1},
        ref: foregroundRef,
    });

    useChain([backgroundRefA, backgroundRefB, foregroundRef]);

    return (
        <Group>
            <animated.Rect
                {...backgroundPropsA}
                x={0}
                y={0}
                fill={'rgb(25,63,73,0.7)'}
                height={36}
                cornerRadius={[5, 0, 0, 0]}
            />
            <animated.Rect {...backgroundPropsB} x={150} y={0} fill="#04B6A9" height={36} cornerRadius={[0, 5, 0, 0]} />

            <OverlayText animated {...foregroundProps} text="Race overview" x={0} y={0} width={150} height={36} />
            <OverlayText animated {...foregroundProps} text={title} x={150} y={0} width={150} height={36} />
        </Group>
    );
});
