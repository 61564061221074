import {HttpLinkProvider} from './driver/http_link_provider';
import {Try} from '../support/monads/try';
import {execute, ExecutionResult, GraphQLRequest, makePromise} from 'apollo-link';
import gql from 'graphql-tag';
import {ServerTimeApi, ServerTime} from '../business/countdown/server_time/single_server_time_interactor';

export interface TimeApi extends ServerTimeApi {}

export class DefaultTimeApi implements TimeApi {
    constructor(private httpLinkProvider: HttpLinkProvider) {}

    public async get(): Promise<Try<ServerTime>> {
        const operation: GraphQLRequest = {
            query: gql`
                query {
                    currentTimeMillis
                }
            `,
        };

        try {
            const start = window.performance.now();
            const result: ExecutionResult = await makePromise(execute(this.httpLinkProvider.get(), operation));
            const duration = window.performance.now() - start;

            if (result.errors !== undefined && result.errors.length > 0) {
                return Try.raiseError(new Error(result.errors[0].message));
            }

            if (result.data === null || result.data === undefined) {
                return Try.raiseError(new Error('No result from server'));
            }
            return Try.just({
                serverTimeMillis: result.data.currentTimeMillis,
                durationMillis: duration,
            });
        } catch (e) {
            return Try.raiseError(e);
        }
    }
}
