import * as React from 'react';
import {FunctionComponent} from 'react';
import {Overlay} from './overlays_presenter';
import {BoatsScreenComponent} from '../boats_screen_component';
import {SplitTimes} from './overlays/split_times/split_times';
import {StrokeRates} from './overlays/stroke_rates/stroke_rates';
import {Speeds} from './overlays/speeds/speeds';

interface OwnProps {
    x: number;
    y: number;
    activeOverlays: Overlay[];
    boatScreenComponent: BoatsScreenComponent;
}

/**
 * The use of this component is to ensure only 1 table is visible at the time
 * @param x
 * @param y
 * @param activeOverlays
 * @param boatScreenComponent
 * @constructor
 */
export const Tables: FunctionComponent<OwnProps> = ({x, y, activeOverlays, boatScreenComponent}) => {
    if (activeOverlays.includes(Overlay.SplitTimes)) {
        return <SplitTimes key="split-times" boatScreenComponent={boatScreenComponent} x={x} y={y} />;
    }
    if (activeOverlays.includes(Overlay.StrokeRates)) {
        return <StrokeRates key="stroke-rates" boatScreenComponent={boatScreenComponent} x={x} y={y} />;
    }
    if (activeOverlays.includes(Overlay.Speeds)) {
        return <Speeds key="speeds" boatScreenComponent={boatScreenComponent} x={x} y={y} />;
    }
    return null;
};
